import React, { useEffect, useState } from 'react';
import { Alert, Col, Modal, Row } from 'react-bootstrap';
import useStrategyMonthYearSelector from '../../../../../hooks/useStrategyMonthYearSelector';
import { monthSmall } from '../../../../../helpers/constants';
import useLocalization from '../../../../../hooks/useLocalization';
import AppTable from '../../../../common/app-table/AppTable';
import UnderlineButton from '../../../../common/UnderlineButton';
import { findLinkedLiabilities, formatCurrency, formatPercentage, getValueAtMonthYear } from './helper';
import { evalFormula } from '../../../../../helpers/global';

const LeverageOptionsModal = ({
  show,
  onHide,
  incompleteYearsOfStrategy,
  strategyYears,
  calculatedStrategy,
  compTable = {},
  assetOptions = [],
  onOptionSelect
}) => {
  const { translate } = useLocalization();

  const {
    selectedMonth,
    onMonthChange,
    selectedYear,
    onYearChange,
    monthOptions,
    yearOptions
  } = useStrategyMonthYearSelector({
    incompleteYearsOfStrategy,
    strategyYears,
    initialYear: strategyYears[0],
    initialMonth: 0
  });

  const [leverageOptions, setLeverageOptions] = useState([]);

  const calculateLeverageOptions = () => {
    //from existing assets
    const leverageOptions = assetOptions
      .map(leveragedAsset => {
        const presetName = leveragedAsset.preset;
        if (!presetName) return;

        const preset = compTable?.presets?.find(p => p.name === presetName);
        if (!preset) return;

        const assetInCalculatedBalanceSheet = calculatedStrategy.assets.find(ca => ca._id === leveragedAsset._id);
        const linkedLoans = findLinkedLiabilities(calculatedStrategy.liabilities, assetInCalculatedBalanceSheet);
        const assetValue = getValueAtMonthYear(assetInCalculatedBalanceSheet, selectedMonth, selectedYear);

        const loanValueThisMonth = linkedLoans.reduce(
          (acc, l) => getValueAtMonthYear(l, selectedMonth, selectedYear) + acc,
          0
        );

        const maxLeverageAllowedPercentage = evalFormula(preset.loanValue, compTable?.variables || []) || 0;
        const maxLeverageAllowedAmount = assetValue * maxLeverageAllowedPercentage * 0.01;
        const availableLeverageAmount = maxLeverageAllowedAmount - loanValueThisMonth;
        const availableLeveragePercentage = (availableLeverageAmount * 100) / assetValue;

        return {
          asset: leveragedAsset,
          type: preset.loanType,
          maxLeverage: maxLeverageAllowedPercentage,
          availableLeverage: availableLeveragePercentage,
          availableLeverageAmount: availableLeverageAmount,
          interest: evalFormula(preset.loanInterest, compTable?.variables || []) || 0
        };
      })
      .filter(Boolean);

    //from salary
    const allSalaryCashflows = calculatedStrategy.cashflows.filter(c => c.affectsRiskManagement);
    const salary = allSalaryCashflows.reduce((acc, c) => acc + getValueAtMonthYear(c, selectedMonth, selectedYear), 0);

    const outstandingLoan = calculatedStrategy.liabilities.reduce(
      (acc, l) => acc + getValueAtMonthYear(l, selectedMonth, selectedYear, 'payments', 'monthlyPayments'),
      0
    );

    const maxLoan = (salary - outstandingLoan) * 10;

    leverageOptions.push({
      isBankLoan: true,
      type: 'Annuity',
      maxLeverage: null,
      availableLeverage: null,
      availableLeverageAmount: maxLoan,
      interest: evalFormula('[A2]', compTable?.variables || []) || 0
    });

    return leverageOptions;
  };

  useEffect(() => {
    setLeverageOptions(calculateLeverageOptions());
  }, [selectedMonth, selectedYear, compTable, assetOptions]);

  const onOptionSelectClick = option => {
    onOptionSelect(option, selectedMonth, selectedYear);
  };

  return (
    <Modal className='bg-black bg-opacity-50' dialogClassName="px-4" size="lg" show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton className="bg-secondary">
        <Modal.Title>
          <h6 className="mb-0">Leverage Options</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto">
        <h6 className="large mb-1">Select month and year you want to view leverage options for:</h6>
        <Row className="mt-1">
          <Col>
            <label className="mid" htmlFor="month">
              {translate('month')}
            </label>
            <select
              id="month"
              onChange={e => onMonthChange(e.target.value)}
              name="month"
              className="form-select form-select-sm mb-3"
              value={selectedMonth}
            >
              {monthOptions.map(month => (
                <option value={month} key={month}>
                  {monthSmall[month]}
                </option>
              ))}
            </select>
          </Col>
          <Col>
            <label className="mid" htmlFor="year">
              {translate('year')}
            </label>
            <select
              id="year"
              onChange={e => onYearChange(e.target.value)}
              name="year"
              className="form-select form-select-sm mb-3"
              value={selectedYear}
            >
              {yearOptions.map(year => (
                <option value={year} selected={selectedYear === year} key={year}>
                  {year}
                </option>
              ))}
            </select>
          </Col>
        </Row>
        <hr className="" />
        <h6 className="large mb-1">Leverage Options:</h6>
        <div>
          <AppTable>
            <thead>
              <tr className="bg-dark text-white">
                <th>Asset</th>
                <th>Max. Leverage Allowed</th>
                <th>Rem. Leverage</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Interest</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {leverageOptions.map(option => (
                <tr key={option.asset?._id || '-'}>
                  <td>{option.isBankLoan ? 'Bank Loan' : option.asset?.name}</td>
                  <td>{option.maxLeverage === null ? '-' : formatPercentage(option.maxLeverage, true, true)}</td>
                  <td>
                    {option.availableLeverage === null ? '-' : formatPercentage(option.availableLeverage, true, true)}
                  </td>
                  <td>{formatCurrency(option.availableLeverageAmount)}</td>
                  <td>{option.type}</td>
                  <td>{formatPercentage(option.interest)}</td>
                  <td className="text-center">
                    <UnderlineButton
                      fontSize="mid"
                      variant="success"
                      text="Select"
                      onClick={() => onOptionSelectClick(option)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </AppTable>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LeverageOptionsModal;

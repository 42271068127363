import React, { useContext, useEffect } from 'react';
import { LocalizeContext } from 'react-locale-language';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import ScrollToTop from './components/common/ScrollToTop';
import ExampleUser from './components/example-user/ExampleUser';
import GetStarted from './components/get-started/GetStarted';
import SharedCustomer from './components/share/SharedCustomer';
import AuthenticatedApp from './pages/AuthenticatedApp';
import CreatePassword from './pages/CreatePassword';
import Login from './pages/Login';
import NonAuthenticatedApps from './pages/NonAuthenticatedApps';

const App = () => {
  const { langCode } = useContext(LocalizeContext);

  useEffect(() => {
    document.dir = langCode === 'Hebrew' ? 'rtl' : 'ltr';
  }, [langCode]);

  return (
    <>
      <ScrollToTop />
      {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
      <Switch>
        <Route exact path="/get-started">
          <GetStarted />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>

        <Route exact path="/example/:userId">
          <ExampleUser />
        </Route>
        <Route exact path="/share/:shareToken">
          <SharedCustomer />
        </Route>
        <Route path="/create-password/:key">
          <CreatePassword />
        </Route>
        <Route path="/home">
          <NonAuthenticatedApps />
        </Route>

        <Route path="/">
          {' '}
          <AuthenticatedApp />
        </Route>
      </Switch>
      <ToastContainer autoClose={1500} />
    </>
  );
};

export default App;

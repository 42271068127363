import { cloneDeep, uniqueId } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { ArrowLeftRight, EyeSlashFill, Pencil, PlusCircle, ThreeDotsVertical } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { makeApiRequests } from '../../../../../helpers/api';
import {
  ENDPOINTS,
  fieldsWithFormulas,
  getScenarioOptions,
  loanTypePresets,
  presetTableHeaders,
  primaryBankAccountName
} from '../../../../../helpers/constants';
import { evalFormula, isCustomerNew, isSystemUser } from '../../../../../helpers/global';
import AlertModal from '../../../../common/AlertModal';
import ExplainatoryVideoButton from '../../../../common/ExplainatoryVideoButton';
import NotFound from '../../../../common/NotFound';
import Tabs from '../../../../common/Tabs';
import UnderlineButton from '../../../../common/UnderlineButton';
import AddEditOneOffEventModal from './AddEditOneOffEventModal';
import useAuth from '../../../../../hooks/useAuth';
import AddUpdateStrategyModal from './AddUpdateStrategyModal';
import ArmageddonModal from './ArmageddonModal';
import AssetModal from './AssetModal';
import CashFlowModal from './CashFlowModal';
import CemeteryButton from './CemeteryButton';
import CemetrySidebar from './CemetrySidebar';
import FloatingStrategyNavigator from './FloatingStrategyNavigator';
import {
  addMonthAndYear,
  addOrUpdateObjectInArray,
  areMonthYearEqual,
  calculateBalanceSheet,
  compoundInterestMonthly,
  createDefaultBankAccount,
  deleteObjectInArray,
  findAllLoansOfPaperApartment,
  findCommonGroupDeleteProblems,
  findCommonGroupEditProblems,
  findDiffInNumberOfMonths,
  findLiabilityEditProblems,
  findLinkedLiabilities,
  findMonthYearAtWhichAssetBecomesZero,
  findNewNameInstrument,
  findReinvestmentDeleteProblems,
  findReinvestmentGroupEditProblems,
  findWeakestMonthYearOfStrategy,
  formatCurrency,
  formatPercentage,
  getEndYearOfLiability,
  getFinalYearOfStrategy,
  getIncompleteYearsOfStrategy,
  getLoanNamePrefill,
  getMonthlyCompoundedDepreciation,
  getMonthlyPayment,
  getStrategyYears,
  getUpdatedLinkedVariablesOfLiability,
  getValueAtMonthYear,
  isPaperApartment,
  updateDatesOfTransferAccordingToAsset,
  updateEndYearOfLiability,
  updateLoanValueFromMarginFloor,
  updateMonthlyPaymentFromLoanValue,
  updateNumberPercentage,
  updateNumberPercentageFromValue,
  updateTimeToMaturityFromMonthlyPayment
} from './helper';
import LiabilityModal from './LiabilityModal';
import ReinvestmentModal from './ReinvestmentModal';
import Strategy from './Strategy';
import StrategyGroupSelectorModal from './StrategyGroupSelectorModal';
import StrategyRearrangeModal from './StrategyRearrangeModal';
import StrategyScenario from './StrategyScenario';
import SyncFixedTransferModal from './SyncFixedTransferModal';

const applyPreset = (preset, compTable, assetForLiability, fieldsToOverride = {}) => {
  const overridenPreset = { ...preset, ...fieldsToOverride };
  presetTableHeaders.forEach(({ key, mapsToElem, isPercentageField, numberField, formType, roundDecimal = 2 }) => {
    try {
      if (!mapsToElem) return;

      if (key === 'loanValue' && preset.loanType === 'Margin') return;

      if (!fieldsWithFormulas.includes(key)) {
        if (overridenPreset[key] === undefined) return;

        if (formType === 'checkbox') {
          document.getElementById(mapsToElem).checked = overridenPreset[key];
        } else {
          document.getElementById(mapsToElem).value = overridenPreset[key];
        }
        return;
      }

      const value = evalFormula(overridenPreset[key], compTable?.variables || []) || 0;
      document.getElementById(mapsToElem).value = isPercentageField
        ? formatPercentage(value, false, true)
        : value.toFixed(roundDecimal);

      if (isPercentageField) {
        if (key === 'loanValue') {
          const startYear = Number(document.getElementById('startYear').value);
          const startMonth = Number(document.getElementById('startMonth').value);

          const valueAtThisMonthYear = isPaperApartment(assetForLiability)
            ? assetForLiability.value
            : getValueAtMonthYear(assetForLiability, startMonth, startYear);

          updateNumberPercentageFromValue(valueAtThisMonthYear, numberField, mapsToElem, 'percentage');
          setTimeout(() => {
            updateMonthlyPaymentFromLoanValue();
          }, 300);
        } else if (key === 'salesCost') {
          const appreciationElem = document.getElementById('returnAppreciation');
          const appreciation = appreciationElem.value ? Number(appreciationElem.value.replaceAll('%', '')) : 0;

          const startYear = Number(document.getElementById('buyingYear').value);
          const startMonth = Number(document.getElementById('buyingMonth').value);

          const valueElem = document.getElementById('value');
          const value = valueElem.value ? Number(valueElem.value.replaceAll(',', '')) : 0;

          const endYear = Number(document.getElementById('sellingYear').value);
          const endMonth = Number(document.getElementById('sellingMonth').value);

          const valueAtTheTimeOfSelling = endYear
            ? compoundInterestMonthly(
                value,
                findDiffInNumberOfMonths(startMonth, startYear, endMonth, endYear),
                appreciation
              )
            : 0;

          updateNumberPercentageFromValue(valueAtTheTimeOfSelling, numberField, mapsToElem, 'percentage');
        } else {
          if (numberField) {
            updateNumberPercentage(
              'value',
              numberField,
              mapsToElem,
              'percentage',
              numberField === 'returnCashflow' ? 12 : 1
            );
          }
        }
      }
    } catch (e) {
      console.log(key, mapsToElem, e);
    }
  });
};

const applyPresetFromPresetName = (
  presetName,
  compTable,
  assetForLiability,
  fieldsToOverride,
  updateLiabilityEndDate,
  newAssetBeingAdded
) => {
  if (!presetName) return;

  const preset = compTable?.presets?.find(p => p.name === presetName);
  if (!preset) return;

  setTimeout(() => {
    applyPreset(preset, compTable, assetForLiability, fieldsToOverride);
    if (updateLiabilityEndDate) updateEndYearOfLiability(assetForLiability);
  }, 100);
};

const CustomerStrategies = ({
  adminCompTable,
  activeCustomer,
  fromCustomerPortal,
  onSelectedStrategyChange,
  onCustomerUpdate,
  onUpdateStrategyTimeClick,
  readOnlyMode
}) => {
  const { translate, langCode } = useContext(LocalizeContext);
  const { user } = useAuth();

  const [selectedStrategy, setSelectedStrategy] = useState();
  const [updatingStrategy, setUpdatingStrategy] = useState(false);
  const [assetModalMeta, setAssetModalMeta] = useState(null);
  const [assetActionInProgress, setAssetActionInProgress] = useState(null);

  const [liabilityModalMeta, setLiabilityModalMeta] = useState(null);
  const [alertModalMeta, setAlertModalMeta] = useState(null);
  const [notesModalMeta, setNotesModalMeta] = useState(null);
  const [isOneOffEventDialogOpen, setOneOffEventDialogOpen] = useState(false);
  const [oneOffEventFormData, setOneOffEventFormData] = useState({});
  const [cashflowDialogOpen, setCashflowDialogOpen] = useState(false);
  const [cashflowFormData, setCashflowFormData] = useState({});
  const [editingCashflowChanges, setEditingCashflowChanges] = useState([]);

  const [isReinvestmentOpen, setReinvestmentOpen] = useState(false);
  const [investmentFormData, setInvestmentFormData] = useState({});
  const [quickRenaming, setQuickRenaming] = useState(false);

  const [strategyModalMeta, setStrategyModalMeta] = useState(null);
  const [strategyGroupSelectionMeta, setStrategyGroupSelectionMeta] = useState(null);
  const [cashflowSubmitting, setCashflowSubmitting] = useState(false);
  const [reinvestmentSubmitting, setReinvestmentSubmitting] = useState(false);

  const [syncFixedTransferModalMeta, setSyncFixedTransferModalMeta] = useState(null);

  const [showCemetry, setShowCemetry] = useState(false);
  const [showStrategySortModal, setShowStrategySortModal] = useState(false);
  const [sortingStartegies, setSortingStrategies] = useState(false);

  const unkilledStrategies = useMemo(() => activeCustomer['strategies'].filter(s => !s.killed), [activeCustomer]);
  const killedStrategies = useMemo(() => activeCustomer['strategies'].filter(s => s.killed), [activeCustomer]);

  const otherStrategiesInThisGroup = useMemo(
    () => (selectedStrategy && activeCustomer['strategies'].filter(s => s._id !== selectedStrategy._id)) || [],
    [selectedStrategy]
  );

  const strategyYears = useMemo(() => (selectedStrategy ? getStrategyYears(selectedStrategy) : []), [selectedStrategy]);

  const incompleteYearOfStrategy = useMemo(() => selectedStrategy && getIncompleteYearsOfStrategy(selectedStrategy), [
    selectedStrategy
  ]);

  const showStressTestAndArmaggedonToClient = useMemo(() => adminCompTable.appConfig.showStressTestToClient, [
    adminCompTable
  ]);
  const showArchivedToClient = useMemo(() => adminCompTable.appConfig.showArchivedToClient && user?.level >= 1, [
    adminCompTable,
    user
  ]);
  const defaultInflationValue = useMemo(
    () => Number(activeCustomer?.comp?.variables?.find(v => v.symbol === 'A4')?.value || 0),
    [activeCustomer]
  );

  const scenarioOptions = useMemo(() => getScenarioOptions(translate, showStressTestAndArmaggedonToClient), [
    langCode,
    showStressTestAndArmaggedonToClient
  ]);
  const [activeScenario, setActiveScenario] = useState(scenarioOptions[0]);
  const [stressTestApplied, setStressTestApplied] = useState(false);
  const [armageddonDetails, setArmageddonDetails] = useState(null);
  const [armageddonDetailsModalMeta, setArmageddonDetailsModalMeta] = useState(null);
  const [inflationApplied, setInflationApplied] = useState(false);
  const [inflationValue, setInflationValue] = useState(defaultInflationValue);

  const [calculatedStrategy, setCalculatedStrategy] = useState();

  useEffect(() => {
    if (!selectedStrategy) return setCalculatedStrategy();

    const armageddon = activeScenario.isArmageddon && armageddonDetails;
    const customerCompTable = activeCustomer?.comp;

    setCalculatedStrategy(
      calculateBalanceSheet(
        selectedStrategy,
        armageddon,
        stressTestApplied,
        translate,
        false,
        adminCompTable,
        customerCompTable
      )
    );
  }, [selectedStrategy, activeScenario, armageddonDetails, stressTestApplied]);

  const prefillFormFields = assetFieldsToFill => {
    assetFieldsToFill.forEach(({ key, value, isPercentage }) => {
      const element = document.getElementById(key);
      if (!element) return;

      const valueToFill =
        typeof value === 'number'
          ? isPercentage
            ? formatPercentage(value, false, true)
            : formatCurrency(value)
          : value;
      element.value = valueToFill;
    });
  };

  useEffect(() => {
    onSelectedStrategyChange && onSelectedStrategyChange(selectedStrategy);
    setNotesModalMeta({ notes: selectedStrategy?.notes || '' });
  }, [selectedStrategy]);

  useEffect(() => {
    if (unkilledStrategies.length === 0) return setSelectedStrategy();

    if (!selectedStrategy) {
      selectStrategy(unkilledStrategies[0]);
    } else {
      selectStrategy(unkilledStrategies.find(s => s._id === selectedStrategy._id) || unkilledStrategies[0]);
    }
  }, [unkilledStrategies, activeScenario, inflationApplied, inflationValue]);

  useEffect(() => {
    if (!selectedStrategy) {
      return setArmageddonDetails(null);
    }

    if (armageddonDetails) {
      const { assetDepreciations = [] } = selectedStrategy.defaultArmageddonConfig;

      setArmageddonDetails({
        ...armageddonDetails,
        assetDepreciations: selectedStrategy.assets
          .filter(a => ['Stocks', 'Real Estate'].includes(a.category))
          .map(a => {
            const defaultDepreciation = assetDepreciations.find(({ assetId }) => assetId === a._id);
            const depPercentage = defaultDepreciation ? defaultDepreciation.depreciation : a.crashPercentage;

            return {
              _id: a._id,
              name: a.name,
              depreciation: depPercentage,
              monthlyDepreciation: getMonthlyCompoundedDepreciation(18, depPercentage)
            };
          })
      });
    }
  }, [selectedStrategy]);

  const onStrategyShownToCustomerChange = async show => {
    setUpdatingStrategy(true);

    const strategy = activeCustomer['strategies'].find(s => s._id === selectedStrategy._id);

    const { response, error } = await updateStrategy({
      ...strategy,
      showToCustomer: show
    });

    setUpdatingStrategy(false);

    if (error) {
      return toast.error(error);
    }

    onCustomerUpdate && onCustomerUpdate(response);
    toast.success(`This strategy is now ${show ? 'shown to' : 'hidden from'} customer!`);
  };

  const onAddAssetClick = () => {
    setAssetModalMeta({});
  };

  const onAddLiabilityClick = () => {
    setLiabilityModalMeta({
      assetOptions: selectedStrategy['assets'].filter(a => a.name !== primaryBankAccountName),
      cashAssetOptions: selectedStrategy['assets'].filter(a => a.category === 'Cash'),
      loanAutoCalculated: true,
      loanEditMode: !selectedStrategy.isDefaultStrategy,
      loanType: 'Annuity',
      liabilityStartYear: selectedStrategy['initialYear'],
      liabilityStartMonth: incompleteYearOfStrategy?.[selectedStrategy['initialYear']]?.start || 0
    });

    setTimeout(() => {
      document.getElementById('name').value = getLoanNamePrefill(null, false, translate);
      applyPreset(loanTypePresets.Annuity, activeCustomer.comp);
      const { year: liabilityEndYear, month: liabilityEndMonth } = updateEndYearOfLiability(
        null,
        null,
        incompleteYearOfStrategy
      );

      setLiabilityModalMeta(liabilityModalMeta => ({
        ...liabilityModalMeta,
        ...(liabilityEndYear ? { liabilityEndYear, liabilityEndMonth } : {})
      }));
    }, [100]);
  };

  const onLiabilitySubmit = () => {
    document.getElementById(liabilityModalMeta.toBeEditedLiability ? 'updateliability' : 'newliability').click();
  };

  const onSingleLiabilitySubmit = () => {
    setLiabilityModalMeta({ ...liabilityModalMeta, updateGroup: false });
    setTimeout(() => onLiabilitySubmit(), 100);
  };

  const onGroupLiabilitySubmit = () => {
    setLiabilityModalMeta({ ...liabilityModalMeta, updateGroup: true });
    setTimeout(() => onLiabilitySubmit(), 100);
  };

  const onStrategyGroupSelectionCheckedChange = strategyId => {
    if (strategyGroupSelectionMeta?.selectedStrategies.includes(strategyId)) {
      strategyGroupSelectionMeta.selectedStrategies = strategyGroupSelectionMeta.selectedStrategies.filter(
        s => s !== strategyId
      );
    } else {
      strategyGroupSelectionMeta?.selectedStrategies.push(strategyId);
    }

    setStrategyGroupSelectionMeta({ ...strategyGroupSelectionMeta });
  };

  const onStrategyGroupSelectedFieldsCheckedChange = fieldId => {
    if (strategyGroupSelectionMeta?.selectedFields.includes(fieldId)) {
      strategyGroupSelectionMeta.selectedFields = strategyGroupSelectionMeta.selectedFields.filter(s => s !== fieldId);
    } else {
      strategyGroupSelectionMeta?.selectedFields.push(fieldId);
    }

    setStrategyGroupSelectionMeta({ ...strategyGroupSelectionMeta });
  };

  const onAllStrategyCheckedChange = checked => {
    setStrategyGroupSelectionMeta({
      ...strategyGroupSelectionMeta,
      selectedStrategies: !checked ? [] : strategyGroupSelectionMeta.strategiesOptions.map(s => s._id)
    });
  };

  const onLiabilityValueCurrencyConvert = currencyConversionInfo => {
    setLiabilityModalMeta(liabilityModalMeta => ({ ...liabilityModalMeta, currencyConversionInfo }));
  };

  const onAddEventClick = (item, isAsset, month, year) => {
    setOneOffEventFormData({
      type: isAsset ? 'Asset' : 'Liability',
      refId: item._id,
      value: 0,
      editMode: false,
      attribute: item,
      notes: '',
      month,
      year
    });
  };

  const onEditEventClick = event => {
    const toBeEditedEvent = { ...event };
    delete toBeEditedEvent.name;
    setOneOffEventFormData({ ...toBeEditedEvent, editMode: toBeEditedEvent.editMode || false });
  };

  useEffect(() => {
    if (oneOffEventFormData.refId) {
      setOneOffEventDialogOpen(true);
    }
  }, [oneOffEventFormData]);

  const handleReinvestmentSubmit = async (formData, isEdit) => {
    const toBeEditedReinvestment = investmentFormData._id ? investmentFormData : undefined;

    const newReinvestment = cloneDeep(formData);

    const fromTypeAndId = formData['fromId'].split(':');
    newReinvestment['fromType'] = fromTypeAndId[0];
    newReinvestment['fromId'] = fromTypeAndId[1];

    const toTypeAndId = formData['toId'].split(':');
    newReinvestment['toType'] = toTypeAndId[0];
    newReinvestment['toId'] = toTypeAndId[1];

    //if group edit is pressed
    if (formData.updateGroup) {
      let strategyOptions = unkilledStrategies.filter(s => s._id !== selectedStrategy._id);

      let { problemStrategies, warningStrategies, changedFields, allFields } = findReinvestmentGroupEditProblems({
        selectedStrategy,
        strategiesToCheck: strategyOptions,
        newReinvestment,
        toBeEditedReinvestment
      });

      const problemStrategyNames = problemStrategies.map(p => p.strategyName);
      strategyOptions = strategyOptions.filter(s => !problemStrategyNames.includes(s.name));

      setStrategyGroupSelectionMeta({
        selectedStrategies: strategyOptions
          .filter(s => !warningStrategies.map(s => s._id).includes(s._id))
          .map(s => s._id),
        strategiesOptions: strategyOptions,
        problemStrategies,
        warningStrategies,
        fieldsToShow: allFields,
        originalObject: toBeEditedReinvestment,
        selectedFields: Object.keys(changedFields),
        onSubmit: (selectedStrategies, changedFields) =>
          addUpdateReinvestmentsInStrategies({
            selectedStrategies,
            newReinvestment,
            toBeEditedReinvestment,
            changedFields
          }),
        progressText: `${toBeEditedReinvestment ? 'Updating' : 'Adding'} reinvestment...`
      });
      return;
    }

    await addUpdateReinvestmentsInStrategies({ newReinvestment, toBeEditedReinvestment });
    return true;
  };

  const addUpdateReinvestmentsInStrategies = async ({
    selectedStrategies = [],
    newReinvestment,
    toBeEditedReinvestment,
    changedFields
  }) => {
    setStrategyGroupSelectionMeta(null);

    let allStrategiesToBeUpdated = [
      activeCustomer['strategies'].find(s => s._id === selectedStrategy._id),
      ...selectedStrategies.map(sId => activeCustomer['strategies'].find(s => s._id === sId))
    ];

    const { fromType, fromId, toType, toId } = newReinvestment;
    const fromInstrumentKey = fromType === 'asset' ? 'assets' : 'liabilities';
    const fromInstrumentName =
      fromType && fromId ? selectedStrategy[fromInstrumentKey].find(a => a._id === fromId)?.name : undefined;

    const toInstrumentKey = toType === 'asset' ? 'assets' : 'liabilities';

    const toInstrumentName =
      toType && toId ? selectedStrategy[toInstrumentKey].find(a => a._id === toId)?.name : undefined;

    const groupId = toBeEditedReinvestment?.groupId || uuid();

    allStrategiesToBeUpdated = allStrategiesToBeUpdated.map(strategy => {
      const investmentsToSearch = strategy['investments'];
      const existingReinvestmentInThisStrategy = toBeEditedReinvestment
        ? investmentsToSearch.find(a => a.groupId === toBeEditedReinvestment.groupId)
        : undefined;

      //if creating new asset, use complete object, otherwise only replace keys which come from changed fields
      const updatingReinvestment =
        changedFields && existingReinvestmentInThisStrategy
          ? { ...existingReinvestmentInThisStrategy, ...changedFields }
          : { ...newReinvestment };

      if (groupId) updatingReinvestment.groupId = groupId;

      //update from instrument id from its own strategy
      if (fromInstrumentName) {
        updatingReinvestment.fromId = strategy[fromInstrumentKey].find(a => a.name === fromInstrumentName)?._id;
      }

      //update to instrument id from its own strategy
      if (fromInstrumentName) {
        updatingReinvestment.toId = strategy[toInstrumentKey].find(a => a.name === toInstrumentName)?._id;
      }

      const updatedInvestments = addOrUpdateObjectInArray(strategy.investments, updatingReinvestment);

      return {
        ...strategy,
        investments: updatedInvestments
      };
    });

    setReinvestmentSubmitting(true);

    const { error, response } = await updateStrategy(allStrategiesToBeUpdated);
    setReinvestmentSubmitting(false);
    if (error) {
      return toast.error(error);
    }

    toast.success(`Successfully ${toBeEditedReinvestment ? 'updated' : 'added'} investment option`);
    onCustomerUpdate && onCustomerUpdate(response);

    setReinvestmentOpen(false);
    setInvestmentFormData({});
  };

  const handleCashflowSubmit = async (formData, isEdit) => {
    const isAutomatedCashflow = formData.isAutomated;
    const toBeEditedCashflow = cashflowFormData._id || cashflowFormData.isAutomated ? cashflowFormData : undefined;

    if (!isAutomatedCashflow) {
      const namesToCheck = selectedStrategy['customCashflows']
        .map(a => a.name)
        .filter(name => (toBeEditedCashflow ? name !== toBeEditedCashflow.name : true));

      if (namesToCheck.includes(formData['name'])) {
        toast.error(
          'There is already an cashflow with the same name, please change the cashflow of the asset and try again!'
        );
        return;
      }
    }

    //TODO
    const newCashflow = { ...formData, cashflowChanges: editingCashflowChanges };

    if (!newCashflow.isRent) {
      newCashflow.linkedApartment = '';
    }

    //if group edit is pressed
    if (formData.updateGroup) {
      let strategyOptions = unkilledStrategies.filter(s => s._id !== selectedStrategy._id);

      let { problemStrategies, warningStrategies, changedFields, allFields } = findCommonGroupEditProblems({
        selectedStrategy,
        strategiesToCheck: strategyOptions,
        instrumentKey: 'customCashflows',
        instrumentName: 'cashflow',
        instrument: newCashflow,
        toBeEditedInstrument: toBeEditedCashflow
      });

      const problemStrategyNames = problemStrategies.map(p => p.strategyName);
      strategyOptions = strategyOptions.filter(s => !problemStrategyNames.includes(s.name));

      setStrategyGroupSelectionMeta({
        selectedStrategies: strategyOptions
          .filter(s => !warningStrategies.map(s => s._id).includes(s._id))
          .map(s => s._id),
        strategiesOptions: strategyOptions,
        problemStrategies,
        warningStrategies,
        fieldsToShow: allFields,
        originalObject: toBeEditedCashflow,
        selectedFields: Object.keys(changedFields),
        onSubmit: (selectedStrategies, changedFields) =>
          addUpdateCashflowsInStrategies({ selectedStrategies, newCashflow, toBeEditedCashflow, changedFields }),
        progressText: `${toBeEditedCashflow ? 'Updating' : 'Adding'} cashflow...`
      });
      return;
    }

    await addUpdateCashflowsInStrategies({ newCashflow, toBeEditedCashflow });
    return true;
  };

  const addUpdateCashflowsInStrategies = async ({
    selectedStrategies = [],
    newCashflow,
    toBeEditedCashflow,
    changedFields
  }) => {
    setStrategyGroupSelectionMeta(null);

    const isAutomatedCashflow = newCashflow.isAutomated;
    let allStrategiesToBeUpdated = [
      activeCustomer['strategies'].find(s => s._id === selectedStrategy._id),
      ...selectedStrategies.map(sId => activeCustomer['strategies'].find(s => s._id === sId))
    ];

    const linkedBankAccountName = selectedStrategy['assets'].find(a => a._id === newCashflow.linkedBankAccount)?.name;

    allStrategiesToBeUpdated = allStrategiesToBeUpdated.map(strategy => {
      if (isAutomatedCashflow) {
        const { name, strategyKey, cashflowChanges, returnCashflow, cashflowAppreciation, taxOnCashflow } = newCashflow;
        let instrumentToBeUpdated = strategy[strategyKey].find(i => i.name === name);
        let updatedInstuments = addOrUpdateObjectInArray(strategy[strategyKey], {
          ...instrumentToBeUpdated,
          ...(changedFields || {
            cashflowChanges,
            returnCashflow: returnCashflow || 0,
            cashflowAppreciation: cashflowAppreciation || 0,
            taxOnCashflow: taxOnCashflow || 0
          })
        });
        return {
          ...strategy,
          [strategyKey]: updatedInstuments
        };
      }

      const cashflowsToSearch = strategy['customCashflows'];
      const existingCashflowInThisStrategy = toBeEditedCashflow
        ? cashflowsToSearch.find(a => a.name === toBeEditedCashflow.name)
        : undefined;

      //if creating new asset, use complete object, otherwise only replace keys which come from changed fields
      const updatingCashflow =
        changedFields && existingCashflowInThisStrategy
          ? { ...existingCashflowInThisStrategy, ...changedFields }
          : { ...newCashflow };

      //update linked bank account id from its own strategy
      updatingCashflow.linkedBankAccount =
        strategy['assets'].find(a => a.category === 'Cash' && a.name === linkedBankAccountName)?._id || 'not_linked';

      const updatedCashflows = addOrUpdateObjectInArray(strategy.customCashflows, updatingCashflow);

      return {
        ...strategy,
        customCashflows: updatedCashflows
      };
    });

    setCashflowSubmitting(true);

    const { error, response } = await updateStrategy(allStrategiesToBeUpdated);
    setCashflowSubmitting(false);
    if (error) {
      return toast.error(error);
    }

    onCustomerUpdate && onCustomerUpdate(response);
    toast.success(`Successfully ${toBeEditedCashflow ? 'updated' : 'added'} cashflow ${newCashflow['name']}`);

    setCashflowDialogOpen(false);
    setCashflowFormData({});
    setEditingCashflowChanges([]);
  };

  const handleOneOffEventSubmit = async (formData, isEdit) => {
    const descriptionToCheck = selectedStrategy.oneOffChanges
      .map(a => a.description)
      .filter(name => (isEdit ? name !== formData.description : true));

    if (descriptionToCheck.includes(formData.description)) {
      return toast.error(
        'There is already a one off event with the same name, please change the name of the one off event and try again!'
      );
    }

    const strategy = activeCustomer['strategies'].find(s => s._id === selectedStrategy._id);
    const updatedOneOffChanges = addOrUpdateObjectInArray(strategy.oneOffChanges, formData);

    const { error, response } = await updateStrategy({ ...strategy, oneOffChanges: updatedOneOffChanges });

    if (error) {
      return toast.error(error);
    }

    toast.success(`Successfully ${isEdit ? 'updated' : 'added'} One Time Event ${formData['description']}`);
    onCustomerUpdate && onCustomerUpdate(response);
  };

  //called when liabilities values changes
  const onLiabilityValueChange = e => {
    if (!liabilityModalMeta) return;

    const { linkedAsset } = liabilityModalMeta;
    if (linkedAsset) {
      const startYear = Number(document.getElementById('startYear').value);
      const startMonth = Number(document.getElementById('startMonth').value);

      const valueAtThisMonthYear = isPaperApartment(linkedAsset)
        ? linkedAsset.value
        : getValueAtMonthYear(linkedAsset, startMonth, startYear);
      updateNumberPercentageFromValue(valueAtThisMonthYear, 'value', 'valueInPercentage', 'number');
    }

    updateLoanStartMonthAndYearForPaperApartment();
    updateMonthlyPaymentFromLoanValue();
  };

  const updateLoanStartMonthAndYearForPaperApartment = (linkedAsset, totalMortgageToBeTaken) => {
    if (!linkedAsset) {
      if (!liabilityModalMeta) return;
      linkedAsset = liabilityModalMeta.linkedAsset;
    }

    if (!linkedAsset || !isPaperApartment(linkedAsset)) return;

    if (totalMortgageToBeTaken === undefined) {
      // find when the mortgage starts
      const mortgageValueElem = document.getElementById('value');
      totalMortgageToBeTaken = mortgageValueElem.value ? Number(mortgageValueElem.value.replaceAll(',', '')) : 0;
    }

    const allLoansForPaperApartment = findAllLoansOfPaperApartment(linkedAsset, { value: totalMortgageToBeTaken });
    if (allLoansForPaperApartment[0]) {
      document.getElementById('startYear').value = allLoansForPaperApartment[0].startYear;
      document.getElementById('startMonth').value = allLoansForPaperApartment[0].startMonth;
      if (liabilityModalMeta) {
        liabilityModalMeta.liabilityStartYear = allLoansForPaperApartment[0].startYear;
        liabilityModalMeta.liabilityStartMonth = allLoansForPaperApartment[0].startMonth;
      }
    }
  };

  //called when liability value's percentage or time changes
  const updateLiabilityValueFromAsset = () => {
    if (!liabilityModalMeta) return;

    const { linkedAsset } = liabilityModalMeta;

    if (linkedAsset) {
      const startYear = Number(document.getElementById('startYear').value);
      const startMonth = Number(document.getElementById('startMonth').value);

      const valueAtThisMonthYear = isPaperApartment(linkedAsset)
        ? linkedAsset.value
        : getValueAtMonthYear(linkedAsset, startMonth, startYear);
      updateNumberPercentageFromValue(valueAtThisMonthYear, 'value', 'valueInPercentage', '%');
      setTimeout(() => {
        updateMonthlyPaymentFromLoanValue();
      }, 300);
    }
  };

  const onLiabilityValuePercentageChange = e => {
    if (!e) return;
    updateLiabilityValueFromAsset();
    updateLoanStartMonthAndYearForPaperApartment();
    setTimeout(() => {
      updateMonthlyPaymentFromLoanValue();
    }, 300);
  };

  const onLiabilityStartMonthChange = e => {
    if (!e) return;
    updateLiabilityValueFromAsset();

    const { year: liabilityEndYear, month: liabilityEndMonth } = updateEndYearOfLiability(
      liabilityModalMeta?.linkedAsset,
      false,
      incompleteYearOfStrategy
    );
    setLiabilityModalMeta(liabilityModalMeta => ({
      ...liabilityModalMeta,
      liabilityStartMonth: Number(e.target.value),
      ...(liabilityEndYear ? { liabilityEndYear, liabilityEndMonth } : {})
    }));
  };

  const onLiabilityStartYearChange = e => {
    if (!e) return;
    updateLiabilityValueFromAsset();

    const { year: liabilityEndYear, month: liabilityEndMonth } = updateEndYearOfLiability(
      liabilityModalMeta?.linkedAsset,
      false,
      incompleteYearOfStrategy
    );
    setLiabilityModalMeta(liabilityModalMeta => ({
      ...liabilityModalMeta,
      liabilityStartYear: Number(e.target.value),
      ...(liabilityEndYear ? { liabilityEndYear, liabilityEndMonth } : {})
    }));
  };

  const onLiabilityEndMonthChange = e => {
    if (!e) return;

    setLiabilityModalMeta(liabilityModalMeta => ({
      ...liabilityModalMeta,
      liabilityEndMonth: e.target.value ? Number(e.target.value) : 0
    }));
  };

  const onLiabilityEndYearChange = e => {
    if (!e) return;

    setLiabilityModalMeta(liabilityModalMeta => ({
      ...liabilityModalMeta,
      liabilityEndYear: e.target.value ? Number(e.target.value) : null
    }));
  };

  const onLiabilityEditModeChange = e => {
    if (!e) return;

    const loanEditMode =
      [...document.getElementById(`editMode-blocks`).getElementsByClassName('block')]
        .filter(block => block.getAttribute('data-selected') === 'true')
        .map(block => block.getAttribute('data-value'))[0] === 'Edit Mode';

    setLiabilityModalMeta(liabilityModalMeta => ({
      ...liabilityModalMeta,
      loanEditMode
    }));
  };

  const onLiabilityInterestChange = e => {
    updateMonthlyPaymentFromLoanValue();
  };

  const onLoanMonthlyPaymentChange = e => {
    if (!e) return;

    updateTimeToMaturityFromMonthlyPayment();
    const { year: liabilityEndYear, month: liabilityEndMonth } = updateEndYearOfLiability(
      liabilityModalMeta?.linkedAsset,
      false,
      incompleteYearOfStrategy
    );

    setLiabilityModalMeta(liabilityModalMeta => ({
      ...liabilityModalMeta,
      ...(liabilityEndYear ? { liabilityEndYear, liabilityEndMonth } : {})
    }));
  };

  const sortAssetsAccordingToLiquidationScore = ({ strategy, editingAsset, assetToBePushed }) => {
    if (!strategy) strategy = selectedStrategy;

    const isFixedIncome = assetToBePushed.category === 'Fixed Income';

    let updatedAssets;
    //do not sort if it is fixed income or the asset's liquidationScore has not changed
    if (isFixedIncome || (editingAsset && editingAsset.liquidationScore === assetToBePushed.liquidationScore)) {
      updatedAssets = addOrUpdateObjectInArray(
        strategy[isFixedIncome ? 'fixedIncomeAssets' : 'assets'],
        assetToBePushed
      );
    } else {
      //getting primary bank account at top
      updatedAssets = [...strategy['assets']];

      if (editingAsset) {
        updatedAssets.splice(
          updatedAssets.findIndex(a => a._id === editingAsset._id),
          1
        );
      }

      //push the asset next to the last asset with same liq score
      const indexToPush =
        updatedAssets.length -
        [...updatedAssets].reverse().findIndex(a => a.liquidationScore === assetToBePushed.liquidationScore);

      updatedAssets.splice(indexToPush, 0, assetToBePushed);

      //have bank account at top
      updatedAssets = [
        ...updatedAssets.filter(a => a.name === primaryBankAccountName),
        ...updatedAssets.filter(a => a.name !== primaryBankAccountName)
      ];
    }

    return updatedAssets;
  };

  const onAssetFormSubmit = async newAsset => {
    const { category, rsuGrants } = newAsset;
    const { toBeEditedAsset } = assetModalMeta;

    const isFixedIncome = category === 'Fixed Income';

    const namesToCheck = selectedStrategy[isFixedIncome ? 'fixedIncomeAssets' : 'assets']
      .map(a => a.name)
      .filter(name => (toBeEditedAsset ? name !== toBeEditedAsset.name : true));

    if (namesToCheck.includes(newAsset['name'])) {
      return toast.error(
        'There is already an asset with the same name, please change the name of the asset and try again!'
      );
    }

    newAsset['sellingYear'] = newAsset['sellingYear'] || undefined;
    newAsset['editMode'] = newAsset['editMode'] === 'Edit Mode';
    newAsset['liquidationScore'] = Number(newAsset['liquidationScore']);

    const { buyingYear, sellingYear } = newAsset;
    if (isFixedIncome) {
      newAsset['startYear'] = newAsset['buyingYear'];
      newAsset['endYear'] = newAsset['sellingYear'];
      newAsset['startMonth'] = newAsset['buyingMonth'];
      newAsset['endMonth'] = newAsset['sellingMonth'];
    }

    if (sellingYear && sellingYear < buyingYear) {
      return toast.error('Selling year cannot be less than buying year!');
    }

    if (category === 'RSU') {
      if (rsuGrants.some(g => !g.amount || !g.vestingYear)) {
        return toast.error('Stock amount and vesting year is required for each grant!');
      }

      newAsset['rsuGrants'] = rsuGrants;
    }

    if (newAsset.deposits?.length > 0) {
      newAsset.deposits = newAsset.deposits.map(d => ({
        fromId: d.source,
        fromType: 'asset',
        frequency: d.frequency,
        value: d.value
      }));
    }

    let assetToBePushed = toBeEditedAsset ? { ...toBeEditedAsset, ...newAsset } : newAsset;

    //if group edit is pressed
    if (newAsset.updateGroup) {
      let strategyOptions = unkilledStrategies.filter(s => s._id !== selectedStrategy._id);

      let { problemStrategies, warningStrategies, changedFields, allFields } = findCommonGroupEditProblems({
        selectedStrategy,
        strategiesToCheck: strategyOptions,
        instrumentKey: 'assets',
        instrumentName: 'asset',
        instrument: newAsset,
        toBeEditedInstrument: toBeEditedAsset
      });

      const problemStrategyNames = problemStrategies.map(p => p.strategyName);
      strategyOptions = strategyOptions.filter(s => !problemStrategyNames.includes(s.name));

      setStrategyGroupSelectionMeta({
        selectedStrategies: strategyOptions
          .filter(s => !warningStrategies.map(s => s._id).includes(s._id))
          .map(s => s._id),
        strategiesOptions: strategyOptions,
        problemStrategies,
        warningStrategies,
        fieldsToShow: allFields,
        originalObject: toBeEditedAsset,
        selectedFields: Object.keys(changedFields),
        onSubmit: (selectedStrategies, changedFields) =>
          addUpdateAssetInStrategies({
            selectedStrategies,
            changedFields,
            assetToBePushed,
            toBeEditedAsset,
            isFixedIncome
          }),
        progressText: `${toBeEditedAsset ? 'Updating' : 'Adding'} asset...`
      });
      return;
    }

    //if there are linked fixed transfers and dates have changed
    if (
      toBeEditedAsset &&
      (!areMonthYearEqual(
        toBeEditedAsset.startMonth ?? toBeEditedAsset.buyingMonth,
        toBeEditedAsset.startYear ?? toBeEditedAsset.buyingYear,
        assetToBePushed.startMonth ?? assetToBePushed.buyingMonth,
        assetToBePushed.startYear ?? assetToBePushed.buyingYear
      ) ||
        !areMonthYearEqual(
          toBeEditedAsset.endMonth ?? toBeEditedAsset.sellingMonth,
          toBeEditedAsset.endYear ?? toBeEditedAsset.sellingYear,
          assetToBePushed.endMonth ?? assetToBePushed.sellingMonth,
          assetToBePushed.endYear ?? assetToBePushed.sellingYear
        ))
    ) {
      const relatedFixedTransfers = selectedStrategy.investments.filter(
        i =>
          (i.fromType === 'asset' && i.fromId === toBeEditedAsset?._id) ||
          (i.toType === 'asset' && i.toId === toBeEditedAsset?._id)
      );

      if (relatedFixedTransfers?.length > 0) {
        setSyncFixedTransferModalMeta({
          assetToBePushed,
          toBeEditedAsset,
          isFixedIncome,
          relatedFixedTransfers: cloneDeep(relatedFixedTransfers)
        });
        return;
      }
    }

    //if link a new liability is pressed
    if (newAsset.linkLiability) {
      // the new asset does not yet have forecasted yearly values, so we temporarily add forecasted values to be used by liability form
      let loanFieldsToFill = newAsset.loanFieldsToFill;
      const strategyWithFilledData = calculateBalanceSheet(
        {
          ...selectedStrategy,
          assets: [...selectedStrategy.assets, newAsset],
          initialYear: selectedStrategy.initialYear
        },
        null,
        null,
        translate,
        false,
        adminCompTable,
        activeCustomer['comp']
      );

      const linkedAsset = strategyWithFilledData.assets.find(a => a.name === newAsset.name);
      const preset = activeCustomer.comp?.presets?.find(p => p.name === newAsset.preset);

      let loanType = preset?.loanType || 'Annuity';

      setLiabilityModalMeta({
        linkedAsset,
        fromAssetCreateForm: true,
        cashAssetOptions: selectedStrategy['assets'].filter(a => a.category === 'Cash'),
        loanType,
        loanAutoCalculated: true,
        loanEditMode: !selectedStrategy.isDefaultStrategy
      });

      setAssetModalMeta(null);

      setTimeout(() => {
        const isMarginLoan = loanType === 'Margin';

        const nameInput = document.getElementById('name');
        nameInput.value = getLoanNamePrefill(newAsset, isMarginLoan, translate);

        if (loanFieldsToFill) {
          applyPresetFromPresetName(newAsset.preset, activeCustomer.comp, linkedAsset, {}, true);
          setTimeout(() => {
            prefillFormFields(loanFieldsToFill);

            const loanPercentageFromPreFill = loanFieldsToFill.find(f => f.key === 'valueInPercentage').value;
            const loanTimeToMaturity = loanFieldsToFill.find(f => f.key === 'timeToMaturity').value;

            updateLoanStartMonthAndYearForPaperApartment(
              linkedAsset,
              Number(linkedAsset.value) * loanPercentageFromPreFill * 0.01
            );
            const { year: liabilityEndYear, month: liabilityEndMonth } = getEndYearOfLiability(
              linkedAsset,
              false,
              incompleteYearOfStrategy,
              loanTimeToMaturity
            );

            setLiabilityModalMeta(liabilityModalMeta => ({
              ...liabilityModalMeta,
              ...(liabilityEndYear ? { liabilityEndYear, liabilityEndMonth } : {})
            }));
          }, 200);
          return;
        }

        if (preset) {
          applyPresetFromPresetName(newAsset.preset, activeCustomer.comp, linkedAsset, {}, true);

          const loanPercentage = Number(preset.loanValue || 0);

          updateLoanStartMonthAndYearForPaperApartment(linkedAsset, Number(linkedAsset.value) * loanPercentage * 0.01);

          if (isMarginLoan) {
            const floorPercentage = evalFormula(preset.marginFloor, activeCustomer.comp?.variables || []) || 0;
            updateLoanValueFromMarginFloor({ linkedAsset, floorPercentage });
          }

          const { year: liabilityEndYear, month: liabilityEndMonth } = getEndYearOfLiability(
            linkedAsset,
            false,
            incompleteYearOfStrategy,
            preset?.timeToMaturity
          );

          setLiabilityModalMeta(liabilityModalMeta => ({
            ...liabilityModalMeta,
            ...(liabilityEndYear ? { liabilityEndYear, liabilityEndMonth } : {})
          }));
        } else {
          document.getElementById('valueInPercentage').value = '100 %';
          document.getElementById('value').value = formatCurrency(newAsset.value);
          setTimeout(() => {
            updateMonthlyPaymentFromLoanValue();
          }, 300);
        }
      }, 100);
      return;
    }

    addUpdateAssetInStrategies({ assetToBePushed, toBeEditedAsset, isFixedIncome });
  };

  const addUpdateAssetInStrategies = async ({
    selectedStrategies = [],
    assetToBePushed,
    toBeEditedAsset,
    isFixedIncome,
    relatedFixedTransfers,
    changedFields
  }) => {
    setStrategyGroupSelectionMeta(null);

    let allStrategiesToBeUpdated = [
      activeCustomer['strategies'].find(s => s._id === selectedStrategy._id),
      ...selectedStrategies.map(sId => activeCustomer['strategies'].find(s => s._id === sId))
    ];

    const linkedBankAccountName = selectedStrategy['assets'].find(a => a._id === assetToBePushed.linkedBankAccount)
      ?.name;

    allStrategiesToBeUpdated = allStrategiesToBeUpdated.map(strategy => {
      const assetsToSearch = strategy[isFixedIncome ? 'fixedIncomeAssets' : 'assets'];
      const existingAssetInThisStrategy = toBeEditedAsset
        ? assetsToSearch.find(a => a.name === toBeEditedAsset.name)
        : undefined;

      //if creating new asset, use complete object, otherwise only replace keys which come from changed fields
      const updatingAsset =
        changedFields && existingAssetInThisStrategy
          ? { ...existingAssetInThisStrategy, ...changedFields }
          : { ...assetToBePushed };

      //update linked bank account id from its own strategy
      updatingAsset.linkedBankAccount =
        strategy['assets'].find(a => a.category === 'Cash' && a.name === linkedBankAccountName)?._id || 'not_linked';

      let updatedAssets = sortAssetsAccordingToLiquidationScore({
        strategy,
        assetToBePushed: updatingAsset,
        editingAsset: existingAssetInThisStrategy
      });

      //If there are linked liabilities of this asset, update the liabilities so that they fall under
      //the time period of asset
      let updatedLiabilities = [...strategy['liabilities']];
      const linkedLiabilities = existingAssetInThisStrategy
        ? findLinkedLiabilities(strategy['liabilities'], existingAssetInThisStrategy)
        : [];
      if (linkedLiabilities.length > 0) {
        updatedLiabilities = addOrUpdateObjectInArray(
          updatedLiabilities,
          linkedLiabilities.map(linkedLiability => ({
            ...linkedLiability,
            ...getUpdatedLinkedVariablesOfLiability(
              strategy,
              updatingAsset,
              existingAssetInThisStrategy,
              linkedLiability
            )
          }))
        );
      }

      let updatedInvestments = [...strategy['investments']];
      if (relatedFixedTransfers?.length > 0) {
        updatedInvestments = addOrUpdateObjectInArray(
          updatedInvestments,
          relatedFixedTransfers.map(t => ({
            ...t,
            ...updateDatesOfTransferAccordingToAsset(t, existingAssetInThisStrategy, updatingAsset)
          }))
        );
      }

      return {
        ...strategy,
        [isFixedIncome ? 'fixedIncomeAssets' : 'assets']: updatedAssets,
        liabilities: updatedLiabilities,
        investments: updatedInvestments
      };
    });

    setAssetActionInProgress(true);

    const { error, response } = await updateStrategy(allStrategiesToBeUpdated);

    setAssetActionInProgress(false);
    if (error) {
      return toast.error(error);
    }

    onCustomerUpdate && onCustomerUpdate(response);
    toast.success(`Successfully ${toBeEditedAsset ? 'updated' : 'added'} asset ${assetToBePushed['name']}`);

    setAssetModalMeta(null);
  };

  const onLiabilityFormSubmit = async newLiability => {
    const { linkedAsset, toBeEditedLiability } = liabilityModalMeta;

    const namesToCheck = selectedStrategy.liabilities
      .map(l => l.name)
      .filter(name => (toBeEditedLiability ? name !== toBeEditedLiability.name : true));

    if (namesToCheck.includes(newLiability['name'])) {
      return toast.error(
        'There is already an liability with the same name, please change the name of the liability and try again!'
      );
    }

    newLiability['editMode'] = newLiability['Mode'] === 'Edit Mode';
    newLiability['startYear'] = Number(newLiability['startYear']);
    newLiability['endYear'] = newLiability['endYear'] ? Number(newLiability['endYear']) : undefined;
    newLiability['startMonth'] = Number(newLiability['startMonth']);
    newLiability['endMonth'] = Number(newLiability['endMonth']);

    if (linkedAsset) {
      newLiability.relatedAssets = [linkedAsset.name];
    }

    //if a currencyConversion has taken place
    if (liabilityModalMeta.currencyConversionInfo) {
      newLiability.valueCurrencyConversionInfo = {
        conversionCurrency: liabilityModalMeta.currencyConversionInfo.fromCurrency,
        conversionRate: liabilityModalMeta.currencyConversionInfo.conversionRate,
        conversionAmount: liabilityModalMeta.currencyConversionInfo.amount
      };
    }

    const liabilityToBePushed = toBeEditedLiability ? { ...toBeEditedLiability, ...newLiability } : newLiability;

    if (liabilityModalMeta.updateGroup) {
      let strategyOptions = unkilledStrategies.filter(s => s._id !== selectedStrategy._id);

      let { problemStrategies, warningStrategies, changedFields, allFields } = findCommonGroupEditProblems({
        selectedStrategy,
        strategiesToCheck: strategyOptions,
        instrumentKey: 'liabilities',
        instrumentName: 'liability',
        instrument: newLiability,
        toBeEditedInstrument: toBeEditedLiability
      });

      let {
        problemStrategies: problemStrategiesLiabilities,
        warningStrategies: warningStrategiesLiabilities
      } = findLiabilityEditProblems({
        strategiesToCheck: strategyOptions,
        liability: newLiability,
        linkedAsset,
        toBeEditedLiability
      });

      problemStrategies.push(...problemStrategiesLiabilities);

      warningStrategies.push(...warningStrategiesLiabilities);

      const problemStrategyNames = problemStrategies.map(p => p.strategyName);
      strategyOptions = strategyOptions.filter(s => !problemStrategyNames.includes(s.name));

      setStrategyGroupSelectionMeta({
        selectedStrategies: strategyOptions
          .filter(s => !warningStrategies.map(s => s._id).includes(s._id))
          .map(s => s._id),
        strategiesOptions: strategyOptions,
        problemStrategies,
        warningStrategies,
        fieldsToShow: allFields,
        originalObject: toBeEditedLiability,
        selectedFields: Object.keys(changedFields),
        onSubmit: (selectedStrategies, changedFields) =>
          addUpdateLiabilityInStrategies({
            selectedStrategies,
            liabilityToBePushed,
            toBeEditedLiability,
            linkedAsset,
            changedFields
          }),
        progressText: `${toBeEditedLiability ? 'Updating' : 'Adding'} liability...`
      });
      return;
    }

    addUpdateLiabilityInStrategies({ liabilityToBePushed, toBeEditedLiability, linkedAsset });
  };

  const addUpdateLiabilityInStrategies = async ({
    selectedStrategies = [],
    liabilityToBePushed,
    toBeEditedLiability,
    linkedAsset,
    changedFields
  }) => {
    setStrategyGroupSelectionMeta(null);

    let allStrategiesToBeUpdated = [
      activeCustomer['strategies'].find(s => s._id === selectedStrategy._id),
      ...selectedStrategies.map(sId => activeCustomer['strategies'].find(s => s._id === sId))
    ];

    const linkedBankAccountName = selectedStrategy['assets'].find(a => a._id === liabilityToBePushed.linkedBankAccount)
      ?.name;

    allStrategiesToBeUpdated = allStrategiesToBeUpdated.map(strategy => {
      const liabilitiesToSearch = strategy['liabilities'];

      const existingLiabilityInThisStrategy = toBeEditedLiability
        ? liabilitiesToSearch.find(l => l.name === toBeEditedLiability.name)
        : undefined;

      //if creating new liability, use complete object, otherwise only replace keys which come from changed fields
      const updatingLiability =
        changedFields && existingLiabilityInThisStrategy
          ? { ...existingLiabilityInThisStrategy, ...changedFields }
          : { ...liabilityToBePushed };

      //update linked bank account id from its own strategy
      updatingLiability.linkedBankAccount =
        strategy['assets'].find(a => a.category === 'Cash' && a.name === linkedBankAccountName)?._id || 'not_linked';

      const updatedLiabilities = addOrUpdateObjectInArray(strategy.liabilities, updatingLiability);

      const updatedStrategy = {
        ...strategy,
        liabilities: updatedLiabilities
      };

      //if there is a new linked asset
      if (linkedAsset && !linkedAsset._id) {
        const isFixedIncome = linkedAsset.category === 'Fixed Income';
        let updatedAssets = sortAssetsAccordingToLiquidationScore({
          assetToBePushed: linkedAsset
        });

        updatedStrategy[isFixedIncome ? 'fixedIncomeAssets' : 'assets'] = updatedAssets;
      }

      return updatedStrategy;
    });

    setLiabilityModalMeta({ ...liabilityModalMeta, showProgress: true });

    const { error, response } = await updateStrategy(allStrategiesToBeUpdated);
    setLiabilityModalMeta({ ...liabilityModalMeta, showProgress: false });

    if (error) {
      return toast.error(error);
    }

    onCustomerUpdate && onCustomerUpdate(response);
    toast.success(
      `Successfully ${toBeEditedLiability ? 'updated' : 'added'} liability ${liabilityToBePushed['name']}.`
    );

    setLiabilityModalMeta(null);
  };

  const onAssetChange = (e, manualEvent = {}) => {
    let { selectedValue, presetFieldsToOverride = {}, resetValue, overRideStartMonth, overRideStartYear } = manualEvent;

    let linkedAsset;

    if (!selectedValue) {
      const element = e ? e.target : document.getElementById('assetName');
      selectedValue = element?.value;
    }

    linkedAsset = selectedValue && cloneDeep(calculatedStrategy.assets.find(a => a.name === selectedValue));
    if (linkedAsset && overRideStartYear) {
      linkedAsset.buyingYear = overRideStartYear;
      linkedAsset.buyingMonth = overRideStartMonth;
    }

    const nameInput = document.getElementById('name');
    //change to Annuity if there is no linked asset
    let loanType = liabilityModalMeta.loanType !== 'Margin' || linkedAsset ? liabilityModalMeta.loanType : 'Annuity';

    if (e || resetValue) {
      let startMonthYear;

      if (linkedAsset) {
        document.getElementById('startYear').value = linkedAsset.buyingYear;
        document.getElementById('startMonth').value = linkedAsset.buyingMonth;
        startMonthYear = {
          month: linkedAsset.buyingMonth,
          year: linkedAsset.buyingYear
        };
      }

      const preset = activeCustomer.comp?.presets?.find(p => p.name === linkedAsset?.preset);
      if (preset) loanType = preset.loanType;

      const newLoanName = getLoanNamePrefill(linkedAsset, loanType === 'Margin', translate);
      nameInput.value = newLoanName;

      //apply preset when linked asset is changed
      let endMonthYear;
      if (linkedAsset) {
        applyPresetFromPresetName(linkedAsset.preset, activeCustomer.comp, linkedAsset, presetFieldsToOverride, true);
        endMonthYear = getEndYearOfLiability(linkedAsset, false, incompleteYearOfStrategy, preset?.timeToMaturity);
        if (preset) {
          setTimeout(() => {
            updateLoanStartMonthAndYearForPaperApartment(
              linkedAsset,
              Number(preset['loanValue'] || 0) * 0.01 * linkedAsset.value
            );
          }, 200);
        } else {
          setTimeout(() => {
            document.getElementById('valueInPercentage').value = '100 %';
            document.getElementById('value').value = formatCurrency(linkedAsset.value);
            setTimeout(() => {
              updateMonthlyPaymentFromLoanValue();
            }, 300);
          }, 100);
        }
      } else {
        applyPreset(loanTypePresets[loanType], activeCustomer.comp);
        endMonthYear = updateEndYearOfLiability(linkedAsset);
      }
      setLiabilityModalMeta(liabilityModalMeta => ({
        ...liabilityModalMeta,
        linkedAsset,
        loanType,
        ...(startMonthYear?.year
          ? { liabilityStartYear: startMonthYear.year, liabilityStartMonth: startMonthYear.month }
          : {}),
        ...(endMonthYear.year ? { liabilityEndYear: endMonthYear.year, liabilityEndMonth: endMonthYear.month } : {})
      }));
    } else {
      if (linkedAsset && !nameInput.value) {
        const newLoanName = getLoanNamePrefill(linkedAsset, loanType === 'Margin', translate);
        nameInput.value = newLoanName;
      }

      setLiabilityModalMeta(liabilityModalMeta => ({
        ...liabilityModalMeta,
        linkedAsset,
        loanType
      }));
    }
  };

  const onLoanTimeToMaturityChange = e => {
    if (!e) return;
    const { year: liabilityEndYear, month: liabilityEndMonth } = updateEndYearOfLiability(
      liabilityModalMeta?.linkedAsset,
      false,
      incompleteYearOfStrategy
    );

    setLiabilityModalMeta(liabilityModalMeta => ({
      ...liabilityModalMeta,
      ...(liabilityEndYear ? { liabilityEndYear, liabilityEndMonth } : {})
    }));

    updateMonthlyPaymentFromLoanValue();
  };

  useEffect(() => {
    if (!liabilityModalMeta) return;

    const endYearElem = document.getElementById('endYear');
    const endMonthElem = document.getElementById('endMonth');

    if (endYearElem && endMonthElem) {
      endYearElem.value = liabilityModalMeta.liabilityEndYear;
      endMonthElem.value = liabilityModalMeta.liabilityEndMonth;
    }
  }, [liabilityModalMeta]);

  const updateStrategy = async strategiesToUpdate => {
    if (!Array.isArray(strategiesToUpdate)) strategiesToUpdate = [strategiesToUpdate];

    strategiesToUpdate = strategiesToUpdate.map(strategyToUpdate => ({
      ...strategyToUpdate,
      assets: strategyToUpdate.assets.filter(asset => asset.category !== 'Fixed Income')
    }));

    return await makeApiRequests({
      endpoint: ENDPOINTS.USERS_UPDATE,
      requestBody: {
        strategies: addOrUpdateObjectInArray(activeCustomer['strategies'], strategiesToUpdate),
        _id: activeCustomer['_id']
      }
    });
  };

  const selectStrategy = strategy => {
    if (!strategy) return setSelectedStrategy();

    const clonedStrategy = cloneDeep(strategy);
    //apply scenario
    if (activeScenario.changeInPercentage) {
      clonedStrategy.assets.forEach(a => {
        a.returnAppreciation = a.returnAppreciation + activeScenario.changeInPercentage * a.returnAppreciation;
        a.cashflowAppreciation = a.cashflowAppreciation + activeScenario.changeInPercentage * a.cashflowAppreciation;
      });
    }

    if (inflationApplied && inflationValue) {
      clonedStrategy.assets.forEach(a => {
        a.returnAppreciation = a.returnAppreciation - inflationValue;
        a.cashflowAppreciation = a.cashflowAppreciation - inflationValue;
      });
      clonedStrategy.liabilities.forEach(a => {
        a.interest = a.interest - inflationValue;
      });
      clonedStrategy.fixedIncomeAssets.forEach(a => {
        a.interest = a.interest - inflationValue;
      });
      clonedStrategy.customCashflows.forEach(c => {
        c.yearlyGrowthRate = c.yearlyGrowthRate - inflationValue;
      });
    }

    //sort in order of "fixed"-> "relative" -> "relative-down"
    const order = ['fixed', 'relative-down', 'relative'];
    clonedStrategy.investments?.sort((a, b) => {
      const alphaCompare = order.indexOf(a['transferValueType']) - order.indexOf(b['transferValueType']);
      if (alphaCompare !== 0) return alphaCompare;

      return a.startYear === b.startYear ? a.startMonth - b.startMonth : a.startYear - b.startYear;
    });

    setSelectedStrategy(clonedStrategy);
  };

  const onNextStrategyClick = () => {
    const currentStrategyIndex = unkilledStrategies.findIndex(s => s.name === selectedStrategy.name);
    if (currentStrategyIndex + 1 === unkilledStrategies.length) return;

    selectStrategy(unkilledStrategies[currentStrategyIndex + 1]);
  };

  const onPrevStrategyClick = () => {
    const currentStrategyIndex = unkilledStrategies.findIndex(s => s.name === selectedStrategy.name);
    if (currentStrategyIndex <= 0) return;

    selectStrategy(unkilledStrategies[currentStrategyIndex - 1]);
  };

  const onAssetLiabilityEditClick = (itemType, item) => {
    if (itemType === 'assets') {
      setAssetModalMeta({
        toBeEditedAsset: selectedStrategy.assets.find(a => a._id === item._id)
      });
    } else {
      const strategyWithFilledData = calculateBalanceSheet(
        selectedStrategy,
        null,
        null,
        translate,
        false,
        adminCompTable,
        activeCustomer['comp']
      );
      let linkedAsset = strategyWithFilledData.assets.find(a => a.name === item.relatedAssets?.[0]);

      setLiabilityModalMeta({
        cashAssetOptions: selectedStrategy['assets'].filter(a => a.category === 'Cash'),
        toBeEditedLiability: {
          ...item,
          assetName: linkedAsset ? linkedAsset.name : '',
          loanValueIn: 'Number',
          Mode: item.editMode ? 'Edit Mode' : 'Setup Mode'
        },
        assetOptions: selectedStrategy['assets'].filter(a => a.name !== primaryBankAccountName),
        linkedAsset,
        loanType: item.type,
        loanAutoCalculated: item.endDateCalculatedAutomatically,
        liabilityStartYear: item.startYear,
        liabilityStartMonth: item.startMonth,
        liabilityEndYear: item.endYear,
        liabilityEndMonth: item.endMonth,
        loanEditMode: item.editMode
      });
    }
  };

  const onDuplicateAssetLiabilityClick = async (
    itemType,
    item,
    skipLinkedLiabilityCheck = false,
    duplicateLinkedLiabilities
  ) => {
    const assetsToPush = [];
    const liabilitiesToPush = [];

    const isFixedIncome = itemType === 'assets' && item.category === 'Fixed Income';

    const currentStrategy = activeCustomer['strategies'].find(s => s._id === selectedStrategy._id);
    if (itemType === 'assets') {
      let assetToDuplicate = currentStrategy['assets'].find(a => a._id === item._id);

      //find if there is linked loan
      const linkedLoans = findLinkedLiabilities(currentStrategy.liabilities, assetToDuplicate);

      if (!skipLinkedLiabilityCheck && linkedLoans.length > 0) {
        return setAlertModalMeta({
          alertText: translate('duplicate_linked_liabilities'),
          onContinueClick: () => {
            onDuplicateAssetLiabilityClick(itemType, item, true, true);
            setAlertModalMeta(null);
          },
          onContinue2Click: () => {
            onDuplicateAssetLiabilityClick(itemType, item, true, false);
            setAlertModalMeta(null);
          },
          continue2ButtonText: translate('no')
        });
      }

      let newAssetToPush = { ...assetToDuplicate, _id: undefined };
      newAssetToPush.name = findNewNameInstrument(
        currentStrategy['assets'].map(a => a.name),
        assetToDuplicate.name
      );

      //update startYear of this asset
      //if asset has sellingYear, new asset will start from this time
      let isForwarded = false;
      let { year: endingYearOfDuplicatingAsset, month: endingMonthOfDuplicatingAsset } =
        assetToDuplicate.category === 'Anona'
          ? findMonthYearAtWhichAssetBecomesZero(
              item.yearlyValues,
              assetToDuplicate.buyingMonth,
              assetToDuplicate.buyingYear,
              assetToDuplicate.sellingMonth,
              assetToDuplicate.sellingYear
            )
          : { year: assetToDuplicate.sellingYear, month: assetToDuplicate.sellingMonth };

      if (endingYearOfDuplicatingAsset) {
        isForwarded = true;
        newAssetToPush.buyingYear = endingYearOfDuplicatingAsset;
        newAssetToPush.buyingMonth = endingMonthOfDuplicatingAsset;

        if (assetToDuplicate.sellingYear) {
          const diffInMonths = findDiffInNumberOfMonths(
            assetToDuplicate.buyingMonth,
            assetToDuplicate.buyingYear,
            endingMonthOfDuplicatingAsset,
            endingYearOfDuplicatingAsset
          );

          const { month: newSellingMonth, year: newSellingYear } = addMonthAndYear(
            newAssetToPush.buyingMonth,
            newAssetToPush.buyingYear,
            diffInMonths
          );

          const finalYearOfStrategy = getFinalYearOfStrategy(currentStrategy);

          newAssetToPush.sellingYear = newSellingYear <= finalYearOfStrategy ? newSellingYear : null;
          newAssetToPush.sellingMonth = newSellingMonth;
        }
        newAssetToPush.editMode = true;
      }

      assetsToPush.push(newAssetToPush);

      //duplicate linked liabilities
      if (duplicateLinkedLiabilities) {
        liabilitiesToPush.push(
          ...linkedLoans.map(l => ({
            ...l,
            editMode: isForwarded ? true : l.editMode,
            _id: undefined,
            name: findNewNameInstrument(
              currentStrategy['liabilities'].map(a => a.name),
              l.name
            ),
            ...getUpdatedLinkedVariablesOfLiability(currentStrategy, newAssetToPush, null, l)
          }))
        );
      }
    } else {
      let liabilityToDuplicate = currentStrategy['liabilities'].find(a => a._id === item._id);

      let newLiabilityToPush = { ...liabilityToDuplicate, _id: undefined };
      newLiabilityToPush.name = findNewNameInstrument(
        currentStrategy['liabilities'].map(a => a.name),
        liabilityToDuplicate.name
      );

      //update startYear of this loan
      //if loan has end year, new loan will start from this time
      //make sure that loan is within start and end of asset
      let isForwarded = false;

      if (liabilityToDuplicate.endYear) {
        isForwarded = true;
        let newStartYear = liabilityToDuplicate.endYear;
        let newStartMonth = liabilityToDuplicate.endMonth;

        const diffInMonths = findDiffInNumberOfMonths(
          liabilityToDuplicate.startMonth,
          liabilityToDuplicate.startYear,
          liabilityToDuplicate.endMonth,
          liabilityToDuplicate.endYear
        );

        let { month: newSellingMonth, year: newSellingYear } = addMonthAndYear(
          newStartMonth,
          newStartYear,
          diffInMonths
        );

        let linkedAsset = currentStrategy['assets'].find(a => a.name === item.relatedAssets[0]);
        if (liabilityToDuplicate.endDateCalculatedAutomatically && linkedAsset?.sellingYear) {
          const linkedAssetEndDate = new Date(linkedAsset.sellingYear, linkedAsset.sellingMonth, 1, 0, 0, 0, 0);
          const loanNewStartDate = new Date(newStartYear, newStartMonth, 1, 0, 0, 0, 0);
          const loanNewEndDate = new Date(newSellingYear, newSellingMonth, 1, 0, 0, 0, 0);

          const isLoanStartingAfterAssetEnd = loanNewStartDate.getTime() >= linkedAssetEndDate.getTime();
          const isLoanEndingAfterAssetEnd = loanNewEndDate.getTime() > linkedAssetEndDate.getTime();

          if (isLoanStartingAfterAssetEnd) {
            //no place to trim end date, so revert to original
            newStartMonth = liabilityToDuplicate.startMonth;
            newStartYear = liabilityToDuplicate.startYear;
            newSellingMonth = liabilityToDuplicate.endMonth;
            newSellingYear = liabilityToDuplicate.endYear;
            isForwarded = false;
          } else if (isLoanEndingAfterAssetEnd) {
            //try to trim it so that both have same end date
            newSellingMonth = linkedAsset.sellingMonth;
            newSellingYear = linkedAsset.sellingYear;
          }
        }

        const finalYearOfStrategy = getFinalYearOfStrategy(currentStrategy);
        newSellingYear = newSellingYear <= finalYearOfStrategy ? newSellingYear : null;

        newLiabilityToPush.startYear = newStartYear;
        newLiabilityToPush.startMonth = newStartMonth;
        newLiabilityToPush.endYear = newSellingYear;
        newLiabilityToPush.endMonth = newSellingMonth;
        newLiabilityToPush.editMode = isForwarded ? true : liabilityToDuplicate.editMode;
      }

      liabilitiesToPush.push(newLiabilityToPush);
    }

    const updatedStrategy = {
      ...currentStrategy,
      liabilities: [...currentStrategy.liabilities, ...liabilitiesToPush]
    };

    if (assetsToPush[0]) {
      updatedStrategy[isFixedIncome ? 'fixedIncomeAssets' : 'assets'] = sortAssetsAccordingToLiquidationScore({
        currentStrategy,
        assetToBePushed: assetsToPush[0]
      });
    }

    setUpdatingStrategy(true);

    const { error, response } = await updateStrategy(updatedStrategy);
    setUpdatingStrategy(false);

    if (error) {
      return toast.error(error);
    }

    onCustomerUpdate && onCustomerUpdate(response);
    toast.success(`Successfully updated strategy.`);
  };

  const onLoanTypeChange = e => {
    updateMonthlyPaymentFromLoanValue();
    if (!e) return;

    const loanType = e.target.value;
    setLiabilityModalMeta({ ...liabilityModalMeta, loanType });
    const { linkedAsset } = liabilityModalMeta;

    if (linkedAsset) {
      if (loanType === 'Margin') {
        const { year: liabilityEndYear, month: liabilityEndMonth } = updateEndYearOfLiability(
          linkedAsset,
          false,
          incompleteYearOfStrategy
        );
        setLiabilityModalMeta(liabilityModalMeta => ({
          ...liabilityModalMeta,
          ...(liabilityEndYear ? { liabilityEndYear, liabilityEndMonth } : {})
        }));
      }
    } else {
      setTimeout(() => {
        applyPreset(loanTypePresets[loanType], activeCustomer.comp);
        const { year: liabilityEndYear, month: liabilityEndMonth } = updateEndYearOfLiability(
          null,
          false,
          incompleteYearOfStrategy
        );
        setLiabilityModalMeta(liabilityModalMeta => ({
          ...liabilityModalMeta,
          ...(liabilityEndYear ? { liabilityEndYear, liabilityEndMonth } : {})
        }));
      }, [100]);
    }

    const nameInput = document.getElementById('name');
    nameInput.value = getLoanNamePrefill(linkedAsset, loanType === 'Margin', translate);
  };

  const onMarginFloorChange = e => {
    const { linkedAsset } = liabilityModalMeta;
    if (!linkedAsset) return;

    const element = e ? e.target : document.getElementById('marginFloor');
    const floorPercentage = element.value ? Number(element.value.replaceAll('%', '')) : 0;

    updateLoanValueFromMarginFloor({ linkedAsset, floorPercentage });
  };

  const onEndDateCalculatedAutomaticallyChange = e => {
    if (e) {
      const { year: liabilityEndYear, month: liabilityEndMonth } = updateEndYearOfLiability(
        liabilityModalMeta.linkedAsset,
        false,
        incompleteYearOfStrategy
      );
      setLiabilityModalMeta({
        ...liabilityModalMeta,
        loanAutoCalculated: e.target.checked,
        ...(liabilityEndYear ? { liabilityEndYear, liabilityEndMonth } : {})
      });
    }
  };

  const onOneOffEventDeleteClick = item => {
    setAlertModalMeta({
      alertText: translate('are_you_sure_you_want_to_delete_one_off_event'),
      onContinueClick: () => deleteOneOffEvent(item)
    });
  };

  const onDeleteCashflowClick = item => {
    if (item.isReinvestment) {
      onDeleteReinvestmentOption(item.reinvestment);
      return;
    }

    setAlertModalMeta({
      alertText: translate('are_you_sure_you_want_to_delete_cashflow'),
      onContinueClick: () => deleteCashflow(item),
      onContinue2Click: () => onGroupDeleteCashflowClick({ cashflow: item }), //group delete only asset
      continue2ButtonText: translate('group_delete_cashflow')
    });
  };

  const onGroupDeleteCashflowClick = ({ cashflow }) => {
    let strategyOptions = unkilledStrategies.filter(s => s._id !== selectedStrategy._id);

    let problemStrategies = findCommonGroupDeleteProblems({
      selectedStrategy,
      strategiesToCheck: strategyOptions,
      instrumentKey: 'customCashflows',
      instrumentName: 'cashflow',
      instrument: cashflow
    });

    const problemStrategyNames = problemStrategies.map(p => p.strategyName);
    strategyOptions = strategyOptions.filter(s => !problemStrategyNames.includes(s.name));

    setStrategyGroupSelectionMeta({
      selectedStrategies: strategyOptions.map(s => s._id),
      strategiesOptions: strategyOptions,
      problemStrategies,
      onSubmit: selectedStrategies =>
        deleteCashflowFromStrategies({
          selectedStrategies,
          toBeDeletedCashflow: cashflow
        }),
      progressText: `Deleting cashflow...`
    });
  };

  const deleteCashflowFromStrategies = async ({ selectedStrategies = [], toBeDeletedCashflow }) => {
    setStrategyGroupSelectionMeta(null);

    let allStrategiesToBeUpdated = [
      activeCustomer['strategies'].find(s => s._id === selectedStrategy._id),
      ...selectedStrategies.map(sId => activeCustomer['strategies'].find(s => s._id === sId))
    ];

    allStrategiesToBeUpdated = allStrategiesToBeUpdated.map(strategy => {
      const updatedStrategy = { ...strategy };
      updatedStrategy.customCashflows = deleteObjectInArray(
        updatedStrategy.customCashflows,
        toBeDeletedCashflow,
        'name'
      );

      return updatedStrategy;
    });

    setAlertModalMeta(previousState => ({ ...previousState, showProgress: true }));

    const { error, response } = await updateStrategy(allStrategiesToBeUpdated);
    setAlertModalMeta(previousState => ({ ...previousState, showProgress: false }));

    if (error) {
      return toast.error(error);
    }

    onCustomerUpdate && onCustomerUpdate(response);
    toast.success(`Successfully deleted cashflow.`);
    setAlertModalMeta(null);
  };

  const onDeleteReinvestmentOption = item => {
    setAlertModalMeta({
      alertText: translate('are_you_sure_you_want_to_delete_investment'),
      onContinueClick: () => deleteReinvestmentOption(item),
      onContinue2Click: () => onGroupDeleteReinvestmentClick({ reinvestment: item }), //group delete
      continue2ButtonText: translate('group_delete_reinvestment')
    });
  };

  const onDisableReinvestmentOption = item => {
    const isDisabled = Boolean(item.disabled);

    const toBeDisabledInvestment = selectedStrategy.investments.find(investment => investment._id === item._id);
    toBeDisabledInvestment.disabled = !isDisabled;
    setSelectedStrategy({ ...selectedStrategy });
  };

  const onDisableAllReinvestmentsOptions = disable => {
    setSelectedStrategy({
      ...selectedStrategy,
      investments: selectedStrategy.investments.map(i => ({ ...i, disabled: disable }))
    });
  };

  const onGroupDeleteReinvestmentClick = ({ reinvestment }) => {
    let strategyOptions = unkilledStrategies.filter(s => s._id !== selectedStrategy._id);

    let problemStrategies = findReinvestmentDeleteProblems({
      selectedStrategy,
      strategiesToCheck: strategyOptions,
      reinvestment
    });

    const problemStrategyNames = problemStrategies.map(p => p.strategyName);
    strategyOptions = strategyOptions.filter(s => !problemStrategyNames.includes(s.name));

    setStrategyGroupSelectionMeta({
      selectedStrategies: strategyOptions.map(s => s._id),
      strategiesOptions: strategyOptions,
      problemStrategies,
      onSubmit: selectedStrategies =>
        deleteReinvestmentFromStrategies({
          selectedStrategies,
          toBeDeletedReinvestment: reinvestment
        }),
      progressText: `Deleting reinvestment...`
    });
  };

  const deleteReinvestmentFromStrategies = async ({ selectedStrategies = [], toBeDeletedReinvestment }) => {
    setStrategyGroupSelectionMeta(null);

    let allStrategiesToBeUpdated = [
      activeCustomer['strategies'].find(s => s._id === selectedStrategy._id),
      ...selectedStrategies.map(sId => activeCustomer['strategies'].find(s => s._id === sId))
    ];

    allStrategiesToBeUpdated = allStrategiesToBeUpdated.map(strategy => {
      const updatedStrategy = { ...strategy };
      updatedStrategy.investments = deleteObjectInArray(
        updatedStrategy.investments,
        toBeDeletedReinvestment,
        'groupId'
      );

      return updatedStrategy;
    });

    setAlertModalMeta(previousState => ({ ...previousState, showProgress: true }));

    const { error, response } = await updateStrategy(allStrategiesToBeUpdated);
    setAlertModalMeta(previousState => ({ ...previousState, showProgress: false }));

    if (error) {
      return toast.error(error);
    }

    onCustomerUpdate && onCustomerUpdate(response);
    toast.success(`Successfully deleted investment.`);
    setAlertModalMeta(null);
  };

  const deleteReinvestmentOption = async item => {
    setAlertModalMeta(previousState => ({ ...previousState, showProgress: true }));
    const toBeDeletedInvestment = selectedStrategy.investments.find(investment => investment._id === item._id);

    const updatedStrategy = { ...selectedStrategy };
    updatedStrategy.investments = deleteObjectInArray(updatedStrategy.investments, toBeDeletedInvestment);

    const { error, response } = await updateStrategy(updatedStrategy);
    onCustomerUpdate && onCustomerUpdate(response);
    toast.success(`Successfully deleted investment option.`);
    setAlertModalMeta(null);
  };

  const deleteCashflow = async item => {
    setAlertModalMeta(previousState => ({ ...previousState, showProgress: true }));
    const toBeDeletedCashflow = selectedStrategy.customCashflows.find(
      customCashflow => customCashflow._id === item._id
    );

    const updatedStrategy = { ...selectedStrategy };
    updatedStrategy.customCashflows = deleteObjectInArray(updatedStrategy.customCashflows, toBeDeletedCashflow);
    const { error, response } = await updateStrategy(updatedStrategy);
    setAlertModalMeta(previousState => ({ ...previousState, showProgress: false }));

    if (error) {
      return toast.error(error);
    }
    onCustomerUpdate && onCustomerUpdate(response);
    toast.success(`Successfully deleted cashflow.`);
    setAlertModalMeta(null);
  };

  const deleteOneOffEvent = async item => {
    setAlertModalMeta(previousState => ({ ...previousState, showProgress: true }));
    const toBeDeletedEvent = { ...item };
    delete toBeDeletedEvent.name;

    const updatedStrategy = { ...selectedStrategy };
    updatedStrategy.oneOffChanges = deleteObjectInArray(updatedStrategy.oneOffChanges, toBeDeletedEvent);

    const { error, response } = await updateStrategy(updatedStrategy);

    if (error) {
      return toast.error(error);
    }

    onCustomerUpdate && onCustomerUpdate(response);
    toast.success(`Successfully deleted one off event.`);
    setAlertModalMeta(null);
  };

  const onAddCashflowClick = () => {
    setCashflowFormData({
      type: 'Income',
      name: '',
      notes: '',
      yearlyGrowthRate: 0,
      monthlyValue: 0,
      startYear: selectedStrategy.initialYear,
      startMonth: 0,
      affectsRiskManagement: false,
      isRent: false,
      linkedBankAccount: selectedStrategy['assets'].filter(a => a.category === 'Cash')[0]?._id,
      linkedApartment: selectedStrategy['assets'].filter(a => a.category === 'Real Estate')[0]?._id
    });
  };

  const onEditCashflowClick = cashflow => {
    if (cashflow.isReinvestment) {
      onEditReinvestmentOption(cashflow.reinvestment);
      return;
    }

    setCashflowFormData({
      ...cashflow,
      type: cashflow.type === 'inflow' ? 'Income' : 'Expenditure'
    });
  };

  useEffect(() => {
    if (Object.keys(cashflowFormData).length) {
      setCashflowDialogOpen(true);
    }

    setEditingCashflowChanges(cashflowFormData?.cashflowChanges?.map(c => ({ ...c, id: uniqueId() })) || []);
  }, [cashflowFormData]);

  const onAddReinvestmentOption = () => {
    setInvestmentFormData({
      fromId: '',
      toId: '',
      transferValueType: 'fixed',
      frequency: 'monthly',
      type: 're-investment',
      value: 0,
      startYear: selectedStrategy.initialYear,
      startMonth: 0,
      continueAfterSell: false
    });
  };

  const onEditReinvestmentOption = investment => {
    const newInvestment = cloneDeep(investment);

    //instrument might have been deleted, so ignoring deleted instrument
    const fromInstrument =
      investment.fromType &&
      investment.fromId &&
      (investment.fromType === 'asset' ? selectedStrategy.assets : selectedStrategy.liabilities).find(
        asset => asset._id === investment.fromId
      );
    const toInstrument =
      investment.toType &&
      investment.toId &&
      (investment.toType === 'asset' ? selectedStrategy.assets : selectedStrategy.liabilities).find(
        asset => asset._id === investment.toId
      );

    newInvestment.fromId = fromInstrument ? `${investment.fromType}:${fromInstrument._id}` : '';
    newInvestment.toId = toInstrument ? `${investment.toType}:${toInstrument._id}` : '';
    setInvestmentFormData({ ...newInvestment });
  };

  useEffect(() => {
    if (Object.keys(investmentFormData).length) {
      setReinvestmentOpen(true);
    }
  }, [investmentFormData]);

  const onAssetLiabilityDeleteClick = (itemType, item) => {
    if (itemType === 'assets') {
      let linkedLiabilities = findLinkedLiabilities(selectedStrategy.liabilities, item);

      setAlertModalMeta({
        alertText: `${translate('are_you_sure_you_want_to_delete_asset')} ${
          linkedLiabilities && linkedLiabilities.length > 0
            ? `. ${translate('asset_has_linked_loans', { n: linkedLiabilities.length })}`
            : ''
        }. ${translate('this_will_also_delete_related_investments')}`,
        continueText: translate('delete_only_asset'),
        onContinueClick: () =>
          deleteAssetLiabilityFromStrategies({
            toBeDeletedAsset: item
          }),
        onContinue2Click:
          linkedLiabilities && linkedLiabilities.length > 0
            ? () =>
                deleteAssetLiabilityFromStrategies({
                  toBeDeletedAsset: item,
                  toBeDeletedLiabilities: linkedLiabilities
                })
            : undefined,
        continue2ButtonText:
          linkedLiabilities && linkedLiabilities.length > 0 ? translate('delete_linked_liabilities_also') : undefined,
        onContinue3Click: () => onGroupDeleteAssetClick({ asset: item }), //group delete only asset
        continue3ButtonText: translate('group_delete_only_asset'),
        //group delete linked liabilities also
        onContinue4Click:
          linkedLiabilities && linkedLiabilities.length > 0
            ? () => onGroupDeleteAssetClick({ asset: item, linkedLiabilities })
            : undefined,
        continue4ButtonText:
          linkedLiabilities && linkedLiabilities.length > 0
            ? translate('group_delete_linked_liabilities_also')
            : undefined,
        progressText: `Deleting asset${`${
          linkedLiabilities && linkedLiabilities.length > 0 ? ' and linked liabilities' : ''
        }`}...`,
        size: 'lg'
      });
    } else {
      setAlertModalMeta({
        alertText: `${translate('are_you_sure_you_want_to_delete_liability')} ${translate(
          'this_will_also_delete_related_investments'
        )}`,
        onContinueClick: () =>
          deleteAssetLiabilityFromStrategies({
            toBeDeletedLiabilities: [item]
          }),
        progressText: `Deleting liability...`,
        onContinue2Click: () => onGroupDeleteLiabilityClick({ liability: item }), //group delete only asset
        continue2ButtonText: translate('group_delete_liability')
      });
    }
  };

  const onGroupDeleteAssetClick = ({ asset, linkedLiabilities = [] }) => {
    let strategyOptions = unkilledStrategies.filter(s => s._id !== selectedStrategy._id);

    let problemStrategies = findCommonGroupDeleteProblems({
      selectedStrategy,
      strategiesToCheck: strategyOptions,
      instrumentKey: 'assets',
      instrumentName: 'asset',
      instrument: asset,
      linkedLiabilities
    });

    const problemStrategyNames = problemStrategies.map(p => p.strategyName);
    strategyOptions = strategyOptions.filter(s => !problemStrategyNames.includes(s.name));

    setStrategyGroupSelectionMeta({
      selectedStrategies: strategyOptions.map(s => s._id),
      strategiesOptions: strategyOptions,
      problemStrategies,
      onSubmit: selectedStrategies =>
        deleteAssetLiabilityFromStrategies({
          selectedStrategies,
          toBeDeletedAsset: asset,
          toBeDeletedLiabilities: linkedLiabilities
        }),
      progressText: `Deleting assets${linkedLiabilities.length > 0 ? ' and liabilities' : ''}...`
    });
  };

  const onGroupDeleteLiabilityClick = ({ liability }) => {
    let strategyOptions = unkilledStrategies.filter(s => s._id !== selectedStrategy._id);

    let problemStrategies = findCommonGroupDeleteProblems({
      selectedStrategy,
      strategiesToCheck: strategyOptions,
      instrumentKey: 'liabilities',
      instrumentName: 'liability',
      instrument: liability
    });

    const problemStrategyNames = problemStrategies.map(p => p.strategyName);
    strategyOptions = strategyOptions.filter(s => !problemStrategyNames.includes(s.name));

    setStrategyGroupSelectionMeta({
      selectedStrategies: strategyOptions.map(s => s._id),
      strategiesOptions: strategyOptions,
      problemStrategies,
      onSubmit: selectedStrategies =>
        deleteAssetLiabilityFromStrategies({
          selectedStrategies,
          toBeDeletedLiabilities: [liability]
        }),
      progressText: `Deleting liability...`
    });
  };

  const deleteAssetLiabilityFromStrategies = async ({
    selectedStrategies = [],
    toBeDeletedAsset,
    toBeDeletedLiabilities = []
  }) => {
    setStrategyGroupSelectionMeta(null);

    let allStrategiesToBeUpdated = [
      activeCustomer['strategies'].find(s => s._id === selectedStrategy._id),
      ...selectedStrategies.map(sId => activeCustomer['strategies'].find(s => s._id === sId))
    ];

    allStrategiesToBeUpdated = allStrategiesToBeUpdated.map(strategy => {
      const updatedStrategy = {
        ...strategy
      };

      if (toBeDeletedAsset) {
        const assetCategory = toBeDeletedAsset.category === 'Fixed Income' ? 'fixedIncomeAssets' : 'assets';
        const toBeDeletedAssetId = updatedStrategy[assetCategory].find(a => a.name === toBeDeletedAsset.name)?._id;
        if (toBeDeletedAssetId) {
          updatedStrategy.investments = updatedStrategy.investments.filter(
            i =>
              !(
                (i.fromType === 'asset' && i.fromId === toBeDeletedAssetId) ||
                (i.toType === 'asset' && i.toId === toBeDeletedAssetId)
              )
          );
        }

        updatedStrategy[assetCategory] = deleteObjectInArray(updatedStrategy[assetCategory], toBeDeletedAsset, 'name');
      }

      toBeDeletedLiabilities.forEach(toBeDeletedLiability => {
        const toBeDeletedLiabiltyId = updatedStrategy.liabilities.find(l => l.name === toBeDeletedLiability.name)?._id;
        if (toBeDeletedLiabiltyId) {
          updatedStrategy.investments = updatedStrategy.investments.filter(
            i =>
              !(
                (i.fromType === 'liability' && i.fromId === toBeDeletedLiabiltyId) ||
                (i.toType === 'liability' && i.toId === toBeDeletedLiabiltyId)
              )
          );
        }

        updatedStrategy.liabilities = deleteObjectInArray(updatedStrategy.liabilities, toBeDeletedLiability, 'name');
      });

      return updatedStrategy;
    });

    setAlertModalMeta(previousState => ({ ...previousState, showProgress: true }));

    const { error, response } = await updateStrategy(allStrategiesToBeUpdated);

    setAlertModalMeta(previousState => ({ ...previousState, showProgress: false }));

    if (error) {
      return toast.error(error);
    }

    onCustomerUpdate && onCustomerUpdate(response);
    toast.success(
      `Successfully deleted ${
        toBeDeletedAsset
          ? toBeDeletedLiabilities.length > 0
            ? 'asset and its linked liabilities'
            : 'asset'
          : 'liability'
      }.`
    );
    setAlertModalMeta(null);
  };

  const onNotesSubmit = async () => {
    const { notes } = notesModalMeta;

    const strategy = activeCustomer['strategies'].find(s => s._id === selectedStrategy._id);
    strategy.notes = notes;

    //do not wait for response
    //notes change as soon as we type in real time
    //so there is no need to wait
    onCustomerUpdate && onCustomerUpdate(activeCustomer);

    const { response, error } = await updateStrategy({
      ...strategy,
      notes
    });
  };

  const onArmagedonClick = () => {
    setArmageddonDetailsModalMeta({ ...armageddonDetails });
  };

  //update armageddon on backend
  const onApplyArmageddon = async () => {
    setArmageddonDetailsModalMeta({ ...armageddonDetailsModalMeta, showProgress: true });

    const { startMonth, startYear, assetDepreciations } = armageddonDetailsModalMeta;

    const strategy = activeCustomer['strategies'].find(s => s._id === selectedStrategy._id);
    const { error, response } = await updateStrategy({
      ...strategy,
      defaultArmageddonConfig: {
        startMonth,
        startYear,
        assetDepreciations: assetDepreciations.map(d => ({ assetId: d._id, depreciation: d.depreciation }))
      }
    });

    setArmageddonDetailsModalMeta({ ...armageddonDetailsModalMeta, showProgress: false });

    if (error) {
      return toast.error(error);
    }

    setArmageddonDetails({
      ...armageddonDetailsModalMeta,
      assetDepreciations: armageddonDetailsModalMeta.assetDepreciations.map(a => ({
        ...a,
        monthlyDepreciation: getMonthlyCompoundedDepreciation(18, a.depreciation)
      }))
    });

    setArmageddonDetailsModalMeta(null);
    onCustomerUpdate && onCustomerUpdate(response);
  };

  const onArmageddonAssetDepreciationChange = (_id, depreciation) => {
    const { assetDepreciations = [] } = armageddonDetailsModalMeta;

    const updatedAsset = assetDepreciations.find(a => a._id === _id);
    if (updatedAsset) {
      updatedAsset.depreciation = depreciation;
      setArmageddonDetailsModalMeta({ ...armageddonDetailsModalMeta, assetDepreciations });
    }
  };

  const onActiveScenarioChange = scenario => {
    setActiveScenario(scenario);
    if (scenario.isArmageddon) {
      const { startMonth, startYear, assetDepreciations = [] } = selectedStrategy.defaultArmageddonConfig;
      setArmageddonDetails({
        startMonth: startMonth || 0,
        startYear:
          startYear && startYear >= selectedStrategy.initialYear && getFinalYearOfStrategy(selectedStrategy)
            ? startYear
            : selectedStrategy.initialYear + 1,
        assetDepreciations: selectedStrategy.assets
          .filter(a => ['Stocks', 'Real Estate'].includes(a.category))
          .map(a => {
            const defaultDepreciation = assetDepreciations.find(({ assetId }) => assetId === a._id);
            const depPercentage = defaultDepreciation ? defaultDepreciation.depreciation : a.crashPercentage;

            return {
              _id: a._id,
              name: a.name,
              depreciation: depPercentage,
              monthlyDepreciation: getMonthlyCompoundedDepreciation(18, depPercentage)
            };
          })
      });
    }
  };

  const onArmageddonWeakestTimeClick = () => {
    const tempStrategy = activeCustomer.strategies.find(s => s._id === selectedStrategy._id);
    const weakestMonthYear = findWeakestMonthYearOfStrategy(
      calculateBalanceSheet(tempStrategy, null, null, translate, false, adminCompTable, activeCustomer['comp'])
    );
    setArmageddonDetailsModalMeta({
      ...armageddonDetailsModalMeta,
      startYear: weakestMonthYear.year,
      startMonth: weakestMonthYear.month
    });
  };

  const onResetArmageddonClick = () => {
    setArmageddonDetailsModalMeta({
      ...armageddonDetailsModalMeta,
      assetDepreciations: selectedStrategy.assets
        .filter(a => ['Stocks', 'Real Estate'].includes(a.category))
        .map(a => {
          return {
            _id: a._id,
            name: a.name,
            depreciation: a.crashPercentage,
            monthlyDepreciation: getMonthlyCompoundedDepreciation(18, a.crashPercentage)
          };
        })
    });
  };

  const onAssetResort = async (sortedAssets = []) => {
    setUpdatingStrategy(true);

    const newAssets = cloneDeep(selectedStrategy.assets).filter(a => !sortedAssets.map(sa => sa._id).includes(a._id));
    newAssets.push(...sortedAssets);

    const { error, response } = await updateStrategy({
      ...selectedStrategy,
      assets: newAssets
    });

    setUpdatingStrategy(false);

    if (error) {
      return toast.error(error);
    }

    onCustomerUpdate && onCustomerUpdate(response);
  };

  const onItemQuickRenameSubmit = async (sectionName, instrument, newName) => {
    if (!newName?.trim()) {
      toast.error('Cannot be empty');
      return true;
    }

    const allInstruments =
      sectionName === 'customCashflows'
        ? [...selectedStrategy['customCashflows']]
        : [...selectedStrategy['assets'], ...selectedStrategy['liabilities'], ...selectedStrategy['fixedIncomeAssets']];
    const toBeUpdatedInstrument = allInstruments.find(i => i._id === instrument._id);

    if (!toBeUpdatedInstrument) {
      toast.error('Cannot rename this item');
      return true;
    }

    const namesToCheck = allInstruments.map(a => a.name).filter(name => name !== instrument.name);

    if (namesToCheck.includes(newName)) {
      toast.error('There is already an item with the same name, please change the name of this item and try again!');
      return;
    }

    //If this is an asset and there are linked liabilities of this asset, update the liability's linked asset
    let updatedLiabilities = selectedStrategy['liabilities'];
    if (sectionName === 'assets') {
      const linkedLiabilities = findLinkedLiabilities(selectedStrategy['liabilities'], instrument);
      linkedLiabilities.forEach(linkedLiability => {
        updatedLiabilities = addOrUpdateObjectInArray(updatedLiabilities, {
          ...linkedLiability,
          relatedAssets: [newName]
        });
      });
    }

    const getUpdatedItems = sectionName => ({
      [sectionName]: (sectionName === 'liabilities' ? updatedLiabilities : selectedStrategy[sectionName]).map(a => ({
        ...a,
        name: a._id === toBeUpdatedInstrument._id ? newName : a.name
      }))
    });

    const updatedStrategy =
      sectionName === 'customCashflows'
        ? {
            ...selectedStrategy,
            ...getUpdatedItems('customCashflows')
          }
        : {
            ...selectedStrategy,
            ...getUpdatedItems('assets'),
            ...getUpdatedItems('fixedIncomeAssets'),
            ...getUpdatedItems('liabilities')
          };

    setQuickRenaming(true);
    const { error, response } = await updateStrategy(updatedStrategy);

    setQuickRenaming(false);

    if (error) {
      return toast.error(error);
    }

    onCustomerUpdate && onCustomerUpdate(response);
    toast.success(`Successfully updated item name`);

    return true;
  };

  const onAddNewStrategyClick = () => {
    setStrategyModalMeta({ name: '' });
  };

  const onCloneStrategyClick = toBeClonedStrategy => {
    setStrategyModalMeta({ name: '', toBeClonedStrategy });
  };

  const onEditStrategyClick = existingStrategy => {
    setStrategyModalMeta({ existingStrategy, name: existingStrategy.name });
  };

  const onDeleteStrategyClick = strategy => {
    setAlertModalMeta({
      progressText: `${translate('deleting_strategy')}...`,
      onContinueClick: () => deleteStrategy(strategy),
      alertText: translate('are_you_sure_you_want_to_delete_strategy', { strategy: strategy?.name || '' })
    });
  };

  const onKillStrategyClick = strategy => {
    setAlertModalMeta({
      progressText: `${translate('archiving_strategy')}...`,
      onContinueClick: () => killStrategy(strategy, true),
      alertText: translate('are_you_sure_you_want_to_archive_strategy', { strategy: strategy?.name || '' })
    });
  };

  const onSelectStrategyClick = strategy => {
    markStrategyAsSelected(strategy);
  };

  const onRetreiveStrategyClick = strategy => {
    setAlertModalMeta({
      progressText: `${translate('unarchiving_strategy')}...`,
      onContinueClick: () => killStrategy(strategy, false),
      alertText: translate('are_you_sure_you_want_to_unarchive_strategy', { strategy: strategy?.name || '' })
    });
  };

  const onStrategySubmit = async form => {
    const { existingStrategy, toBeClonedStrategy } = strategyModalMeta;
    const { name, value, returnAppreciation, returnCashflow } = form;

    if (!name) return toast.error('Please provide name of the strategy!');

    if (
      activeCustomer.strategies
        .map(s => s.name)
        .filter(sName => !existingStrategy || sName !== existingStrategy.name)
        .includes(name.trim())
    )
      return toast.error('This name is already in use by some other strategy!');

    const totalYears = activeCustomer.strategies[0].totalYears || 10;

    setStrategyModalMeta({ ...strategyModalMeta, showProgress: true });

    let updatedStrategies = [];
    let newStrategy;
    if (toBeClonedStrategy) {
      if (!toBeClonedStrategy.groupId) {
        toBeClonedStrategy.groupId = uuid();
        updatedStrategies = addOrUpdateObjectInArray(activeCustomer.strategies, toBeClonedStrategy);
      }
      newStrategy = {
        ...toBeClonedStrategy,
        _id: undefined,
        name,
        totalYears,
        isSelected: false,
        isDefaultStrategy: false,
        isCreatedByClient: user?.role === 'Customer'
      };
      updatedStrategies = addOrUpdateObjectInArray(activeCustomer.strategies, newStrategy);
    } else {
      newStrategy = existingStrategy
        ? { ...existingStrategy, name }
        : {
            name,
            totalYears,
            isCreatedByClient: user?.role === 'Customer',
            showToCustomer: user?.role === 'Customer',
            groupId: uuid(),
            assets: [
              createDefaultBankAccount({
                initialCash: value,
                returnAppreciation,
                returnCashflow,
                month: activeCustomer.strategies[0]?.initialMonth || 0,
                year: activeCustomer.strategies[0]?.initialYear || new Date().getFullYear()
              })
            ],
            initialYear: activeCustomer.strategies[0]?.initialYear || new Date().getFullYear(),
            initialMonth: activeCustomer.strategies[0]?.initialMonth || 0
          };

      updatedStrategies = addOrUpdateObjectInArray(activeCustomer.strategies, newStrategy);
    }

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_UPDATE,
      requestBody: {
        strategies: updatedStrategies,
        _id: activeCustomer['_id']
      }
    });
    setStrategyModalMeta({ ...strategyModalMeta, showProgress: false });

    if (error) {
      toast.error(error);
      return;
    }

    onCustomerUpdate(response);
    if (toBeClonedStrategy) {
      selectStrategy(response.strategies.find(s => s.name === newStrategy.name));
    }

    setStrategyModalMeta(null);
    toast.success(`Strategy ${existingStrategy ? 'updated' : 'added'} successfully`);
  };

  const deleteStrategy = async strategy => {
    setAlertModalMeta(previousState => ({ ...previousState, showProgress: true }));

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_UPDATE,
      requestBody: {
        strategies: activeCustomer.strategies.filter(s => s._id !== strategy._id),
        _id: activeCustomer['_id']
      }
    });
    setAlertModalMeta(previousState => ({ ...previousState, showProgress: false }));

    if (error) {
      toast.error(error);
      return;
    }

    onCustomerUpdate(response);
    setAlertModalMeta(null);
    toast.success('Strategy deleted successfully');
  };

  const killStrategy = async (strategyToBeKilled, killed) => {
    setAlertModalMeta(previousState => ({ ...previousState, showProgress: true }));

    const strategy = activeCustomer['strategies'].find(s => s._id === strategyToBeKilled._id);

    const { response, error } = await updateStrategy({
      ...strategy,
      killed
    });

    setAlertModalMeta(previousState => ({ ...previousState, showProgress: false }));

    if (error) {
      toast.error(error);
      return;
    }

    onCustomerUpdate && onCustomerUpdate(response);
    setAlertModalMeta(null);
    toast.success(`Strategy ${killed ? 'archived' : 'unarchived'} successfully!`);
  };

  const markStrategyAsSelected = async strategyToBeSelected => {
    const isStrategySelected = strategyToBeSelected.isSelected;

    setUpdatingStrategy(true);

    const { response, error } = await updateStrategy(
      activeCustomer['strategies'].map(s => ({
        ...s,
        isSelected: !isStrategySelected && s._id === strategyToBeSelected._id
      }))
    );

    setUpdatingStrategy(false);

    if (error) {
      toast.error(error);
      return;
    }

    onCustomerUpdate && onCustomerUpdate(response);
    toast.success(`Strategy ${isStrategySelected ? 'unselected' : 'selected'} successfully!`);
  };

  const onCemetryClick = () => {
    setShowCemetry(true);
  };

  const onSortStrategiesClick = () => {
    setShowStrategySortModal(true);
  };

  const sortStrategies = async (sortedStrategies = []) => {
    setSortingStrategies(true);

    const sortedIds = sortedStrategies.map(s => s._id);
    const sortedStrategiesWithKilled = [
      ...sortedStrategies,
      ...activeCustomer['strategies'].filter(s => !sortedIds.includes(s._id))
    ];
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_UPDATE,
      requestBody: {
        strategies: sortedStrategiesWithKilled,
        _id: activeCustomer['_id']
      }
    });

    setSortingStrategies(false);

    if (error) {
      toast.error(error);
      return;
    }

    onCustomerUpdate && onCustomerUpdate(response);
    setShowStrategySortModal(false);
    toast.success(`Strategy rearranged successfully!`);
  };

  const onLeverageOptionSelect = (
    { isBankLoan, asset, availableLeverage, availableLeverageAmount, interest },
    startingMonth,
    startingYear
  ) => {
    const assetElement = document.getElementById('assetName');
    assetElement.value = isBankLoan ? '' : asset.name;

    if (!isBankLoan) {
      onAssetChange(null, {
        selectedValue: asset.name,
        presetFieldsToOverride: { loanValue: availableLeverage },
        resetValue: true,
        overRideStartYear: startingYear,
        overRideStartMonth: startingMonth
      });
    } else {
      const { month, year } = getEndYearOfLiability(
        {},
        false,
        incompleteYearOfStrategy,
        84,
        startingYear,
        startingMonth
      );

      prefillFormFields([
        { key: 'value', value: availableLeverageAmount },
        {
          key: 'monthlyPayment',
          value: getMonthlyPayment({ interest, value: availableLeverageAmount, timeToMaturity: 84, type: 'Annuity' })
        },
        { key: 'interest', value: interest, isPercentage: true },
        { key: 'timeToMaturity', value: 84 },
        { key: 'type', value: 'Annuity' },
        { key: 'startYear', value: startingYear.toString() },
        { key: 'startMonth', value: startingMonth.toString() },
        { key: 'endYear', value: year?.toString() },
        { key: 'endMonth', value: month?.toString() }
      ]);

      console.log(year, month);

      setLiabilityModalMeta(liabilityModalMeta => ({
        ...liabilityModalMeta,
        linkedAsset: null,
        loanType: 'Annuity',
        liabilityStartYear: startingYear.toString(),
        liabilityStartMonth: startingMonth.toString(),
        liabilityEndYear: year,
        liabilityEndMonth: month
      }));
    }
  };

  window['onLoanTypeChange'] = onLoanTypeChange;

  window['onLiabilityValueChange'] = onLiabilityValueChange;
  window['onLiabilityValuePercentageChange'] = onLiabilityValuePercentageChange;
  window['onLiabilityStartMonthChange'] = onLiabilityStartMonthChange;
  window['onLiabilityStartYearChange'] = onLiabilityStartYearChange;
  window['onLiabilityEndMonthChange'] = onLiabilityEndMonthChange;
  window['onLiabilityEndYearChange'] = onLiabilityEndYearChange;
  //inter related for loan
  window['onLiabilityInterestChange'] = onLiabilityInterestChange;
  window['onLoanMonthlyPaymentChange'] = onLoanMonthlyPaymentChange;
  window['onLoanTimeToMaturityChange'] = onLoanTimeToMaturityChange;
  window['onLiabilityEditModeChange'] = onLiabilityEditModeChange;
  window['onLiabilityValueCurrencyConvert'] = onLiabilityValueCurrencyConvert;

  window['onAssetFormSubmit'] = onAssetFormSubmit;
  window['onLiabilityFormSubmit'] = onLiabilityFormSubmit;
  window['onAssetChange'] = onAssetChange;
  window['onMarginFloorChange'] = onMarginFloorChange;
  window['onEndDateCalculatedAutomaticallyChange'] = onEndDateCalculatedAutomaticallyChange;
  window['onNewStrategyFormSubmit'] = onStrategySubmit;
  window['onUpdateStrategyFormSubmit'] = onStrategySubmit;

  return (
    <>
      {activeCustomer['strategies'].length > 0 ? (
        <>
          <div className={`overflow-hidden ${selectedStrategy?.killed ? 'bg-secondary p-2 rounded ' : ''}`}>
            <div className="my-2">
              <ExplainatoryVideoButton
                videoKey="how_to_use_app"
                showLabel
                showPlayer={isCustomerNew(activeCustomer['strategies'])}
              />
            </div>
            <div id="top-strategy-navigator">
              <div>
                {selectedStrategy?.killed ? (
                  <CemeteryButton
                    strategy={selectedStrategy}
                    onEditClick={
                      !fromCustomerPortal
                        ? strategy =>
                            onEditStrategyClick(activeCustomer['strategies'].find(s => s._id === strategy._id))
                        : undefined
                    }
                    onDeleteClick={
                      !fromCustomerPortal
                        ? strategy =>
                            onDeleteStrategyClick(activeCustomer['strategies'].find(s => s._id === strategy._id))
                        : undefined
                    }
                    onCloseClick={() => selectStrategy(unkilledStrategies[0])}
                    onRetreiveClick={() => onRetreiveStrategyClick(selectedStrategy)}
                  />
                ) : (
                  <div className="bg-light px-2 py-1 rounded border">
                    <div className="d-flex align-items-center mx-1 mb-0">
                      <div className="flex-grow-1">
                        <h6 className=" mb-0 text-muted smallFont fw-bold">
                          {translate('strategies')} ({unkilledStrategies.length}):
                        </h6>
                      </div>
                      {!readOnlyMode && (
                        <>
                          <UnderlineButton
                            iconSize={11}
                            fontSize="smallFont"
                            text={translate('update_strategy_time')}
                            Icon={Pencil}
                            onClick={onUpdateStrategyTimeClick}
                            variant="dark"
                          />

                          {(isSystemUser(user?.role) || user?.level >= 2) && (
                            <UnderlineButton
                              iconSize={11}
                              fontSize="smallFont"
                              text={translate('new_strategy')}
                              Icon={PlusCircle}
                              onClick={onAddNewStrategyClick}
                              variant="success"
                            />
                          )}
                          <div className="d-none d-lg-inline-block">
                            {unkilledStrategies.length > 1 && (
                              <>
                                <UnderlineButton
                                  fontSize="smallFont"
                                  iconSize={11}
                                  text={translate('rearrange_strategies')}
                                  Icon={ArrowLeftRight}
                                  onClick={onSortStrategiesClick}
                                />
                              </>
                            )}

                            {showArchivedToClient && (
                              <UnderlineButton
                                iconSize={11}
                                fontSize="smallFont"
                                text={translate('strategy_archive')}
                                Icon={EyeSlashFill}
                                onClick={onCemetryClick}
                                variant="primary-new"
                              />
                            )}
                          </div>
                          {(unkilledStrategies.length > 1 || showArchivedToClient) && (
                            <div className="d-inline-block d-lg-none">
                              <Dropdown>
                                <Dropdown.Toggle
                                  className="no-caret"
                                  variant="transparent"
                                  size="sm"
                                  aria-label="More actions"
                                >
                                  <ThreeDotsVertical size={10} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {unkilledStrategies.length > 1 && (
                                    <>
                                      <Dropdown.Item onClick={onSortStrategiesClick}>
                                        <ArrowLeftRight size={12} />
                                        <span className="align-middle mx-2">{translate('rearrange_strategies')}</span>
                                      </Dropdown.Item>
                                    </>
                                  )}
                                  {showArchivedToClient && (
                                    <Dropdown.Item onClick={onCemetryClick}>
                                      <EyeSlashFill size={12} />
                                      <span className="align-middle mx-2">{translate('strategy_archive')}</span>
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          )}
                          {unkilledStrategies.length > 1 && (
                            <StrategyRearrangeModal
                              strategies={unkilledStrategies}
                              show={showStrategySortModal}
                              onHide={() => setShowStrategySortModal(false)}
                              showProgress={sortingStartegies}
                              onSubmit={sortStrategies}
                            />
                          )}
                        </>
                      )}
                    </div>
                    <Tabs
                      scrollable
                      disabled={updatingStrategy}
                      activeTab={selectedStrategy?.name || ''}
                      selectedTab={activeCustomer['strategies'].find(s => s.isSelected)?.name}
                      tabs={unkilledStrategies.map(s => s.name)}
                      tabVariantColors={unkilledStrategies.map(s =>
                        s.isDefaultStrategy || s.isCreatedByClient ? 'success' : 'dark'
                      )}
                      keys={unkilledStrategies.map(s => s._id)}
                      onEditClick={
                        isSystemUser(user?.role) || (selectedStrategy?.isCreatedByClient && user?.level >= 2)
                          ? t => onEditStrategyClick(activeCustomer['strategies'].find(s => s.name === t))
                          : undefined
                      }
                      onDeleteClick={
                        isSystemUser(user?.role) || (selectedStrategy?.isCreatedByClient && user?.level >= 2)
                          ? t => onDeleteStrategyClick(activeCustomer['strategies'].find(s => s.name === t))
                          : undefined
                      }
                      onCloneClick={
                        !fromCustomerPortal || user?.level >= 1
                          ? t => onCloneStrategyClick(activeCustomer['strategies'].find(s => s.name === t))
                          : undefined
                      }
                      onSelectClick={
                        !fromCustomerPortal
                          ? t => onSelectStrategyClick(activeCustomer['strategies'].find(s => s.name === t))
                          : undefined
                      }
                      onKillClick={
                        showArchivedToClient &&
                        (t => onKillStrategyClick(activeCustomer['strategies'].find(s => s.name === t)))
                      }
                      onTabClick={t => selectStrategy(activeCustomer['strategies'].find(s => s.name === t))}
                      readOnlyMode={readOnlyMode}
                    />
                  </div>
                )}
              </div>
              {selectedStrategy && (
                <>
                  <StrategyScenario
                    showStressTestAndArmaggedonToClient={showStressTestAndArmaggedonToClient}
                    readOnlyMode={readOnlyMode}
                    stressTestApplied={stressTestApplied}
                    setStressTestApplied={setStressTestApplied}
                    activeScenario={activeScenario}
                    scenarioOptions={scenarioOptions}
                    onActiveScenarioChange={onActiveScenarioChange}
                    onArmagedonClick={onArmagedonClick}
                    armageddonDetails={armageddonDetails}
                    inflationApplied={inflationApplied}
                    onInflationApplyCheckedChange={() => setInflationApplied(!inflationApplied)}
                    inflationValue={inflationValue}
                    onInflationValueChange={setInflationValue}
                  />
                  <hr className="my-1 border-muted" />
                </>
              )}
            </div>
            {selectedStrategy ? (
              <>
                <Strategy
                  adminCompTable={adminCompTable}
                  customerCompTable={activeCustomer['comp']}
                  strategy={selectedStrategy}
                  calculatedStrategy={calculatedStrategy}
                  armageddon={activeScenario.isArmageddon && armageddonDetails}
                  stressTestApplied={stressTestApplied}
                  onShownToCustomerChange={onStrategyShownToCustomerChange}
                  onAddAssetClick={onAddAssetClick}
                  onAddLiabilityClick={onAddLiabilityClick}
                  onAssetLiabilityEditClick={onAssetLiabilityEditClick}
                  onAssetLiabilityDeleteClick={onAssetLiabilityDeleteClick}
                  onDuplicateAssetLiabilityClick={onDuplicateAssetLiabilityClick}
                  onAddEventClick={onAddEventClick}
                  onEditEventClick={onEditEventClick}
                  onOneOffEventDeleteClick={onOneOffEventDeleteClick}
                  onItemQuickRenameSubmit={onItemQuickRenameSubmit}
                  disabled={updatingStrategy}
                  quickRenaming={quickRenaming}
                  editable={
                    !stressTestApplied && !inflationApplied && activeScenario.changeInPercentage === 0 && !readOnlyMode
                  }
                  assetLiabilityEditable={
                    !fromCustomerPortal ||
                    selectedStrategy.isDefaultStrategy ||
                    selectedStrategy.isCreatedByClient ||
                    user?.level >= 3
                  }
                  cashflowEditable={
                    !fromCustomerPortal ||
                    selectedStrategy.isDefaultStrategy ||
                    selectedStrategy.isCreatedByClient ||
                    user?.level >= 2
                  }
                  reinvestmentsEditable={
                    !fromCustomerPortal ||
                    selectedStrategy.isDefaultStrategy ||
                    selectedStrategy.isCreatedByClient ||
                    user?.level >= 3
                  }
                  fromCustomerPortal={fromCustomerPortal}
                  onAddCashflowClick={onAddCashflowClick}
                  onEditCashflowClick={onEditCashflowClick}
                  onDeleteCashflowClick={onDeleteCashflowClick}
                  onAddReinvestmentOption={onAddReinvestmentOption}
                  onEditReinvestmentOption={onEditReinvestmentOption}
                  onDeleteReinvestmentOption={onDeleteReinvestmentOption}
                  onDisableReinvestmentOption={onDisableReinvestmentOption}
                  onDisableAllReinvestmentsOptions={onDisableAllReinvestmentsOptions}
                  onAssetResort={onAssetResort}
                  editingNotes={notesModalMeta?.notes}
                  onEditingNotesChange={notes => setNotesModalMeta({ ...notesModalMeta, notes })}
                  savingNotes={notesModalMeta?.showProgress}
                  onNotesSave={onNotesSubmit}
                  readOnlyMode={readOnlyMode}
                />
                {activeScenario.changeInPercentage === 0 && (
                  <>
                    <AssetModal
                      show={assetModalMeta !== null}
                      onHide={() => setAssetModalMeta(null)}
                      strategy={selectedStrategy}
                      strategyYears={strategyYears}
                      showProgress={assetActionInProgress}
                      toBeEditedAsset={assetModalMeta?.toBeEditedAsset}
                      compTable={activeCustomer?.comp}
                      incompleteYearsOfStrategy={incompleteYearOfStrategy}
                      activeCustomer={activeCustomer}
                      adminCompTable={adminCompTable}
                      onAssetSubmit={onAssetFormSubmit}
                    />

                    <LiabilityModal
                      show={liabilityModalMeta !== null}
                      onHide={() => setLiabilityModalMeta(null)}
                      strategyYears={strategyYears}
                      compTable={activeCustomer.comp}
                      strategy={selectedStrategy}
                      calculatedStrategy={calculatedStrategy}
                      toBeEditedLiability={liabilityModalMeta?.toBeEditedLiability}
                      showProgress={liabilityModalMeta?.showProgress}
                      linkedAsset={liabilityModalMeta?.linkedAsset}
                      assetOptions={liabilityModalMeta?.assetOptions || []}
                      cashAssetOptions={liabilityModalMeta?.cashAssetOptions}
                      fromAssetCreateForm={liabilityModalMeta?.fromAssetCreateForm}
                      loanType={liabilityModalMeta?.loanType}
                      loanEditMode={liabilityModalMeta?.loanEditMode}
                      loanAutoCalculated={liabilityModalMeta?.loanAutoCalculated}
                      onLiabilitySubmit={onSingleLiabilitySubmit}
                      onGroupLiabilitySubmit={onGroupLiabilitySubmit}
                      showGroupActionButton={otherStrategiesInThisGroup.length > 0}
                      liabilityStartYear={liabilityModalMeta?.liabilityStartYear}
                      liabilityEndYear={liabilityModalMeta?.liabilityEndYear}
                      incompleteYearsOfStrategy={incompleteYearOfStrategy}
                      onLeverageOptionSelect={onLeverageOptionSelect}
                    />
                    <AddEditOneOffEventModal
                      show={isOneOffEventDialogOpen}
                      strategyYears={strategyYears}
                      hideDialog={() => setOneOffEventDialogOpen(false)}
                      onHide={() => setOneOffEventDialogOpen(false)}
                      initialValues={oneOffEventFormData}
                      handleOneOffEventSubmit={handleOneOffEventSubmit}
                      originalStrategy={selectedStrategy}
                      incompleteYearsOfStrategy={incompleteYearOfStrategy}
                      isPrimaryBankAccount={oneOffEventFormData?.attribute?.name === primaryBankAccountName}
                    />
                    <CashFlowModal
                      strategyYears={strategyYears}
                      incompleteYearsOfStrategy={incompleteYearOfStrategy}
                      show={cashflowDialogOpen}
                      onHide={() => setCashflowDialogOpen(false)}
                      hideDialog={() => setCashflowDialogOpen(false)}
                      initialValues={cashflowFormData}
                      isAutomated={cashflowFormData?.isAutomated}
                      cashflowChanges={editingCashflowChanges}
                      onCashflowChangesUpdate={setEditingCashflowChanges}
                      handleCashflowSubmit={handleCashflowSubmit}
                      isSubmitting={cashflowSubmitting}
                      cashAssetOptions={selectedStrategy['assets'].filter(a => a.category === 'Cash')}
                      apartmentOptions={selectedStrategy['assets'].filter(a => a.category === 'Real Estate')}
                    />

                    <ReinvestmentModal
                      onHide={() => setReinvestmentOpen(false)}
                      hideDialog={() => setReinvestmentOpen(false)}
                      initialValues={investmentFormData}
                      handleReinvestmentSubmit={handleReinvestmentSubmit}
                      show={isReinvestmentOpen}
                      isSubmitting={reinvestmentSubmitting}
                      assets={selectedStrategy.assets}
                      fixedIncomeAssets={selectedStrategy.fixedIncomeAssets}
                      liabilities={selectedStrategy.liabilities}
                      strategyYears={strategyYears}
                      incompleteYearsOfStrategy={incompleteYearOfStrategy}
                    />
                    <StrategyGroupSelectorModal
                      show={strategyGroupSelectionMeta !== null}
                      onHide={() => setStrategyGroupSelectionMeta(null)}
                      strategies={strategyGroupSelectionMeta?.strategiesOptions}
                      problemStrategies={strategyGroupSelectionMeta?.problemStrategies}
                      warningStrategies={strategyGroupSelectionMeta?.warningStrategies}
                      onAllStrategyCheckedChange={onAllStrategyCheckedChange}
                      onStrategyCheckedChange={onStrategyGroupSelectionCheckedChange}
                      selectedStrategies={strategyGroupSelectionMeta?.selectedStrategies}
                      fieldsToShow={strategyGroupSelectionMeta?.fieldsToShow}
                      originalObject={strategyGroupSelectionMeta?.originalObject}
                      selectedFields={strategyGroupSelectionMeta?.selectedFields}
                      onSelectedFieldCheckChange={onStrategyGroupSelectedFieldsCheckedChange}
                      changedFields={strategyGroupSelectionMeta?.changedFields}
                      showProgress={strategyGroupSelectionMeta?.showProgress}
                      onSubmit={(selectedStrategies, changedFields) =>
                        strategyGroupSelectionMeta?.onSubmit &&
                        strategyGroupSelectionMeta?.onSubmit(selectedStrategies, changedFields)
                      }
                      progressText={strategyGroupSelectionMeta?.progressText}
                    />
                    <SyncFixedTransferModal
                      show={Boolean(syncFixedTransferModalMeta)}
                      investments={syncFixedTransferModalMeta?.relatedFixedTransfers}
                      assets={selectedStrategy.assets}
                      fixedIncomeAssets={selectedStrategy.fixedIncomeAssets}
                      liabilities={selectedStrategy.liabilities}
                      onInvestmentsChange={relatedFixedTransfers =>
                        setSyncFixedTransferModalMeta({ ...syncFixedTransferModalMeta, relatedFixedTransfers })
                      }
                      onHide={() => setSyncFixedTransferModalMeta(null)}
                      showProgress={syncFixedTransferModalMeta?.showProgress}
                      onSubmit={() => {
                        setSyncFixedTransferModalMeta(null);
                        addUpdateAssetInStrategies(syncFixedTransferModalMeta);
                      }}
                    />
                  </>
                )}
                {showStressTestAndArmaggedonToClient && activeScenario.isArmageddon && (
                  <ArmageddonModal
                    strategyYears={strategyYears}
                    show={armageddonDetailsModalMeta !== null}
                    onHide={() => setArmageddonDetailsModalMeta(null)}
                    assetDepreciations={armageddonDetailsModalMeta?.assetDepreciations}
                    onAssetDepreciationsChange={onArmageddonAssetDepreciationChange}
                    startYear={armageddonDetailsModalMeta?.startYear}
                    onStartYearChange={year =>
                      setArmageddonDetailsModalMeta({ ...armageddonDetailsModalMeta, startYear: year })
                    }
                    startMonth={armageddonDetailsModalMeta?.startMonth}
                    onStartMonthChange={month =>
                      setArmageddonDetailsModalMeta({ ...armageddonDetailsModalMeta, startMonth: month })
                    }
                    previousArmageddon={armageddonDetails}
                    onWeakestTimeClick={onArmageddonWeakestTimeClick}
                    onResetArmageddonClick={onResetArmageddonClick}
                    onApplyArmageddon={onApplyArmageddon}
                    translate={translate}
                    showProgress={armageddonDetailsModalMeta?.showProgress}
                  />
                )}
              </>
            ) : (
              <div className="p-5 text-center">
                <NotFound text="No strategy selected" />
              </div>
            )}
          </div>
          {showArchivedToClient && (
            <CemetrySidebar
              show={showCemetry}
              onHide={() => setShowCemetry(false)}
              onEditClick={
                !fromCustomerPortal
                  ? strategy => onEditStrategyClick(activeCustomer['strategies'].find(s => s._id === strategy._id))
                  : undefined
              }
              onDeleteClick={
                !fromCustomerPortal
                  ? strategy => onDeleteStrategyClick(activeCustomer['strategies'].find(s => s._id === strategy._id))
                  : undefined
              }
              onRetreiveClick={onRetreiveStrategyClick}
              strategies={killedStrategies}
              onStrategyClick={s => {
                selectStrategy(s);
                setShowCemetry(false);
              }}
            />
          )}
          {!selectedStrategy?.killed && (
            <FloatingStrategyNavigator
              showStressTestAndArmaggedonToClient={showStressTestAndArmaggedonToClient}
              selectedStrategy={selectedStrategy}
              onNextStrategyClick={onNextStrategyClick}
              onPrevStrategyClick={onPrevStrategyClick}
              activeScenario={activeScenario}
              onActiveScenarioChange={onActiveScenarioChange}
              armageddonDetails={armageddonDetails}
              stressTestApplied={stressTestApplied}
              onStressTestAppliedChange={setStressTestApplied}
              scenarioOptions={scenarioOptions}
              onArmagedonClick={onArmagedonClick}
            />
          )}
          <AlertModal
            onDismissClick={() => setAlertModalMeta(null)}
            onHide={() => setAlertModalMeta(null)}
            show={alertModalMeta !== null}
            alertText={alertModalMeta?.alertText}
            progressText={alertModalMeta?.progressText}
            showProgress={alertModalMeta?.showProgress}
            continueButtonText={alertModalMeta?.continueButtonText}
            onContinueClick={alertModalMeta?.onContinueClick}
            continue2ButtonText={alertModalMeta?.continue2ButtonText}
            onContinue2Click={alertModalMeta?.onContinue2Click}
            continue3ButtonText={alertModalMeta?.continue3ButtonText}
            onContinue3Click={alertModalMeta?.onContinue3Click}
            continue4ButtonText={alertModalMeta?.continue4ButtonText}
            onContinue4Click={alertModalMeta?.onContinue4Click}
            size={alertModalMeta?.size}
          />
        </>
      ) : (
        <div className="p-5 text-center">
          <NotFound text="No strategies created" />
          {!fromCustomerPortal && (
            <Button size="sm" variant="success" className="text-white" onClick={onAddNewStrategyClick}>
              {translate('add_new_strategy')}
            </Button>
          )}
        </div>
      )}
      <AddUpdateStrategyModal
        translate={translate}
        show={strategyModalMeta !== null}
        onHide={() => setStrategyModalMeta(null)}
        existingStrategy={strategyModalMeta && strategyModalMeta.existingStrategy}
        showProgress={strategyModalMeta && strategyModalMeta.showProgress}
        toBeClonedStrategy={strategyModalMeta && strategyModalMeta.toBeClonedStrategy}
        fromCustomerPortal={fromCustomerPortal}
      />
    </>
  );
};

export default CustomerStrategies;

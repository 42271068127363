import { useEffect, useMemo, useState } from 'react';

const monthArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

const useStrategyMonthYearSelector = ({
  strategyYears = [],
  incompleteYearsOfStrategy = [],
  initialMonth,
  initialYear
}) => {
  const [selectedMonth, setSelectedMonth] = useState(initialMonth);
  const [selectedYear, setSelectedYear] = useState(initialYear);

  useEffect(() => {
    onMonthChange(initialMonth);
    onYearChange(initialYear);
  }, [initialYear, initialMonth, strategyYears, incompleteYearsOfStrategy]);

  const onYearChange = year => {
    if (!year) return setSelectedYear(year);

    setSelectedYear(Number(year));
  };

  const onMonthChange = month => {
    if (!month && month !== 0) return setSelectedMonth(month);

    setSelectedMonth(Number(month));
  };

  const monthOptions = useMemo(() => {
    if (!strategyYears.includes(selectedYear)) return [];

    return incompleteYearsOfStrategy?.[selectedYear]
      ? monthArray.slice(incompleteYearsOfStrategy?.[selectedYear].start, incompleteYearsOfStrategy?.[selectedYear].end)
      : monthArray;
  }, [selectedYear]);

  const yearOptions = useMemo(() => [...strategyYears], [strategyYears]);

  useEffect(() => {
    if (!selectedYear) return;

    if (selectedMonth !== 0 && !selectedMonth) return;

    const currentMonthNum = Number(selectedMonth);
    const incompleteYear = incompleteYearsOfStrategy?.[selectedYear];

    if (incompleteYear && (currentMonthNum < incompleteYear.start || currentMonthNum > incompleteYear.end - 1)) {
      setSelectedMonth(incompleteYear.start);
    }
  }, [selectedYear]);

  return {
    selectedMonth,
    selectedYear,
    onYearChange,
    onMonthChange,
    monthOptions,
    yearOptions
  };
};

export default useStrategyMonthYearSelector;

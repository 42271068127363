import React from 'react';
import { ArrowLeftRight } from 'react-bootstrap-icons';
import CurrencyInput from '../../../../common/CurrencyInput';
import PercentageInput from '../../../../common/PercentageInput';
import { formatCurrency } from './helper';

const BuyingTax = ({
  buyTaxBreakdown = [],
  totalTax,
  totalTaxPercentage,
  onTotalTaxChange,
  onTotalTaxPercentageChange,
  disabled,
  isApartmentAbroad,
  isTaxCalculatedAutomatically,
  onAutoCalculateModeChange
}) => {
  return (
    <>
      <div className="mt-1 d-flex align-items-center">
        <div className="flex-grow-1">
          <h6 className="mid mb-0">Buying Tax:</h6>
        </div>
        {!isApartmentAbroad && (
          <div>
            <h6 className="a tiny text-primary hover mb-0" onClick={onAutoCalculateModeChange}>
              <ArrowLeftRight className="mx-1" />
              Switch to <b>{isTaxCalculatedAutomatically ? 'Manual' : 'Auto'}</b> mode
            </h6>
          </div>
        )}
      </div>
      <hr className="my-2" />
      <div className="px-2">
        <div>
          {buyTaxBreakdown.map(t => (
            <div>
              <h6 className="mb-0  smallFont text-dark text-end">
                Upto {formatCurrency(t.to)}(<span>{t.percentage}%</span>)
              </h6>

              <h6 className="text-end text-muted smallFont mt-2">
                <b>={formatCurrency(t.actualTax)}</b>
              </h6>
            </div>
          ))}
        </div>

        <div className="smallFont mb-3 d-flex">
          <div className={isApartmentAbroad ? 'w-50' : 'w-100'}>
            <b>Total Tax: </b>
            <CurrencyInput
              size="sm"
              disabled={disabled || (!isApartmentAbroad && isTaxCalculatedAutomatically)}
              onChange={value => onTotalTaxChange(value)}
              value={totalTax}
              maxDecimal={0}
            />
          </div>
          {isApartmentAbroad && (
            <div className="mx-2 w-50">
              <b>Tax (%): </b>
              <PercentageInput
                size="sm"
                disabled={disabled}
                onChange={value => onTotalTaxPercentageChange(value)}
                value={totalTaxPercentage}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BuyingTax;

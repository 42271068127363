import { snakeCase } from 'lodash';
import moment from 'moment';
import React, { useContext, useMemo } from 'react';
import { Form, FormControl, FormSelect } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { LocalizeContext } from 'react-locale-language';
import { DEFAULT_DATE_TIME_FORMAT_NON_MOMENT, getDateWithTimezone } from '../../../helpers/constants';
import CustomMultiSelect from '../CustomMultiSelect';
import EditableTagsInput from '../EditableTagsInput';
import GrouppedCheckBox from '../GrouppedCheckBox';
import TimeIntervalInWeek from '../TimeIntervalInWeek';
import Title from './Title';

const getFormControlType = ({ type, isMultiple }) => {
  if (isMultiple) {
    return 'tags-input';
  }

  const typeToFormControlTypeMap = {
    url: 'text',
    currency: 'number',
    datetime: 'datetime-local'
  };

  return typeToFormControlTypeMap[type] || type || 'text';
};

const getFormControlValue = (field, data = {}, formControlType) => {
  const key = field.key;
  const value = data?.[key];
  switch (formControlType) {
    case 'date':
      return value ? moment(value).format('YYYY-MM-DD') : null;
    case 'datetime-local':
      return value ? new Date(value) : null;
    case 'dropdown':
      return value ? value?._id || value : null; // TODO need to handle other cases
    case 'multi-select':
      return value ? value : [];
    case 'number':
    case 'price':
    case 'email':
    case 'text':
    default:
      return value || (field.isMultiple || field?.type === 'groupped-checkbox' ? [] : '');
  }
};

const getNewValue = (field, value, formControlType) => {
  let valueUpdated;
  switch (formControlType) {
    case 'date':
      valueUpdated = value ? getDateWithTimezone(value) : null;
      break;
    default:
      valueUpdated = value;
      break;
  }

  return valueUpdated;
};

const InputCell = ({ data, onChange, field, className, disabled, style }) => {
  const { isRTL, translate } = useContext(LocalizeContext);
  const formControlType = useMemo(() => getFormControlType(field), [field]);
  const { options = [] } = field;
  const handleTagChange = tags => {
    onChange(field, getNewValue(field, tags, formControlType));
  };

  return (
    <div className="">
      {formControlType === 'groupped-checkbox' ? (
        <GrouppedCheckBox
          options={field?.options}
          values={getFormControlValue(field, data, formControlType)}
          onChange={values => onChange(field, values)}
        />
      ) : formControlType === 'checkbox' ? (
        <Form.Check
          label={<h6 className="mb-0 smallFont">{translate(snakeCase(field?.label)) || field?.label}</h6>}
          style={{
            fontSize: '14px'
          }}
          checked={getFormControlValue(field, data, formControlType)}
          onChange={e => onChange(field, getNewValue(field, e.target.checked, formControlType))}
          type={formControlType}
          name={`checkbox-${field?.key}`}
          id={`checkbox-${field?.key}`}
        />
      ) : formControlType === 'dropdown' ? (
        <FormSelect
          size="sm"
          style={{
            fontSize: '12px'
          }}
          value={getFormControlValue(field, data, formControlType)}
          disabled={disabled}
          onChange={e => {
            let value = e.target.value;
            let newVal = value === field?.clearableOptionTitle || value === 'Select one...' ? null : value;
            onChange(field, newVal);
          }}
        >
          {field?.clearableOption && (
            <option className="smallFont">{field?.clearableOptionTitle || 'Select one...'}</option>
          )}
          {options.map(({ value, label }) => (
            <option className="smallFont" key={value} value={value}>
              {label}
            </option>
          ))}
        </FormSelect>
      ) : formControlType === 'grouped-dropdown' ? (
        <CustomMultiSelect
          disabled={disabled}
          items={field.options}
          selectedItems={getFormControlValue(field, data, formControlType)}
          onChange={option => onChange(field, option)}
          isMulti={false}
          isGroupped
          placeholder={field?.placeholder || 'Select'}
          fieldColors={field?.options
            .flatMap(o => o?.options)
            ?.find(option => option?.value === getFormControlValue(field, data, formControlType))}
          closeMenuOnSelect
        />
      ) : formControlType === 'multi-select' ? (
        <CustomMultiSelect
          disabled={disabled}
          items={field.options}
          selectedItems={getFormControlValue(field, data, formControlType)}
          onChange={option => onChange(field, option)}
          isMulti
          maxItemCustomMessage={field?.maxItemCustomMessage || undefined}
          placeholder={field?.placeholder || 'Select'}
          maxToShow={20}
        />
      ) : formControlType === 'tags-input' ? (
        <>
          <EditableTagsInput
            tags={getFormControlValue(field, data, formControlType)}
            onTagsChange={handleTagChange}
            disabled={disabled}
            fullWidthTag
            autoLowerCase={field?.key === 'emails'}
          />
        </>
      ) : formControlType === 'date' ? (
        <FormControl
          className={`mb-0 text-dark px-2 py-0 ${className}`}
          as={field.as || 'input'}
          style={{
            fontSize: '12px'
          }}
          disabled={disabled}
          type={formControlType}
          value={getFormControlValue(field, data, formControlType)}
          onChange={e => onChange(field, getNewValue(field, e.target.value, formControlType))}
          size="sm"
        />
      ) : formControlType === 'followUpDate' ? (
        <div className="">
          <Title field={field} editMode style={{ fontSize: '14px' }} />
          <div className="d-flex my-2">
            <TimeIntervalInWeek
              disabled={disabled}
              className=""
              style={{ marginTop: '-8px' }}
              initialDate={
                // getFormControlValue(field, data, "date") ??
                new Date()
              }
              onChange={newDate => onChange(field, newDate)}
            />
          </div>
          <DatePicker
            wrapperClassName="w-100"
            className={`${isRTL ? 'px-4' : ''}   mb-0 text-dark px-2 py-1 form-control form-control-sm ${className}`}
            selected={getFormControlValue(field, data, 'datetime-local')}
            dateFormat={DEFAULT_DATE_TIME_FORMAT_NON_MOMENT}
            onChange={e => {
              onChange(field, e ? getNewValue(field, e.toISOString(), 'datetime-local') : null);
            }}
            openToDate={new Date()} // Opens the datepicker to the current month and year
            showYearDropdown
            showMonthDropdown
            showTimeInput
            dropdownMode="scroll"
            timeFormat="HH:mm" // 24-hour format
            popperClassName="datepickerPopperClassName"
            style={style}
            disabled={disabled}
            clearButtonClassName="text-dark"
            portalId="root"
            isClearable
          />
        </div>
      ) : (
        <FormControl
          className={`mb-0 text-dark px-2 py-0 ${className}`}
          as={field.as || 'input'}
          style={{
            fontSize: '12px'
          }}
          disabled={disabled}
          type={formControlType}
          value={getFormControlValue(field, data, formControlType)}
          onChange={e => onChange(field, getNewValue(field, e.target.value, formControlType))}
          size="sm"
        />
      )}
    </div>
  );
};

export default InputCell;

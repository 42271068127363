import { useFormik } from 'formik';
import { snakeCase, uniqueId } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, FormCheck, FormControl, FormGroup, FormLabel, FormSelect, Row } from 'react-bootstrap';
import { assetRows } from '../../components/admin/manage-users/customer/strategies/form';
import {
  calculateSellingTax,
  calculateTaxFromBrackets,
  calculatorApartmentModalFields,
  compoundInterestMonthly,
  findDiffInNumberOfMonths,
  getMonthlyPayment,
  isPrimaryBankAccount
} from '../../components/admin/manage-users/customer/strategies/helper';
import RealEstateIRR from '../../components/calculators/Irr';
import PaperApartmentIRR from '../../components/calculators/PaperApartment';
import PinuiBinuiIRR from '../../components/calculators/PinuiBinui';
import BlockSelectInput from '../../components/common/BlockSelectInput';
import CollapsibleSection from '../../components/common/CollapsibleSection';
import CurrencyInput from '../../components/common/CurrencyInput';
import HorizontalProgress from '../../components/common/HorizontalProgress';
import MonthYearPicker from '../../components/common/MonthYearPicker';
import PercentageInput from '../../components/common/PercentageInput';
import SlidingSideBar from '../../components/common/SlidingSideBar/SlidingSideBar';
import {
  APARTMENT_TYPES,
  ASSET_CATEGORIES,
  fieldsWithFormulas,
  IRR_CALCULATOR_KEY,
  LOAN_TYPES,
  PAPER_CALCULATOR_KEY,
  PINUI_BINUI_CALCULATOR_KEY,
  presetTableHeaders,
  transferFrequencyOptions
} from '../../helpers/constants';
import { evalFormula, isAdmin } from '../../helpers/global';
import useLocalization from '../../hooks/useLocalization';
import AssetTaxes from './AssetTaxes';
import PaperApartmentPayments from './PaperApartmentPaymentsTable';
import PartialSales from './PartialSalesTable';
import RSUGrants from './RSUGrantsTable';
import Deposits from './DepositsTable';

const apartmentTypeToKey = {
  'Classic Apartment': IRR_CALCULATOR_KEY,
  'Pinui Binui Apartment': PINUI_BINUI_CALCULATOR_KEY,
  'Paper Apartment': PAPER_CALCULATOR_KEY
};

const applyPreset = ({ preset, compTable, fieldsToOverride = {}, existingValues = {} }) => {
  const newValues = {};

  const overridenPreset = { ...preset, ...fieldsToOverride };
  presetTableHeaders
    .filter(p => p.mapsToElem && p.for === 'asset')
    .forEach(({ key, mapsToElem, isPercentageField, numberField }) => {
      try {
        let value;
        if (!fieldsWithFormulas.includes(key)) {
          value = overridenPreset[key];
        } else {
          value = evalFormula(overridenPreset[key], compTable?.variables || []) || 0;

          if (isPercentageField) {
            if (key === 'salesCost') {
              const {
                returnAppreciation = 0,
                buyingYear,
                buyingMonth,
                sellingYear,
                sellingMonth,
                value = 0
              } = existingValues;

              const valueAtTheTimeOfSelling = sellingYear
                ? compoundInterestMonthly(
                    value,
                    findDiffInNumberOfMonths(buyingMonth, buyingYear, sellingMonth, sellingYear),
                    returnAppreciation
                  )
                : 0;

              newValues.salesCost = valueAtTheTimeOfSelling * value * 0.01;
            } else {
              if (numberField) {
                //update percentage
                /* updateNumberPercentage(
                  value,
                  numberField,
                  mapsToElem,
                  'percentage',
                  numberField === 'returnCashflow' ? 12 : 1
                ); */
              }
            }
          }
        }

        newValues[mapsToElem] = value;
      } catch (e) {
        console.log(key, mapsToElem, e);
      }
    });

  if (preset.apartmentType === APARTMENT_TYPES.PINUI_BINUI) {
    newValues['rentPreConstructionPercentage'] = newValues['returnCashflowPercentage'];
  }

  return newValues;
};

const IrrCalculatorSidebar = ({
  customer,
  show,
  onHide,
  onSelectAppartment,
  translate,
  adminCompTable,
  selectMode
}) => {
  return (
    <SlidingSideBar
      fullScreen
      visible={show}
      onClose={onHide}
      showCloseButton
      title={`${translate('irr_calculator')} (${customer?.name})`}
    >
      {show && (
        <RealEstateIRR
          adminCompTable={adminCompTable}
          customer={customer}
          onSelectAppartment={onSelectAppartment}
          selectMode={selectMode}
        />
      )}
    </SlidingSideBar>
  );
};

const PinuiBinuiIrrCalculatorSidebar = ({
  customer,
  show,
  onHide,
  onSelectAppartment,
  translate,
  adminCompTable,
  selectMode
}) => (
  <SlidingSideBar
    fullScreen
    visible={show}
    onClose={onHide}
    showCloseButton
    title={`${translate('pinui_binui')} (${customer?.name})`}
  >
    {show && (
      <PinuiBinuiIRR
        adminCompTable={adminCompTable}
        customer={customer}
        onSelectAppartment={onSelectAppartment}
        selectMode={selectMode}
      />
    )}
  </SlidingSideBar>
);

const PaperApartmentCalculatorSidebar = ({
  customer,
  show,
  onHide,
  onSelectAppartment,
  translate,
  adminCompTable,
  selectMode
}) => (
  <SlidingSideBar
    fullScreen
    visible={show}
    onClose={onHide}
    showCloseButton
    title={`${translate('paper_apartment')} (${customer?.name})`}
  >
    {show && (
      <PaperApartmentIRR
        adminCompTable={adminCompTable}
        customer={customer}
        onSelectAppartment={onSelectAppartment}
        selectMode={selectMode}
      />
    )}
  </SlidingSideBar>
);

// AssetAddEditForm Component
const AssetAddEditForm = ({
  initialFormValues,
  onSubmit,
  strategy,
  strategyYears,
  incompleteYearsOfStrategy,
  assetOptions,
  compTable,
  adminCompTable,
  user,
  activeCustomer,
  showProgress
}) => {
  const { translate, isRTL } = useLocalization();
  const [collapsedSections, setCollapsedSections] = useState(['notes', 'deposits', 'partialSales', 'advanced', 'tax']);
  const [calculatorToShow, setCalculatorToShow] = useState(null);
  const [loanFieldsToFill, setLoanFieldsToFill] = useState();

  const isUpdatingAsset = useMemo(() => Boolean(initialFormValues?._id), [initialFormValues]);

  const validateForm = values => {
    const errors = {};

    assetSections.forEach(row => {
      row.columns.forEach(column => {
        const field = column.field;

        if (field.type === 'monthYearPicker' && field.required) {
          const month = values[field.monthKey];
          const year = values[field.yearKey];

          if (month === null || month === undefined || !year) {
            errors[field.key] = translate('field_required');
          }

          return;
        }

        const value = values[field.key];

        if (field.required && !value) {
          errors[field.key] = translate('field_required');
        }
      });
    });

    return errors;
  };

  // Formik Hook
  const { errors, values, setFieldValue, handleSubmit, handleChange, submitForm, setValues } = useFormik({
    initialValues: initialFormValues,
    validateOnChange: false,
    validateOnBlur: false,
    validate: validateForm,
    onSubmit: values => {
      onSubmit(values);
    }
  });

  const onGroupAssetSubmit = () => {
    setFieldValue('linkLiability', false);
    setFieldValue('updateGroup', true);
    setFieldValue('loanFieldsToFill', loanFieldsToFill);
    submitForm();
  };

  const onAddAssetWithLiabilityClick = () => {
    setFieldValue('linkLiability', true);
    setFieldValue('updateGroup', false);
    setFieldValue('loanFieldsToFill', loanFieldsToFill);
    submitForm();
  };

  const onAssetSubmit = () => {
    setFieldValue('linkLiability', false);
    setFieldValue('updateGroup', false);
    setFieldValue('loanFieldsToFill', loanFieldsToFill);
    submitForm();
  };

  const updateFormValues = newValues => {
    for (const key in newValues) {
      const newValue = newValues[key];
      setFieldValue(key, newValue);
    }
  };

  useEffect(() => {
    setValues(initialFormValues);

    const { value } = initialFormValues;

    updatePercentageValue(value, (initialFormValues.returnCashflow || 0) * 12, 'returnCashflowPercentage');
    updatePercentageValue(value, (initialFormValues.rentPreConstruction || 0) * 12, 'rentPreConstructionPercentage');
    updatePercentageValue(value, initialFormValues.purchaseCost, 'purchaseCostPercentage');
    updatePercentageValue(value, initialFormValues.renovationCost, 'renovationCostPercentage');
    updatePercentageValue(value, initialFormValues.realtorCost, 'realtorCostPercentage');
    updatePercentageValue(value, initialFormValues.makeReadyCost, 'makeReadyPercentage');
    updateSalesCostPercentage(initialFormValues);
  }, [initialFormValues]);

  const onSelectAppartment = appartment => {
    const { assetFieldsToFill, loanFieldsToFill } = calculatorApartmentModalFields({
      strategy,
      appartment,
      apartmentType: values.apartmentType,
      startMonth: values.buyingMonth,
      startYear: values.buyingYear
    });

    const newValues = {};
    assetFieldsToFill.forEach(({ key, value }) => {
      newValues[key] = value;
    });

    newValues.tax = values.tax || {};
    newValues.tax = {
      ...newValues.tax,
      isBuyAutomaticallyCalculated: false,
      isSellAutomaticallyCalculated: false,
      buy: assetFieldsToFill.find(a => a.key === 'buyingTax')?.value || 0,
      sell: assetFieldsToFill.find(a => a.key === 'sellingTax')?.value || 0
    };

    updateFormValues(newValues);
    setLoanFieldsToFill(loanFieldsToFill);
    setCalculatorToShow(null);
  };

  const onImportClick = apartmentKey => {
    setCalculatorToShow(apartmentKey);
  };

  const onAssetCategoryChange = category => {
    setFieldValue('preset', '');

    //set apartment type
    if (category === ASSET_CATEGORIES.REAL_ESTATE) setFieldValue('apartmentType', APARTMENT_TYPES.CLASSIC);

    //set fixed income type
    if (category === ASSET_CATEGORIES.FIXED_INCOME) setFieldValue('type', LOAN_TYPES.ANNUITY);
  };

  const onPresetChange = presetName => {
    const preset = compTable.presets.find(p => p.name === presetName);
    if (!preset) return;

    //update asset name from preset
    if (presetName && !values.name?.trim()) {
      setFieldValue('name', presetName + ' ');
    }

    const category = values.category;
    //set apartment type from preset
    setFieldValue('apartmentType', category === 'Real Estate' ? preset?.apartmentType || 'Classic Apartment' : null);

    //apply preset values
    const fieldsToOverride =
      values.editMode !== 'Edit Mode' ? { purchaseCost: 0, realtorCost: 0, renovationCost: 0 } : {};
    const newValues = applyPreset({ preset, compTable, fieldsToOverride });
    updateFormValues(newValues);
  };

  const updatePercentageValue = (totalValue = 0, value = 0, percentageField) => {
    if (totalValue === 0 || value === 0) return setFieldValue(percentageField, 0);

    const percentage = (value * 100) / totalValue;
    setFieldValue(percentageField, percentage);
  };

  const updateNumberValue = (totalValue = 0, percentageValue = 0, numberField) => {
    if (totalValue === 0 || percentageValue === 0) return setFieldValue(numberField, 0);

    const number = percentageValue * totalValue * 0.01;
    setFieldValue(numberField, number);
  };

  const onAssetValueChange = value => {
    updateNumberValue(value, (values.returnCashflowPercentage || 0) / 12, 'returnCashflow');
    updateNumberValue(value, (values.rentPreConstructionPercentage || 0) / 12, 'rentPreConstruction');

    updateNumberValue(value, values.purchaseCostPercentage, 'purchaseCost');
    updateNumberValue(value, values.renovationCostPercentage, 'renovationCost');
    updateNumberValue(value, values.realtorCostPercentage, 'realtorCost');
    updateNumberValue(value, values.makeReadyCostPercentage, 'makeReady');

    updateSalesCost({ value });

    if (
      values.category === ASSET_CATEGORIES.REAL_ESTATE &&
      [APARTMENT_TYPES.PAPER, APARTMENT_TYPES.PINUI_BINUI].includes(values.apartmentType)
    ) {
      setFieldValue('valueOfApartmentToday', value);
    }

    if (values.category === ASSET_CATEGORIES.FIXED_INCOME) {
      //update monthly payment for fixed income
      setFieldValue(
        'monthlyPayment',
        getMonthlyPayment({
          ...values,
          value
        })
      );
    }
  };

  const onAssetCashflowChange = value => {
    updatePercentageValue(values.value, (value || 0) * 12, 'returnCashflowPercentage');
  };

  const onAssetCashflowPercentageChange = value => {
    updateNumberValue(values.value, value / 12, 'returnCashflow');
  };

  const onAssetRentPreConstructionChange = value => {
    updatePercentageValue(values.value, (value || 0) * 12, 'rentPreConstructionPercentage');
    setFieldValue('returnCashflow', value);
    onAssetCashflowChange(value);
  };

  const onAssetRentPreConstructionPercentageChange = value => {
    updateNumberValue(values.value, value / 12, 'rentPreConstruction');
    setFieldValue('returnCashflowPercentage', value);
    onAssetCashflowPercentageChange(value);
  };

  const onAssetPurchaseCostChange = value => {
    updatePercentageValue(values.value, value, 'purchaseCostPercentage');
  };

  const onAssetPurchasePercentageChange = value => {
    updateNumberValue(values.value, value, 'purchaseCost');
  };

  const onAssetRenovationCostChange = value => {
    updatePercentageValue(values.value, value, 'renovationCostPercentage');
  };

  const onAssetRenovationPercentageChange = value => {
    updateNumberValue(values.value, value, 'renovationCost');
  };

  const onAssetRealtorCostChange = value => {
    updatePercentageValue(values.value, value, 'realtorCostPercentage');
  };

  const onAssetRealtorPercentageChange = value => {
    updateNumberValue(values.value, value, 'realtorCost');
  };

  const onAssetMakeReadyCostsChange = value => {
    updatePercentageValue(values.value, value, 'makeReadyPercentage');
  };

  const onAssetMakeReadyPercentageChange = value => {
    updateNumberValue(values.value, value, 'makeReadyCost');
  };

  const updateSalesCost = (updatedValues = {}) => {
    const valuesForCalculation = { ...values, ...updatedValues };
    const {
      buyingYear,
      buyingMonth,
      sellingYear,
      sellingMonth,
      returnAppreciation,
      value: startingValue,
      salesCostPercentage
    } = valuesForCalculation;

    if (startingValue && buyingYear && sellingYear && returnAppreciation) {
      const appreciatedValue = compoundInterestMonthly(
        startingValue,
        findDiffInNumberOfMonths(buyingMonth, buyingYear, sellingMonth, sellingYear),
        returnAppreciation
      );

      updateNumberValue(appreciatedValue, salesCostPercentage, 'salesCost');
    }
  };

  const updateSalesCostPercentage = (updatedValues = {}) => {
    const valuesForCalculation = { ...values, ...updatedValues };

    //sales cost percentage
    const {
      buyingYear,
      buyingMonth,
      sellingYear,
      sellingMonth,
      returnAppreciation,
      value: startingValue,
      salesCost
    } = valuesForCalculation;
    if (startingValue && buyingYear && sellingYear && returnAppreciation) {
      const appreciatedValue = compoundInterestMonthly(
        startingValue,
        findDiffInNumberOfMonths(buyingMonth, buyingYear, sellingMonth, sellingYear),
        returnAppreciation
      );

      updatePercentageValue(appreciatedValue, salesCost, 'salesCostPercentage');
    }
  };

  const onAssetSalesCostChange = value => {
    updateSalesCostPercentage({ salesCost: value });
  };

  const onAssetSalesPercentageChange = value => {
    //sales cost percentage
    updateSalesCost({ salesCostPercentage: value });
  };

  const onAssetEditModeChange = value => {
    const isEditMode = value === 'Edit Mode';
    if (!isEditMode) {
      [
        'purchaseCost',
        'purchaseCostPercentage',
        'renovationCost',
        'renovationCostPercentage',
        'realtorCost',
        'realtorCostPercentage',
        'makeReadyCost',
        'makeReadyPercentage'
      ].forEach(field => setFieldValue(field, 0));
    }
  };

  const onAssetSellMonthChange = sellingMonth => {
    updateSalesCost({ sellingMonth });
  };

  const onAssetSellYearChange = sellingYear => {
    updateSalesCost({ sellingYear });
  };

  const onAssetBuyMonthChange = buyingMonth => {
    updateSalesCost({ buyingMonth });
  };

  const onAssetBuyYearChange = buyingYear => {
    updateSalesCost({ buyingYear });
  };

  const onAssetAppreciationChange = returnAppreciation => {
    updateSalesCost({ returnAppreciation });
  };

  const onAssetValueCurrencyConvert = currencyConversionInfo => {
    setFieldValue('valueCurrencyConversionInfo', {
      conversionCurrency: currencyConversionInfo.fromCurrency,
      conversionRate: currencyConversionInfo.conversionRate,
      conversionAmount: currencyConversionInfo.amount
    });
  };

  const onRSUInitialValueCurrencyConvert = currencyConversionInfo => {
    setFieldValue('initialRSUStockValueCurrencyConversionInfo', {
      conversionCurrency: currencyConversionInfo.fromCurrency,
      conversionRate: currencyConversionInfo.conversionRate,
      conversionAmount: currencyConversionInfo.amount
    });
  };

  useEffect(() => {
    console.log({ values });
  }, [values]);

  const renderRSUGrants = () => {
    return (
      <RSUGrants
        strategyYears={strategyYears}
        rsuGrants={values.rsuGrants}
        onRSUGrantsChange={grants => setFieldValue('rsuGrants', grants)}
        onRSUGrantsAddClick={() => {
          const newGrant = {
            id: uniqueId(),
            year: strategyYears[0],
            month: 0,
            vestingMonth: 0
          };

          setFieldValue('rsuGrants', [...(values.rsuGrants || []), newGrant]);
        }}
        onRSUGrantsDeleteClick={grant => {
          setFieldValue(
            'rsuGrants',
            values.rsuGrants.filter(g => g.id !== grant.id)
          );
        }}
      />
    );
  };

  const renderPartialSales = () => {
    return (
      <PartialSales
        sales={values.partialSales}
        isRSU={values.category === ASSET_CATEGORIES.RSU}
        strategyYears={strategyYears}
        onSalesChange={sales => setFieldValue('partialSales', sales)}
        onSalesAddClick={() => {
          const newPayment = {
            id: uniqueId(),
            afterTax: false,
            year: strategyYears[0],
            month: 0,
            value: 0,
            taxPercentage: 0
          };
          setFieldValue('partialSales', [...(values.partialSales || []), newPayment]);
        }}
        onSalesDeleteClick={sales => {
          setFieldValue(
            'partialSales',
            values.partialSales.filter(s => s.id !== sales.id)
          );
        }}
      />
    );
  };

  const renderDeposits = () => {
    return (
      <Deposits
        assetOptions={assetOptions}
        deposits={values.deposits}
        onDepositChange={sales => setFieldValue('deposits', sales)}
        onDepositAddClick={() => {
          const deposit = {
            id: uniqueId(),
            source: '',
            frequency: transferFrequencyOptions[0].value
          };
          setFieldValue('deposits', [...(values.deposits || []), deposit]);
        }}
        onDepositDeleteClick={deposit => {
          setFieldValue(
            'deposits',
            values.deposits.filter(s => s.id !== deposit.id)
          );
        }}
      />
    );
  };

  const renderPaperApartmentPayments = () => {
    return (
      <PaperApartmentPayments
        apartmentValue={values.value}
        strategyYears={strategyYears}
        paperApartmentPayments={values.paperApartmentPayments}
        onPaymentsChange={payments => setFieldValue('paperApartmentPayments', payments)}
        onPaymentAddClick={() => {
          const newPayment = {
            id: uniqueId(),
            amount: 0,
            '%': 0,
            month: 0,
            year: strategyYears[0]
          };
          setFieldValue('paperApartmentPayments', [...(values.paperApartmentPayments || []), newPayment]);
        }}
        onPaymentDeleteClick={payment => {
          setFieldValue(
            'paperApartmentPayments',
            values.paperApartmentPayments.filter(p => p.id !== payment.id)
          );
        }}
      />
    );
  };

  const calculateTax = (valuesToOverride = {}) => {
    const newValues = { ...values, ...valuesToOverride };
    if (newValues.category !== ASSET_CATEGORIES.REAL_ESTATE) return {};

    const isAbroadApartment = newValues.apartmentType === APARTMENT_TYPES.ABROAD;
    const isEditMode = newValues.editMode === 'Edit Mode';

    const {
      buyingTaxBrackets,
      sellingTaxBrackets,
      buyingTaxBracketsForOnlyApartment,
      sellingTaxBracketsForOnlyApartment
    } = adminCompTable;

    const buyingTaxBreakdown =
      isAbroadApartment || !isEditMode
        ? []
        : calculateTaxFromBrackets(
            newValues.value,
            newValues.onlyApartment?.buying ? buyingTaxBracketsForOnlyApartment : buyingTaxBrackets
          );

    const isBuyAutomaticallyCalculated =
      typeof newValues?.tax?.isBuyAutomaticallyCalculated !== 'boolean'
        ? true
        : newValues?.tax?.isBuyAutomaticallyCalculated;

    const isSellAutomaticallyCalculated =
      typeof newValues?.tax?.isSellAutomaticallyCalculated !== 'boolean'
        ? true
        : newValues?.tax?.isSellAutomaticallyCalculated;

    let buy = 0;
    if (isEditMode) {
      buy = isAbroadApartment
        ? (newValues.value || 0) * (newValues.tax?.buyPercentage || 0) * 0.01
        : isBuyAutomaticallyCalculated
        ? buyingTaxBreakdown.reduce((total, t) => total + t.actualTax, 0)
        : newValues.tax?.buy || 0;
    }

    const buyPercentage = !newValues.value ? (buy * 100) / newValues.value : 0;

    const sellTaxInfo = calculateSellingTax({
      assetValue: newValues.value,
      assetBuyYear: newValues.buyingYear,
      assetSellYear: newValues.sellingYear,
      assetBuyMonth: newValues.buyingMonth,
      assetSellMonth: newValues.sellingMonth,
      assetAppreciation: newValues.returnAppreciation,
      onlyApartment: newValues.onlyApartment?.selling,
      assetPurchaseCost: newValues.purchaseCost,
      assetRenovationCost: newValues.renovationCost,
      assetSalesCost: newValues.salesCost,
      assetMakeReadyCost: newValues.makeReadyCost,
      assetRealtorCost: newValues.realtorCost,
      sellingTaxBrackets,
      sellingTaxBracketsForOnlyApartment,
      totalBuyingTax: buy,
      isApartmentAbroad: isAbroadApartment,
      autoMode: isSellAutomaticallyCalculated
    });

    const sellingTaxBreakdown = sellTaxInfo.taxes || [];
    let sell;
    if (isSellAutomaticallyCalculated) {
      if (!isAbroadApartment) {
        sell = sellingTaxBreakdown.reduce((total, t) => total + t.actualTax, 0);
      } else {
        sell = sellTaxInfo.pnl > 0 ? newValues.tax?.sellPercentage || 0 * 0.01 * sellTaxInfo.pnl : 0;
      }
    } else {
      sell = newValues.tax?.sell || 0;
    }

    const sellPercentage = sellTaxInfo.pnl > 0 ? (sell * 100) / sellTaxInfo.pnl : 0;

    return {
      buy,
      buyPercentage,
      buyTaxBreakdown: buyingTaxBreakdown,
      isBuyAutomaticallyCalculated,
      sell,
      sellPercentage,
      sellingTaxBreakdown: sellingTaxBreakdown,
      isSellAutomaticallyCalculated,
      pnl: sellTaxInfo.pnl,
      appreciatedValue: sellTaxInfo.appreciatedValue
    };
  };

  useEffect(() => {
    setFieldValue('tax', calculateTax());
  }, [
    values.category,
    values.apartmentType,
    values.editMode,
    values.value,
    values.onlyApartment?.buying,
    values.onlyApartment?.selling,
    values.tax?.isBuyAutomaticallyCalculated,
    values.tax?.isSellAutomaticallyCalculated,
    values.buyingYear,
    values.sellingYear,
    values.buyingMonth,
    values.sellingMonth,
    values.returnAppreciation,
    values.purchaseCost,
    values.renovationCost,
    values.realtorCost,
    values.makeReadyCost,
    values.salesCost
  ]);

  const renderTaxes = () => {
    return (
      <AssetTaxes
        isApartmentAbroad={values.apartmentType === APARTMENT_TYPES.ABROAD}
        taxInfo={values.tax}
        assetEditMode={values.editMode === 'Edit Mode'}
        assetValue={values.value}
        onBuyingTaxChange={value => {
          const newTax = values.tax || {};
          newTax.buy = value;
          newTax.buyPercentage = values.value ? (value * 100) / values.value : 0;
          setFieldValue('tax', calculateTax({ tax: newTax }));
        }}
        onBuyingTaxPercentageChange={percentage => {
          const newTax = values.tax || {};
          newTax.buy = (values.value || 0) * percentage * 0.01;
          newTax.buyPercentage = percentage;
          setFieldValue('tax', calculateTax({ tax: newTax }));
        }}
        buyingTaxAutoMode={values?.tax?.isBuyAutomaticallyCalculated}
        onBuyingTaxCalculationModeChange={() => {
          const newTax = values.tax || {};
          newTax.isBuyAutomaticallyCalculated = !values?.tax?.isBuyAutomaticallyCalculated;
          setFieldValue('tax', newTax);
        }}
        onSellingTaxChange={value => {
          const newTax = values.tax || {};
          newTax.sell = value;
          newTax.sellPercentage = newTax.pnl ? (value * 100) / newTax.pnl : 0;
          setFieldValue('tax', newTax);
        }}
        onSellingTaxPercentageChange={percentage => {
          const newTax = values.tax || {};
          newTax.sell = (newTax.pnl || 0) * percentage * 0.01;
          newTax.sellPercentage = percentage;
          setFieldValue('tax', newTax);
        }}
        sellingTaxAutoMode={values?.tax?.isSellAutomaticallyCalculated}
        onSellingTaxCalculationModeChange={() => {
          const newTax = values.tax || {};
          newTax.isSellAutomaticallyCalculated = !values?.tax?.isSellAutomaticallyCalculated;
          setFieldValue('tax', newTax);
        }}
        onlyApartment={values.onlyApartment}
        onFirstApartmentCheckedChange={(type, checked) => {
          const newOnlyApartmentValue = values.onlyApartment || {};
          newOnlyApartmentValue[type] = checked;
          setFieldValue('onlyApartment', { ...newOnlyApartmentValue });
        }}
      />
    );
  };

  const onFixedIncomeInterestChange = interest => {
    setFieldValue(
      'monthlyPayment',
      getMonthlyPayment({
        ...values,
        interest
      })
    );
  };

  const onFixedIncomeTimeToMaturityChange = timeToMaturity => {
    setFieldValue(
      'monthlyPayment',
      getMonthlyPayment({
        ...values,
        timeToMaturity
      })
    );
  };

  const onFixedIncomeMonthlyPaymentChange = monthlyPayment => {
    setFieldValue(
      'monthlyPayment',
      getMonthlyPayment({
        ...values,
        monthlyPayment
      })
    );
  };

  const onAssetValueAddedByRenovationChange = value => {};

  const assetSections = useMemo(() => {
    const showImportButton =
      isAdmin(user?.role) &&
      values.category === 'Real Estate' &&
      apartmentTypeToKey[values.apartmentType] &&
      activeCustomer['allowedCalculators']?.includes(apartmentTypeToKey[values.apartmentType]);

    return assetRows({
      assetCategory: values.category,
      presetOptions: compTable.presets.filter(p => p.assetType === values.category),
      assetEditMode: values.editMode === 'Edit Mode',
      apartmentType: values.apartmentType,
      assetOptions,
      user,
      showImport: showImportButton,
      fromAddForm: !isUpdatingAsset,
      isPrimaryBankAccount: values.category === ASSET_CATEGORIES.CASH && isPrimaryBankAccount(values),
      onImportClick: () => onImportClick(apartmentTypeToKey[values.apartmentType]),
      renderPaperApartmentPayments,
      renderTaxes,
      renderRSUGrants,
      renderPartialSales,
      renderDeposits,
      //callbacks
      onPresetChange,
      onAssetCategoryChange,
      onAssetValueChange,
      onAssetCashflowChange,
      onAssetCashflowPercentageChange,
      onAssetPurchaseCostChange,
      onAssetPurchasePercentageChange,
      onAssetRenovationCostChange,
      onAssetRenovationPercentageChange,
      onAssetRealtorCostChange,
      onAssetRealtorPercentageChange,
      onAssetMakeReadyCostsChange,
      onAssetMakeReadyPercentageChange,
      onAssetSalesCostChange,
      onAssetSalesPercentageChange,
      onAssetEditModeChange,
      onAssetSellMonthChange,
      onAssetSellYearChange,
      onAssetBuyMonthChange,
      onAssetBuyYearChange,
      onAssetAppreciationChange,
      onAssetAppreciationChange,
      onAssetValueCurrencyConvert,
      onRSUInitialValueCurrencyConvert,
      onFixedIncomeInterestChange,
      onFixedIncomeMonthlyPaymentChange,
      onFixedIncomeTimeToMaturityChange,
      onAssetRentPreConstructionChange,
      onAssetRentPreConstructionPercentageChange,
      onAssetValueAddedByRenovationChange
    });
  }, [
    strategyYears,
    incompleteYearsOfStrategy,
    values,
    assetOptions,
    compTable,
    user,
    renderPaperApartmentPayments,
    renderTaxes,
    renderRSUGrants
  ]);

  return (
    <>
      <form onSubmit={handleSubmit} className="p-2">
        {assetSections.map(row => (
          <CollapsibleSection
            key={row.rowName}
            sectionName={row.rowName}
            isCollapsed={collapsedSections.includes(row.rowName)}
            onShowHideClick={() =>
              collapsedSections.includes(row.rowName)
                ? setCollapsedSections(collapsedSections.filter(c => c !== row.rowName))
                : setCollapsedSections([...collapsedSections, row.rowName])
            }
          >
            <Row className={`px-2 mb-2`}>
              {row.columns.map(col => {
                const CustomRender = col.field.render;
                return (
                  <Col key={col.field.key} xs={col.xs || 12} md={col.default || 6} className="my-2 align-self-end">
                    <FormGroup>
                      {(col.field.title || col.field.titleKey) && (
                        <FormLabel>
                          <h6 className="mid mb-0">
                            {translate(col.field.titleKey) || translate(snakeCase(col.field.title)) || col.field.title}
                            {col.field.required && <span className="text-danger">*</span>}
                          </h6>
                        </FormLabel>
                      )}

                      {col.field.type === 'dropdown' && (
                        <FormSelect
                          autoComplete={false}
                          size="sm"
                          name={col.field.key}
                          onChange={e => {
                            handleChange(e);
                            col.field.onChange && col.field.onChange(e.target.value);
                          }}
                          value={values[col.field.key]}
                          disabled={col.field.disabled}
                        >
                          {col.field.options.map((option, index) => {
                            const isObject = typeof option === 'object';
                            const label = isObject ? option.label : option;
                            const value = isObject ? option.value : option;

                            return (
                              <option key={value} value={value}>
                                {label}
                              </option>
                            );
                          })}
                        </FormSelect>
                      )}
                      {col.field.type === 'text' && (
                        <FormControl
                          autoComplete={false}
                          size="sm"
                          type="text"
                          name={col.field.key}
                          onChange={e => {
                            handleChange(e);
                            col.field.onChange && col.field.onChange(e.target.value);
                          }}
                          value={values[col.field.key]}
                          disabled={col.field.disabled}
                        />
                      )}
                      {col.field.type === 'checkbox' && (
                        <div>
                          <FormCheck
                            autoComplete={false}
                            size="sm"
                            name={col.field.key}
                            onChange={e => {
                              setFieldValue(col.field.key, e.target.checked);
                              col.field.onChange && col.field.onChange(e.target.checked);
                            }}
                            checked={values[col.field.key]}
                            disabled={col.field.disabled}
                            label={
                              col.field.showLabel && (
                                <label style={{ fontSize: 13 }} className="ml-1 form-check-label my-1">
                                  {col.field.boldLabel ? (
                                    <b>
                                      {translate(snakeCase(col.field.label)) ||
                                        translate(snakeCase(col.field.title)) ||
                                        col.field.label ||
                                        col.field.title}
                                    </b>
                                  ) : (
                                    translate(snakeCase(col.field.label)) ||
                                    translate(snakeCase(col.field.title)) ||
                                    col.field.label ||
                                    col.field.title
                                  )}
                                </label>
                              )
                            }
                          />
                        </div>
                      )}
                      {col.field.type === 'text-area' && (
                        <FormControl
                          size="sm"
                          autoComplete={false}
                          type="text"
                          name={col.field.key}
                          onChange={e => {
                            handleChange(e);
                            col.field.onChange && col.field.onChange(e.target.value);
                          }}
                          value={values[col.field.key]}
                          disabled={col.field.disabled}
                          as={'textarea'}
                        />
                      )}

                      {(col.field.type === 'currency' || col.field.type === 'number') && (
                        <CurrencyInput
                          onChange={(numberValue, formattedValue) => {
                            setFieldValue(col.field.key, numberValue);
                            col.field.onChange && col.field.onChange(numberValue);
                          }}
                          value={values[col.field.key]}
                          disabled={col.field.disabled}
                          onCurrencyConvert={info => {
                            col.field.onCurrencyConvert && col.field.onCurrencyConvert(info);
                          }}
                          maxDecimal={0}
                          withCurrencyConverter={col.field.withCurrencyConverter}
                          currencyConversionInfo={values[col.field.currencyConversionInfoKey]}
                        />
                      )}
                      {col.field.type === 'percentage' && (
                        <PercentageInput
                          onChange={numberValue => {
                            setFieldValue(col.field.key, numberValue);
                            col.field.onChange && col.field.onChange(numberValue);
                          }}
                          value={values[col.field.key]}
                          disabled={col.field.disabled}
                        />
                      )}
                      {col.field.type === 'block-select' && (
                        <BlockSelectInput
                          options={col.field.options}
                          onChange={value => {
                            setFieldValue(col.field.key, value);
                            col.field.onChange && col.field.onChange(value);
                          }}
                          value={values[col.field.key]}
                          disabled={col.field.disabled}
                          multiple={col.field.multiple}
                        />
                      )}
                      {col.field.type === 'monthYearPicker' && (
                        <MonthYearPicker
                          onMonthChange={month => {
                            setFieldValue(col.field.monthKey, month);
                            col.field.onMonthChange && col.field.onMonthChange(month);
                          }}
                          onYearChange={year => {
                            setFieldValue(col.field.yearKey, year);
                            col.field.onYearChange && col.field.onYearChange(year);
                          }}
                          month={values[col.field.monthKey]}
                          year={values[col.field.yearKey]}
                          disabled={col.field.disabled}
                          monthLabel={translate(col.field.monthLabel)}
                          yearLabel={translate(col.field.yearLabel)}
                          years={strategyYears}
                          incompleteYears={incompleteYearsOfStrategy}
                          required={col.field.required}
                        />
                      )}
                      {col.field.type === 'custom' && CustomRender && CustomRender()}
                      {errors[col.field.key] && <div className="text-danger large">{errors[col.field.key]}</div>}
                    </FormGroup>
                  </Col>
                );
              })}
            </Row>
          </CollapsibleSection>
        ))}
      </form>
      {showProgress && <HorizontalProgress text={isUpdatingAsset ? 'Updating Asset...' : 'Adding Asset....'} />}

      <hr />
      <div className={isRTL ? 'text-start' : 'text-end'}>
        <Button disabled={showProgress} size="sm" variant="success" className="mr-2 text-white" onClick={onAssetSubmit}>
          {isUpdatingAsset ? 'Update' : 'Add'} Asset
        </Button>

        <Button
          disabled={showProgress}
          size="sm"
          variant="success"
          className="mr-2 text-white"
          onClick={onGroupAssetSubmit}
        >
          Group {isUpdatingAsset ? 'Update' : 'Add'} Asset
        </Button>

        {!isUpdatingAsset && (
          <Button
            disabled={showProgress}
            size="sm"
            variant="danger"
            className="mr-2"
            onClick={onAddAssetWithLiabilityClick}
          >
            Link a new liability
          </Button>
        )}
      </div>

      {[
        {
          show: calculatorToShow === IRR_CALCULATOR_KEY,
          onHide: setCalculatorToShow,
          key: IRR_CALCULATOR_KEY,
          Calculator: IrrCalculatorSidebar
        },
        {
          show: calculatorToShow === PINUI_BINUI_CALCULATOR_KEY,
          onHide: setCalculatorToShow,
          key: PINUI_BINUI_CALCULATOR_KEY,
          Calculator: PinuiBinuiIrrCalculatorSidebar
        },
        {
          show: calculatorToShow === PAPER_CALCULATOR_KEY,
          onHide: setCalculatorToShow,
          key: PAPER_CALCULATOR_KEY,
          Calculator: PaperApartmentCalculatorSidebar
        }
      ]
        .filter(a => activeCustomer['allowedCalculators']?.includes(a.key))
        .map(({ show, onHide, Calculator }) => (
          <Calculator
            translate={translate}
            customer={activeCustomer}
            show={show}
            adminCompTable={adminCompTable}
            onSelectAppartment={onSelectAppartment}
            onHide={() => onHide(false)}
            selectMode={true}
          />
        ))}
    </>
  );
};

export default AssetAddEditForm;

import React, { useMemo } from 'react';
import { Button, FormControl, FormSelect } from 'react-bootstrap';
import { PlusCircle, Trash } from 'react-bootstrap-icons';
import { formatCurrency, formatPercentage } from '../../components/admin/manage-users/customer/strategies/helper';
import UnderlineButton from '../../components/common/UnderlineButton';
import AppTable from '../../components/common/app-table/AppTable';
import { monthSmall } from '../../helpers/constants';
import { findIfUserIsInterestedInCourseAndOfSpecifiedLevel } from '../../helpers/global';
import useAuth from '../../hooks/useAuth';
import useLocalization from '../../hooks/useLocalization';

const getPaymentHeaders = (translate, strategyYears) => [
  {
    name: translate('month'),
    key: 'month',
    type: 'dropdown',
    options: monthSmall.map((m, i) => ({ option: m, value: i }))
  },
  {
    name: translate('year'),
    key: 'year',
    type: 'dropdown',
    options: strategyYears.map(y => ({ option: y, value: y }))
  },
  {
    name: translate('value'),
    type: 'number',
    format: 'currency',
    key: 'amount'
  },
  {
    name: '%',
    type: 'number',
    format: '%',
    key: '%'
  }
];

const PaperApartmentPayments = ({
  apartmentValue = 0,
  paperApartmentPayments = [],
  onPaymentsChange,
  onPaymentAddClick,
  onPaymentDeleteClick,
  strategyYears = []
}) => {
  const { langCode, translate } = useLocalization();
  const paymentHeaders = useMemo(() => getPaymentHeaders(translate, strategyYears), [langCode, strategyYears]);
  const { user } = useAuth();

  const onChange = (payment, field, type, format, value) => {
    payment[field] = value;

    if (type === 'number') {
      if (format === '%') {
        //update number also
        payment.amount = apartmentValue * value * 0.01;
      } else {
        //update % also
        payment['%'] = apartmentValue === 0 ? 0 : ((value * 100) / apartmentValue).toFixed(2);
      }
    }

    onPaymentsChange && onPaymentsChange(paperApartmentPayments.map(p => (p.id === payment.id ? { ...payment } : p)));
  };

  return (
    <>
      <AppTable className="mb-0">
        <thead>
          <tr className="text-white bg-dark">
            {paymentHeaders.map(({ name }) => (
              <th style={{ minWidth: 100 }} key={name}>
                {name}
              </th>
            ))}
            {!findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1]) && (
              <th style={{ minWidth: 70 }}>{translate('actions')}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {paperApartmentPayments.length > 0 ? (
            <>
              {paperApartmentPayments.map(payment => (
                <tr key={payment.id}>
                  {paymentHeaders.map(({ name, type, options, key, format }) => (
                    <td className="bg-white p-0" key={name}>
                      {type === 'dropdown' && (
                        <FormSelect
                          className="mb-0"
                          autoComplete="off"
                          size="sm"
                          value={payment[key]}
                          onChange={e => {
                            onChange(payment, key, type, format, e.target.value);
                          }}
                        >
                          {options.map(o => (
                            <option key={o.value} value={o.value}>
                              {o.option}
                            </option>
                          ))}
                        </FormSelect>
                      )}
                      {type === 'number' && (
                        <FormControl
                          className="mb-0"
                          autoComplete="off"
                          type="text"
                          size="sm"
                          value={format === 'currency' ? formatCurrency(payment[key]) : formatPercentage(payment[key])}
                          onChange={e => {
                            let inputValue = Number(
                              e.target.value.replaceAll(format === 'currency' ? ',' : '%', '').trim() || 0
                            );
                            if (Number.isNaN(inputValue)) return;
                            //call on change
                            onChange(payment, key, type, format, inputValue);
                          }}
                        />
                      )}
                    </td>
                  ))}
                  {!findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1]) && (
                    <td className="p-0">
                      <div className="text-center">
                        <Button
                          variant="outline-danger"
                          className="px-1 py-0 "
                          onClick={() => onPaymentDeleteClick(payment)}
                        >
                          <Trash size={9} />
                        </Button>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={paymentHeaders.length + 1}>
                <h6 className="text-muted text-center mb-0 smallFont">{translate('nothing_to_show')}</h6>
              </td>
            </tr>
          )}
        </tbody>
      </AppTable>
      <div className="text-end">
        {!findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1]) && (
          <UnderlineButton
            fontSize="smallFont"
            variant="success"
            Icon={PlusCircle}
            text={translate('new')}
            onClick={onPaymentAddClick}
          />
        )}
      </div>
    </>
  );
};

export default PaperApartmentPayments;

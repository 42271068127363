import React, { useMemo } from 'react';
import { OverlayTrigger, Popover, Table } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import useLocalization from '../../hooks/useLocalization';
import { formatCurrency } from '../admin/manage-users/customer/strategies/helper';

const Summary = ({ data, extraInfo: { timeLabel } }) => {
  const { translate } = useLocalization();

  const dataRows = useMemo(
    () =>
      Object.keys(data).sort((a, b) => {
        if (a === 'Begin') return -1;
        if (b === 'Begin') return 1;
        return a - b;
      }),
    [data]
  );
  const PopoverModal = (
    <Popover onClick={e => e.stopPropagation()} style={{ minWidth: 600 }} className="" id="popover-positioned-left">
      <Popover.Header className="p-1 fw-bold bg-primary text-white">{translate('break_down')}</Popover.Header>
      <Popover.Body className="p-0" style={{ maxHeight: '70vh', overflowY: 'auto', overflowX: 'hidden' }}>
        <Table striped bordered hover className="bg-white m-0 mb-0">
          <tbody>
            {dataRows?.map(row => {
              return (
                <>
                  <tr className="bg-dark text-white px-2 no-pointer-events py-1">
                    <td colSpan={2} className="text-white fw-bold py-1">
                      {translate(timeLabel) || timeLabel} {row}
                    </td>
                  </tr>
                  {data?.[row]?.map(dataRow => {
                    return (
                      <tr>
                        <td className="fw-bold py-1"> {translate(dataRow?.description) || dataRow?.description}</td>{' '}
                        <td className="py-1">{formatCurrency(dataRow?.cashflow)}</td>
                      </tr>
                    );
                  })}
                  <tr className="bg-primary-light">
                    <td className="py-1 text-dark ">
                      <span className="fw-bold">{translate('total')}</span>{' '}
                    </td>
                    <td className="py-1 text-dark">
                      {formatCurrency(
                        data?.[row]?.reduce((total, transaction) => {
                          return total + transaction.cashflow;
                        }, 0)
                      )}
                    </td>
                  </tr>
                </>
              );
            })}{' '}
          </tbody>
        </Table>
      </Popover.Body>
    </Popover>
  );
  return (
    <OverlayTrigger rootClose trigger="click" placement="auto" overlay={PopoverModal}>
      <InfoCircle className="mx-2 hover text-primary-new" size={15} />
    </OverlayTrigger>
  );
};

export default Summary;

import React from 'react';
import { Button, Col, FormControl, Row } from 'react-bootstrap';
import CustomMultiSelect from '../common/CustomMultiSelect';
import { PersonCircle, Plus, X, XCircleFill, XLg } from 'react-bootstrap-icons';
import RealEstateIRR from '../calculators/Irr';
import PinuiBinuiIRR from '../calculators/PinuiBinui';
import PaperApartmentIRR from '../calculators/PaperApartment';
import CustomerSelectModal from '../calculators/CustomerSelectModal';
import { apartmentTypes } from '../../helpers/global';
import { APARTMENT_STATUS } from '../../helpers/constants';
import useLocalization from '../../hooks/useLocalization';
import cities from '../../helpers/israel-cities.json';

const AddEditApartmentForm = ({
  editMode,
  apartmentType,
  apartmentValues,
  setApartment,
  adminCompTable,
  apartmentExtraInfo,
  setApartmentExtraInfo,
  customerPickerModalMeta,
  setCustomerPickerModalMeta,
  handleSelectCustomer,
  assigneUsers
}) => {
  const { translate } = useLocalization();

  const onCustomerSelect = selectedCustomer => {
    let previousSelcectedCustomers = customerPickerModalMeta?.selectedCustomer || [];
    if (previousSelcectedCustomers?.find(c => c?._id === selectedCustomer?._id)) {
      previousSelcectedCustomers = previousSelcectedCustomers?.filter(c => c?._id !== selectedCustomer?._id);
    } else {
      previousSelcectedCustomers = [...previousSelcectedCustomers, selectedCustomer];
    }
    setCustomerPickerModalMeta(meta => ({ ...meta, selectedCustomer: previousSelcectedCustomers }));
  };

  const removeCustomer = customer => {
    setApartmentExtraInfo({
      ...apartmentExtraInfo,
      customers: apartmentExtraInfo?.customers?.filter(c => c?._id !== customer?._id)
    });
  };

  return (
    <div>
      <div className="border rounded mx-3 my-2 p-2">
        {' '}
        <Row className=" m-0">
          <Col xs={12} sm={6} md={2} className="p-1">
            <h6 className="smallFont">Apartment type</h6>
            <CustomMultiSelect
              selectedItems={apartmentExtraInfo?.type}
              items={apartmentTypes}
              onChange={type => setApartmentExtraInfo({ ...apartmentExtraInfo, type })}
              isMulti={false}
              closeMenuOnSelect
              height="30px"
            />
          </Col>
          <Col xs={12} sm={6} md={2} className="p-1">
            <h6 className="smallFont">{translate('city')}</h6>
            <FormControl
              size="sm"
              value={apartmentExtraInfo?.city}
              onChange={e => setApartmentExtraInfo({ ...apartmentExtraInfo, city: e.target.value })}
              list="cities-list"
            />
            <datalist id="cities-list">
              {cities.map(({ city }) => (
                <option key={city} value={city} />
              ))}
            </datalist>
          </Col>{' '}
          <Col xs={12} sm={6} md={2} className="p-1">
            <h6 className="smallFont">{translate('status')}</h6>
            <CustomMultiSelect
              selectedItems={apartmentExtraInfo?.status}
              items={APARTMENT_STATUS?.map(s => ({ label: s, value: s }))}
              onChange={status => setApartmentExtraInfo({ ...apartmentExtraInfo, status })}
              isMulti={false}
              closeMenuOnSelect
              placeholder=""
              height="30px"
            />
          </Col>{' '}
          <Col xs={12} sm={6} md={2} className="p-1">
            <h6 className="smallFont">Gush tat helka</h6>
            <FormControl
              size="sm"
              value={apartmentExtraInfo?.gushTatHelka}
              onChange={e => setApartmentExtraInfo({ ...apartmentExtraInfo, gushTatHelka: e.target.value })}
            />
          </Col>{' '}
          <Col xs={12} sm={6} md={4} className="p-1 d-flex flex-column gap-1">
            <h6 className="smallFont">Related Customers</h6>
            <div className="d-flex align-items-center gap-1 flex-wrap">
              {apartmentExtraInfo?.customers?.map(c => (
                <div key={c?._id} className="bg-primary d-flex gap-1 align-items-center text-white hover rounded p-1">
                  <PersonCircle /> <h6 className="smaller fw-bold mb-0">{c?.name || c?.email || 'No name'}</h6>{' '}
                  <X className="text-white" size={18} onClick={() => removeCustomer(c)} />
                </div>
              ))}{' '}
              <Button
                size="sm"
                variant="dark"
                className="d-flex align-items-center"
                onClick={() => setCustomerPickerModalMeta({ selectedCustomer: apartmentExtraInfo?.customers || [] })}
              >
                <Plus /> <h6 className="smallFont mb-0">Select customer</h6>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      {apartmentType === apartmentTypes[0]?.value && (
        <RealEstateIRR
          customer={apartmentValues}
          addApartmentMode
          adminCompTable={adminCompTable}
          onChange={setApartment}
          assigneUsers={assigneUsers}
        />
      )}{' '}
      {apartmentType === apartmentTypes[1]?.value && (
        <PinuiBinuiIRR
          addApartmentMode
          customer={apartmentValues}
          adminCompTable={adminCompTable}
          onChange={setApartment}
          assigneUsers={assigneUsers}
        />
      )}
      {apartmentType === apartmentTypes[2]?.value && (
        <PaperApartmentIRR
          addApartmentMode
          customer={apartmentValues}
          adminCompTable={adminCompTable}
          onChange={setApartment}
          assigneUsers={assigneUsers}
        />
      )}
      <CustomerSelectModal
        show={Boolean(customerPickerModalMeta)}
        onHide={() => setCustomerPickerModalMeta(null)}
        selectedCustomer={customerPickerModalMeta?.selectedCustomer}
        onCustomerSelect={onCustomerSelect}
        onSubmit={handleSelectCustomer}
        multiSelect
        submitButtonText="Select"
        headerText="Select customer"
      />
    </div>
  );
};

export default AddEditApartmentForm;

import React, { useEffect, useMemo, useState } from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { ArrowLeftRight, CurrencyExchange, X } from 'react-bootstrap-icons';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import CircularProgressBar from '../../components/common/circular-progress';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS, currencyOptions } from '../../helpers/constants';
import useDebouncedEffect from '../../hooks/useDebouncedEffect';
import useLocalization from '../../hooks/useLocalization';

const formatValue = (num, decimal = 2) => {
  if (num === undefined || num === null) return '';

  let numToFormat = num;
  if (typeof num === 'string') {
    if (!num?.trim()) return '';

    const numCleaned = Number(num.toString().replace(/[^0-9.-]/g, ''));
    if (isNaN(numCleaned)) return '';

    numToFormat = numCleaned;
  }

  return numToFormat.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimal
  });
};

const CurrencyInput = ({
  value,
  onCurrencyConvert,
  onChange,
  className,
  returnZeroByDefault = true,
  currencyConversionInfo,
  withCurrencyConverter,
  maxDecimal = 2,
  disabled,
  ...props
}) => {
  const { isRTL, translate } = useLocalization();
  const [inputValue, setInputValue] = useState(formatValue(value, maxDecimal));
  const [showCurrencyConverter, setShowCurrencyConverter] = useState(Boolean(currencyConversionInfo));
  const [currencyValue, setCurrencyValue] = useState(
    currencyConversionInfo?.amount || currencyConversionInfo?.conversionAmount || 0
  );
  const [currencyToConvertFrom, setCurrencyToConvertFrom] = useState(
    currencyConversionInfo?.fromCurrency || currencyConversionInfo?.conversionCurrency || currencyOptions[0].symbol
  );
  const [fetchingConvertedValue, setFetchingConvertedValue] = useState(false);

  const currencySelectOptions = useMemo(
    () =>
      currencyOptions.map(option => ({
        value: option.symbol,
        label: option.name
      })),
    []
  );

  const handleInputChange = e => {
    const rawValue = e.target.value;
    // Remove all non-numeric characters except the first occurrence of "-" and "."
    const sanitizedValue = rawValue.replace(/[^0-9.-]/g, '').trim();
    if (sanitizedValue === '-') {
      setInputValue(sanitizedValue);
      onChange(returnZeroByDefault ? 0 : null, sanitizedValue);
      return;
    }

    const numberValue = parseFloat(sanitizedValue);

    // Call onChange if the value is a valid number
    if (!isNaN(numberValue)) {
      let formatedValue = formatValue(numberValue, maxDecimal);
      if (sanitizedValue.endsWith('.')) {
        formatedValue += '.';
      }
      setInputValue(formatedValue);
      onChange(numberValue, formatedValue);
    } else {
      setInputValue('');
      onChange(returnZeroByDefault ? 0 : null, '');
    }
  };

  useEffect(() => {
    if (value !== undefined && value !== null) {
      const numberValue = Number(value?.toString().replace(/[^0-9.-]/g, ''));
      if (isNaN(numberValue) || !isFinite(numberValue)) {
        onChange(returnZeroByDefault ? 0 : null, '');
        setInputValue('');
        return;
      }
    }

    setInputValue(formatValue(value, maxDecimal));
  }, [value]);

  const convertCurrency = async () => {
    if (!currencyValue) return;

    setFetchingConvertedValue(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.UTILS_CONVERT_CURRENCY,
      method: 'POST',
      requestBody: {
        amount: currencyValue,
        fromCurrency: currencyToConvertFrom
      }
    });

    setFetchingConvertedValue(false);
    if (error) {
      toast.error(error);
      return;
    }

    if (response.convertedAmount !== null) {
      onChange && onChange(response.convertedAmount);
      onCurrencyConvert && onCurrencyConvert(response);
    }
  };

  useDebouncedEffect(
    () => {
      if (currencyValue) convertCurrency();
    },
    [currencyValue, currencyToConvertFrom],
    300,
    true
  );

  if (!withCurrencyConverter) {
    return (
      <FormControl
        size="sm"
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        dir={'ltr'}
        disabled={disabled}
        className={`mx-0 text-${isRTL ? 'end' : 'start'} ${className}`}
        {...props}
      />
    );
  }

  return (
    <>
      <div className="">
        <div className="d-flex align-items-center justify-content-end bg-dark">
          {showCurrencyConverter && (
            <>
              <div className=" text-white ">
                <ReactSelect
                  options={currencySelectOptions}
                  onChange={selectedOption => setCurrencyToConvertFrom(selectedOption.value)}
                  value={currencySelectOptions.find(option => option.value === currencyToConvertFrom)}
                  classNamePrefix="select"
                  className={`p-0 w-100 px-1`}
                  menuPortalTarget={document.body}
                  formatOptionLabel={(option, { context }) => {
                    // Display only the symbol (value) when showing the selected value
                    if (context === 'value') {
                      return <span className="fw-bold text-white px-2">{option.value}</span>;
                    }

                    // Add a divider after EUR when showing in the menu
                    if (option.value === 'EUR' && context === 'menu') {
                      return (
                        <div style={{ position: 'relative' }}>
                          <span className="fw-bold">{option.value} </span> ({option.label})
                          <div
                            className="bg-secondary-dark mt-2"
                            style={{
                              height: '2px',
                              background: '#000'
                            }}
                          />
                        </div>
                      );
                    }
                    return (
                      <>
                        <span className="fw-bold">{option.value} </span> ({option.label})
                      </>
                    ); // Normal display for other options
                  }}
                  isDisabled={disabled}
                  styles={{
                    control: base => ({
                      ...base,
                      border: 0,
                      minHeight: 0,
                      background: 'transparent',
                      fontSize: 12,
                      padding: 0,
                      fontWeight: 'bold'
                    }),
                    menu: base => ({
                      ...base,
                      width: '200px',
                      zIndex: 10000,
                      position: 'absolute'
                    }),
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                    option: base => ({ ...base, padding: 5, textAlign: 'start', fontSize: 13 }),
                    dropdownIndicator: base => ({
                      paddingTop: 0,
                      paddingBottom: 0,
                      marginLeft: 5,
                      marginRight: 5
                    }),
                    valueContainer: base => ({ ...base, padding: 0 }),
                    indicatorSeparator: base => ({
                      display: 'none' // Hides the separator next to the arrow
                    }),
                    indicatorsContainer: base => ({
                      padding: 0 // Removes any padding to reduce space taken
                    })
                  }}
                />
              </div>
              <div className={`flex-grow-1 text-${isRTL ? 'start' : 'end'}`}>
                <span className="fw-bold text-white px-2 mid">Shekels</span>
                <X className="hover-light text-white" size={18} onClick={() => setShowCurrencyConverter(false)} />
              </div>
            </>
          )}
        </div>
      </div>
      <div className="d-flex align-items-center border">
        {showCurrencyConverter && (
          <>
            <CurrencyInput
              dir={'ltr'}
              onChange={value => {
                setCurrencyValue(value);
              }}
              value={currencyValue}
              placeholder={translate('value_in_currency', { currency: currencyToConvertFrom })}
              disabled={disabled}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  if (disabled || !currencyValue || fetchingConvertedValue) return;

                  convertCurrency();
                }
              }}
            />

            <div className="mx-2">
              {fetchingConvertedValue ? <CircularProgressBar size={1.3} /> : <ArrowLeftRight size={12} />}
            </div>
          </>
        )}
        <InputGroup dir="ltr">
          <FormControl
            size="sm"
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            dir={'ltr'}
            disabled={disabled}
            className={`mx-0 text-${isRTL ? 'end' : 'start'} ${className}`}
            {...props}
          />
          {withCurrencyConverter && !showCurrencyConverter && (
            <Button
              variant={!showCurrencyConverter ? 'outline-dark' : 'dark'}
              onClick={() => setShowCurrencyConverter(!showCurrencyConverter)}
              disabled={disabled}
              size={'sm'}
              className="mx-0"
            >
              <CurrencyExchange />
            </Button>
          )}
        </InputGroup>
      </div>
    </>
  );
};

export default CurrencyInput;

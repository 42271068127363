import { snakeCase } from 'lodash';
import React from 'react';
import { ArrowDownCircle, ArrowUpCircle } from 'react-bootstrap-icons';
import useLocalization from '../../hooks/useLocalization';

const CollapsibleSection = ({ sectionName, isCollapsed, onShowHideClick, className, children }) => {
  const { translate } = useLocalization();
  return (
    <div className={`border rounded mb-2 ${className}`}>
      <div className="d-flex align-items-center justify-content-between py-2 px-2 bg-light rounded">
        <h6 className="text-dark smallFont mb-0 fw-bold">
          {translate(snakeCase(sectionName)) || sectionName || 'Fields'}
        </h6>
        <h6 className="d-inline-block tiny text-primary mb-0  hover-light" onClick={() => onShowHideClick(sectionName)}>
          {isCollapsed ? (
            <>
              {translate('show')}
              <ArrowDownCircle className="mx-1" size={13} />
            </>
          ) : (
            <>
              {translate('hide')} <ArrowUpCircle className="mx-1" size={13} />
            </>
          )}
        </h6>
      </div>
      {!isCollapsed && (
        <>
          <hr className="mt-0 mb-1" />
          {children}
        </>
      )}
    </div>
  );
};

export default CollapsibleSection;

import { uniqueId } from 'lodash';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Badge, Button, Card, Container, FormControl, Table } from 'react-bootstrap';
import {
  ArrowClockwise,
  ArrowsAngleContract,
  ArrowsAngleExpand,
  Check,
  EyeSlash,
  Files,
  Folder,
  Plus,
  Trash
} from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS, PINUI_BINUI_CALCULATOR_KEY } from '../../helpers/constants';
import { scrollToEnd } from '../../helpers/global';
import { isRtl } from '../../helpers/localization';
import useAuth from '../../hooks/useAuth';
import useDebouncedEffect from '../../hooks/useDebouncedEffect';
import { formatCurrency } from '../admin/manage-users/customer/strategies/helper';
import AlertModal from '../common/AlertModal';
import DataRow from './ApartmentDataRow';
import SectionRow from './ApartmentSectionRow';
import CustomerSelectModal from './CustomerSelectModal';
import { getAllPinuiBinuiIrrFields, getInformationalFields, getPinuiBinuiIrrFields } from './constants';
import { combinedIRRFormula, computeTargetPrice } from './goalSeekerPinuiBinui';
import { getInitialValues, getParsedValues, getPercentageValues, updateApartmentValues } from './helpers';

const getServerFieldsOfAppartments = (app, isCollapsed, isArchived) => {
  const {
    targetIrr,
    inputPrice,
    askedPrice,
    areaInSquareMeters,
    renovationCost,
    valueAddedByRenovation,
    appraisedValue,
    maximalMortgageAvailable,
    onlyApartmentWhileBuying,
    onlyApartmentWhileSelling,
    mortgagePercentage,
    expectedAppreciation,
    realEstateHoldingPeriodInYears,
    timeToDeliveryAfterBuyingInMonths,
    monthlyRent,
    rentDuringContruction,
    rentPostConstruction,
    apartmentSizeAfterReconstruction,
    valueOfApartmentToday,
    estimatedTimeForDemolition,
    estimatedTimeForFinishingContruction,
    irrInNoReconstruction,
    realtorCostsInPercentageTermsPreVat,
    vat,
    relatedCostsLawyerAppraiserEtc,
    mortgageInterest,
    mortgageTimeToMaturityInMonths,
    taxOnRent,
    rentIncrease,
    files,
    //general informational fields
    additionalComments,
    address,
    apartmentFloorNumber,
    apartmentSize,
    city,
    // howEasyToFindRenters,
    // howGoodAreCurrentRenters,
    // howGoodIsLocation,
    // howWellMaintained,
    isApartmentProperlyRegistered,
    isApartmentRegisteredOnSellerName,
    isThereBalcony,
    isThereConstructionWithoutPermit,
    isThereElevator,
    isThereGarden,
    isThereParking,
    isThereSafeRoom,
    numberOfFloorInBuilding,
    numberOfRooms,
    rating,
    realtorSellerName,
    wanterPricePerSQM,
    whoIsTypicalRenter,
    neighbourhoodInfo,
    signaturePercentage,
    developerWebsite,
    developerCompany,
    numOfAirDirections,
    dangerousBuilding,
    physicalCondition,
    parking,
    transportation,
    importedFrom,
    requiredCashForDeal,
    developerLoan,
    linearPayment,
    developerCompanyScale,
    buyingDecisionScale,
    apartmentLooks,
    largeRenovation,
    earlyDeal,
    noElevator,
    complicatedDeals
  } = app;

  return {
    targetIrr,
    inputPrice,
    askedPrice,
    areaInSquareMeters,
    renovationCost,
    valueAddedByRenovation,
    appraisedValue,
    maximalMortgageAvailable,
    onlyApartmentWhileBuying,
    onlyApartmentWhileSelling,
    mortgagePercentage,
    expectedAppreciation,
    realEstateHoldingPeriodInYears,
    timeToDeliveryAfterBuyingInMonths,
    monthlyRent,
    realtorCostsInPercentageTermsPreVat,
    vat,
    relatedCostsLawyerAppraiserEtc,
    mortgageInterest,
    mortgageTimeToMaturityInMonths,
    taxOnRent,
    rentIncrease,
    files,
    additionalComments,
    address,
    apartmentFloorNumber,
    apartmentSize,
    city,
    // howEasyToFindRenters,
    // howGoodAreCurrentRenters,
    // howGoodIsLocation,
    // howWellMaintained,
    isApartmentProperlyRegistered,
    isApartmentRegisteredOnSellerName,
    isThereBalcony,
    isThereConstructionWithoutPermit,
    isThereElevator,
    isThereGarden,
    isThereParking,
    isThereSafeRoom,
    numberOfFloorInBuilding,
    numberOfRooms,
    rating,
    realtorSellerName,
    wanterPricePerSQM,
    whoIsTypicalRenter,
    rentDuringContruction,
    rentPostConstruction,
    apartmentSizeAfterReconstruction,
    valueOfApartmentToday,
    estimatedTimeForDemolition,
    estimatedTimeForFinishingContruction,
    irrInNoReconstruction,
    isCollapsed,
    isArchived,
    neighbourhoodInfo,
    signaturePercentage,
    developerWebsite,
    developerCompany,
    numOfAirDirections,
    dangerousBuilding,
    physicalCondition,
    parking,
    transportation,
    importedFrom: importedFrom?._id,
    requiredCashForDeal,
    developerLoan,
    linearPayment,
    developerCompanyScale,
    buyingDecisionScale,
    apartmentLooks,
    largeRenovation,
    earlyDeal,
    noElevator,
    complicatedDeals
  };
};

const PinuiBinuiIRR = ({
  customer,
  onCustomerUpdate,
  fromCustomerPortal,
  adminCompTable,
  readOnlyMode,
  selectMode,
  onSelectAppartment,
  addApartmentMode,
  onChange,
  assigneUsers
}) => {
  const [appartments, setAppartments] = useState([]);
  const [targetPrices, setTargetPrices] = useState({});
  const [updatingAppartments, setUpdatingAppartments] = useState(false);
  const [collapsedApartments, setCollapsedApartments] = useState([]);
  const [apartmentCopyModalMeta, setApartmentCopyModalMeta] = useState(null);
  const [relatedApartments, setRelatedApartments] = useState([]);

  const { translate, langCode } = useContext(LocalizeContext);
  const { user } = useAuth();

  const apparmentsToShow = useMemo(() => appartments.filter(a => addApartmentMode || !a.isArchived), [appartments]);

  const irrFields = useMemo(() => getPinuiBinuiIrrFields(translate), [langCode]);
  const irrFieldsCount = useMemo(
    () => Object.keys(irrFields).reduce((acc, sectionName) => irrFields[sectionName].length + acc, 0),
    [irrFields]
  );

  const infoFields = useMemo(
    () => getInformationalFields(translate, PINUI_BINUI_CALCULATOR_KEY, addApartmentMode, assigneUsers),
    [langCode, addApartmentMode, assigneUsers]
  );
  const infoFieldsCount = useMemo(
    () => Object.keys(infoFields).reduce((acc, sectionName) => infoFields[sectionName].length + acc, 0),
    [infoFields]
  );
  const totalFieldsCount = useMemo(() => irrFieldsCount + infoFieldsCount + 2, [infoFieldsCount, irrFieldsCount]);

  const allFields = useMemo(() => getAllPinuiBinuiIrrFields(translate, addApartmentMode, assigneUsers, user), [
    langCode,
    addApartmentMode,
    assigneUsers,
    user
  ]);
  const isRtlLanguage = useMemo(() => isRtl(langCode), [langCode]);

  const tableContainerRef = useRef();
  const [newApartmentAdded, setNewApartmentAdded] = useState(false);
  const [updatedApartment, setUpdatedApartment] = useState('all');

  useEffect(() => {
    let initialAppartment =
      customer?.pinuiBinuiIrrAppartments?.length > 0
        ? customer?.pinuiBinuiIrrAppartments.map(a => ({
            ...a,
            id: uniqueId(),
            ...getCalculatedValues(a),
            ...getPercentageValues(a, allFields)
          }))
        : [getInitialValues(allFields, adminCompTable)];

    setAppartments(initialAppartment);
    setCollapsedApartments(
      addApartmentMode ? [] : initialAppartment?.filter(appartment => appartment?.isCollapsed)?.map(a => a?.id)
    );
  }, [customer]);

  const fetchRelatedApartments = async () => {
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.GET_APPARTMENTS,
      requestBody: {
        relatedToCustomer: customer?._id,
        filter: [{ key: 'type', value: 'pinui-binui' }]
      }
    });

    if (response) {
      setRelatedApartments(response.results);
    }
  };

  useEffect(() => {
    if (customer && !selectMode && !addApartmentMode && !readOnlyMode) fetchRelatedApartments();
  }, []);

  const onInputValueChange = (appId, objectKey, currentInputType, newValue, otherInfo) => {
    const apartment = appartments.find(a => a.id === appId);
    updateApartmentValues(allFields, apartment, objectKey, currentInputType, newValue, otherInfo);
    setAppartments(appartments.map(app => (app.id === appId ? { ...app, ...getCalculatedValues(app) } : app)));
    setUpdatedApartment(appId);
  };

  const getCalculatedValues = apartment => {
    const parsedValues = getParsedValues(allFields, apartment, adminCompTable);
    return combinedIRRFormula(parsedValues.inputPrice, {
      ...parsedValues,
      useNewIRR: true
    });
  };

  const onTargetIRRChange = (appId, e) => {
    setAppartments(appartments.map(app => (app.id === appId ? { ...app, targetIrr: e.target.value } : app)));
    setUpdatedApartment(appId);
  };

  useDebouncedEffect(
    () => {
      if (!updatedApartment) return;

      const newTargetPrices = appartments.reduce((acc, apartment) => {
        if (updatedApartment !== 'all' && updatedApartment !== apartment.id) {
          //return previous value, do not recalculate
          return { ...acc, [apartment.id]: targetPrices[apartment.id] };
        }

        if (!apartment.targetIrr) return { ...acc, [apartment.id]: 0 };

        const parsedValues = getParsedValues(allFields, apartment, adminCompTable);
        const targetIRRNum = Number(apartment.targetIrr) / 100;

        return {
          ...acc,
          [apartment.id]: computeTargetPrice(parsedValues.inputPrice, targetIRRNum, {
            ...parsedValues,
            useNewIRR: true
          })
        };
      }, {});

      setTargetPrices(newTargetPrices);
      setUpdatedApartment();
    },
    [appartments, updatedApartment],
    200
  );

  useEffect(() => {
    if (newApartmentAdded) {
      scrollToEnd(tableContainerRef.current);
      setNewApartmentAdded(false); // Reset the flag
    }
  }, [appartments, newApartmentAdded]); // Effect runs when apartments or newApartmentAdded changes

  const onAddAppartmentClick = () => {
    appartments.push(getInitialValues(allFields, adminCompTable));
    setAppartments([...appartments]);
    setNewApartmentAdded(true);
  };

  const onDeleteAppartmentClick = appId => {
    appartments.splice(
      appartments.findIndex(a => a.id === appId),
      1
    );
    setAppartments([...appartments]);
  };

  const onSaveAppartmentsClick = async silentSave => {
    if (!silentSave) {
      toast.info('Updating appartments...');
      setUpdatingAppartments(true);
    }

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_UPDATE,
      requestBody: {
        pinuiBinuiIrrAppartments: appartments.map(appartment =>
          getServerFieldsOfAppartments(
            { ...appartment, ...getParsedValues(allFields, appartment, adminCompTable, false) },
            collapsedApartments?.includes(appartment.id),
            appartment.isArchived
          )
        ),
        _id: customer['_id']
      }
    });

    setUpdatingAppartments(false);
    if (error) {
      if (!silentSave) toast.error(error);
      return;
    }

    if (!silentSave) toast.success('Updated successfully!');
    onCustomerUpdate(response);
  };

  const [archiveAppartmentMeta, setArchiveAppartmentMeta] = useState(null);

  const onArchiveAppartmentClick = appartment => {
    setArchiveAppartmentMeta({ appartment });
  };

  const onArchiveAppartment = async () => {
    try {
      let { appartment } = archiveAppartmentMeta;
      setArchiveAppartmentMeta({ ...archiveAppartmentMeta, showProgress: true });
      const { response, error } = await makeApiRequests({
        endpoint: ENDPOINTS.USERS_UPDATE,
        requestBody: {
          pinuiBinuiIrrAppartments: appartments.map(app =>
            getServerFieldsOfAppartments(
              { ...app, ...getParsedValues(allFields, app, adminCompTable, false) },
              collapsedApartments?.includes(app.id),
              app.id !== appartment?.id ? app.isArchived : true
            )
          ),
          _id: customer['_id']
        }
      });

      if (error) {
        toast.error(error);
        return;
      }
      if (response) {
        toast.success('Archived successfully!');
        onCustomerUpdate(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setArchiveAppartmentMeta(null);
    }
  };

  const onResetApartmentClick = appId => {
    const linkedApartment = { ...appartments.find(a => a.id === appId)?.importedFrom };
    ['_id', 'importedFrom', 'id'].forEach(key => {
      delete linkedApartment[key];
    });

    if (linkedApartment) {
      setAppartments(
        appartments.map(a => {
          if (a.id === appId) {
            const resetApartment = { ...a, ...linkedApartment };
            return { ...resetApartment, ...getCalculatedValues(resetApartment) };
          }

          return a;
        })
      );
      setUpdatedApartment(appId);
    }
  };

  const onCollapseApartmentClick = appId => {
    if (collapsedApartments.includes(appId)) {
      collapsedApartments.splice(collapsedApartments.indexOf(appId), 1);
    } else {
      collapsedApartments.push(appId);
    }

    setCollapsedApartments([...collapsedApartments]);
  };

  const onCopyApartmentClick = appId => {
    onSaveAppartmentsClick(true);
    const apartmentToCopy = appartments.find(a => a.id === appId);
    setApartmentCopyModalMeta({ apartmentToCopy });
  };

  const onApartmentCopySubmit = async () => {
    const { apartmentToCopy, selectedCustomer } = apartmentCopyModalMeta;

    toast.info('Updating appartments...');
    setApartmentCopyModalMeta(meta => ({ ...meta, showProgress: true }));

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_COPY_APARTMENT,
      requestBody: {
        apartmentCalculatorKey: 'pinuiBinuiIrrAppartments',
        apartment: getServerFieldsOfAppartments({
          ...apartmentToCopy,
          ...getParsedValues(allFields, apartmentToCopy, adminCompTable, false)
        }),
        _id: selectedCustomer['_id']
      }
    });

    setApartmentCopyModalMeta(meta => ({ ...meta, showProgress: false }));

    if (error) {
      toast.error(error);
      return;
    }

    toast.success('Copied successfully!');
    setApartmentCopyModalMeta(null);
    onCustomerUpdate(response);
  };

  const handleSelectAppartment = apartment => {
    const parsedValues = getParsedValues(allFields, apartment, adminCompTable);
    onSelectAppartment({ ...parsedValues, ...combinedIRRFormula(parsedValues.inputPrice, parsedValues) });
  };

  useEffect(() => {
    if (addApartmentMode && onChange) {
      const apartmentToReturn = appartments[0] || {};
      onChange({ ...apartmentToReturn, ...getParsedValues(allFields, apartmentToReturn, adminCompTable, false) });
    }
  }, [onChange, addApartmentMode, allFields, adminCompTable, appartments]);

  const onImportApartment = app => {
    const newApartment = {
      ...app,
      id: uniqueId(),
      ...getCalculatedValues(app),
      ...getPercentageValues(app, allFields),
      importedFrom: app
    };
    appartments.push(newApartment);
    setAppartments([...appartments]);
    setNewApartmentAdded(true);
    setUpdatedApartment(newApartment.id);
  };

  const showImportApartment = useMemo(
    () =>
      !addApartmentMode &&
      !selectMode &&
      !readOnlyMode &&
      customer &&
      relatedApartments.filter(app => !appartments.map(a => a.importedFrom?._id).includes(app._id)).length > 0,
    [addApartmentMode, selectMode, readOnlyMode, customer, relatedApartments]
  );
  return (
    <>
      <Container fluid className="h-100 p-1 calculator text-start">
        {showImportApartment && (
          <div style={{ height: 60 }} className="p-1 border rounded mb-1">
            <h6 className="mid mb-1 fw-bold">You may want to import the following apartments to the calculator:</h6>
            <div className="d-flex overflow-auto ">
              {relatedApartments
                .filter(app => !appartments.map(a => a.importedFrom?._id).includes(app._id))
                .map(app => (
                  <Badge key={app._id} className="mx-1 hover mt-1" onClick={() => onImportApartment(app)}>
                    {app.address ? `${app.address}-` : ''}
                    {app.inputPrice}
                  </Badge>
                ))}
            </div>
          </div>
        )}
        <Card className={`${addApartmentMode && 'border-0'}`}>
          {!addApartmentMode && (
            <Card.Header>
              <div className="d-flex">
                <div className={`flex-grow-1 text-${isRtlLanguage ? 'end' : 'start'}`}>{translate('pinui_binui')}</div>
                <div>
                  {!readOnlyMode && !selectMode && (
                    <>
                      <Button
                        disabled={updatingAppartments || readOnlyMode}
                        variant="outline-primary"
                        size="sm"
                        className="px-1 py-0 "
                        onClick={onAddAppartmentClick}
                      >
                        <Plus size={10} />
                        <span className="align-middle mx-1">{translate('add')}</span>
                      </Button>
                      {customer && (
                        <Button
                          disabled={updatingAppartments}
                          variant="outline-success"
                          size="sm"
                          className="px-1 py-0 mx-1"
                          onClick={() => onSaveAppartmentsClick()}
                        >
                          <Check size={10} />
                          <span className="align-middle mx-1">{translate('save')}</span>
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Card.Header>
          )}

          <Card.Body
            ref={tableContainerRef}
            className="p-0 scrollbar-visible overflow-auto"
            style={{ height: addApartmentMode ? '100%' : showImportApartment ? 'calc(80vh - 60px)' : '80vh' }}
          >
            <Table bordered hover className="mid">
              <thead>
                <tr className="bg-primary text-white">
                  <th className="calc-column"></th>
                  {apparmentsToShow?.map((app, index) => (
                    <th
                      key={app.id}
                      className={`p-1 text-end ${!collapsedApartments.includes(app.id) ? 'calc-column' : ''}`}
                    >
                      <div className="d-flex px-1">
                        {!collapsedApartments.includes(app.id) && (
                          <div className={`flex-grow-1 mx-1 text-${isRtlLanguage ? 'end' : 'start'}`}>
                            <FormControl
                              autoComplete="off"
                              disabled={readOnlyMode || selectMode}
                              tabIndex={index * totalFieldsCount + 1}
                              size="sm"
                              style={{ fontSize: 10 }}
                              className="p-1"
                              value={app.address}
                              onChange={e => onInputValueChange(app.id, 'address', 'text', e.target.value)}
                            />
                          </div>
                        )}
                        {!addApartmentMode && (
                          <div className="d-flex">
                            <Button
                              variant="danger"
                              size="sm"
                              className="px-1 py-0"
                              onClick={() => onCollapseApartmentClick(app.id)}
                            >
                              {collapsedApartments.includes(app.id) ? (
                                <ArrowsAngleExpand size={12} />
                              ) : (
                                <ArrowsAngleContract size={12} />
                              )}
                            </Button>
                            {selectMode && (
                              <Button
                                variant="dark"
                                size="sm"
                                className="px-1 py-0 mx-1"
                                onClick={() => handleSelectAppartment(app)}
                              >
                                Select
                              </Button>
                            )}
                            {!collapsedApartments.includes(app.id) && !readOnlyMode && !selectMode && (
                              <>
                                {appartments.length > 1 && (
                                  <Button
                                    variant="danger"
                                    size="sm"
                                    disabled={readOnlyMode}
                                    className="px-1 py-0 mx-1"
                                    onClick={() => onDeleteAppartmentClick(app.id)}
                                  >
                                    <Trash size={12} />
                                  </Button>
                                )}

                                {customer && (
                                  <>
                                    <Button
                                      variant="secondary"
                                      size="sm"
                                      className="px-1 py-0 mx-1"
                                      onClick={() => onArchiveAppartmentClick(app)}
                                    >
                                      <EyeSlash size={12} />
                                    </Button>
                                    <Button
                                      variant="dark"
                                      size="sm"
                                      className="px-1 py-0 mx-1"
                                      onClick={() => onCopyApartmentClick(app.id)}
                                    >
                                      <Files size={12} />
                                    </Button>
                                    {app.importedFrom && (
                                      <>
                                        <Button
                                          variant="dark"
                                          size="sm"
                                          className="px-1 py-0 mx-1"
                                          onClick={() => onResetApartmentClick(app.id)}
                                        >
                                          <ArrowClockwise size={12} />
                                        </Button>
                                        {app.importedFrom?.driveFolder && (
                                          <Button
                                            variant="dark"
                                            size="sm"
                                            className="px-1 py-0 mx-1"
                                            onClick={() => {
                                              let fileURL = `https://drive.google.com/drive/u/0/folders/${app.importedFrom.driveFolder}`;
                                              window.open(fileURL, '_blank');
                                            }}
                                          >
                                            <Folder size={12} />
                                          </Button>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.keys(irrFields).map((sectionName, index) => (
                  <React.Fragment>
                    <SectionRow
                      sectionName={sectionName}
                      span={apparmentsToShow.length + 1}
                      isRtlLanguage={isRtlLanguage}
                    />
                    {irrFields[sectionName].map(rowDetails => (
                      <DataRow
                        {...rowDetails}
                        allFields={allFields}
                        appartments={apparmentsToShow}
                        onValueChange={onInputValueChange}
                        isRtlLanguage={isRtlLanguage}
                        collapsedApartments={collapsedApartments}
                        tabIndex={index + 1}
                        totalFieldsCount={totalFieldsCount}
                        readOnlyMode={readOnlyMode || selectMode}
                      />
                    ))}
                  </React.Fragment>
                ))}

                <>
                  <SectionRow
                    sectionName={translate('target')}
                    span={apparmentsToShow.length + 1}
                    isRtlLanguage={isRtlLanguage}
                  />
                  <tr>
                    <td className="p-1 bg-white calc-column">
                      <b>{translate('target_irr')}</b>
                    </td>
                    {apparmentsToShow?.map((app, appIndex) => (
                      <td
                        key={app.id}
                        className={`p-1 text-${isRtlLanguage ? 'end' : 'start'} ${
                          !collapsedApartments.includes(app.id) ? 'calc-column' : ''
                        }`}
                      >
                        {!collapsedApartments.includes(app.id) && (
                          <FormControl
                            autoComplete="off"
                            tabIndex={appIndex * totalFieldsCount + irrFieldsCount + 1}
                            type="number"
                            size="sm"
                            disabled={readOnlyMode || selectMode}
                            value={app.targetIrr}
                            onChange={e => onTargetIRRChange(app.id, e)}
                          />
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr className="bg-primary no-pointer-events text-white large">
                    <td className="p-2 calc-column">
                      <b>{translate('target_price')}</b>
                    </td>
                    {apparmentsToShow?.map((app, index) => (
                      <td
                        key={app.id}
                        className={`p-2 text-${isRtlLanguage ? 'end' : 'start'} ${
                          !collapsedApartments.includes(app.id) ? 'calc-column' : ''
                        }`}
                      >
                        {!collapsedApartments.includes(app.id) ? formatCurrency(targetPrices[app.id]) : ''}
                      </td>
                    ))}
                  </tr>
                </>

                {Object.keys(infoFields).map((sectionName, index) => (
                  <React.Fragment>
                    <SectionRow
                      sectionName={sectionName}
                      span={apparmentsToShow.length + 1}
                      isRtlLanguage={isRtlLanguage}
                    />
                    {infoFields[sectionName].map(rowDetails => (
                      <DataRow
                        {...rowDetails}
                        allFields={allFields}
                        appartments={apparmentsToShow}
                        onValueChange={onInputValueChange}
                        isRtlLanguage={isRtlLanguage}
                        collapsedApartments={collapsedApartments}
                        tabIndex={irrFieldsCount + 2 + index}
                        totalFieldsCount={totalFieldsCount}
                        readOnlyMode={readOnlyMode || selectMode}
                      />
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
      {customer && (
        <>
          <AlertModal
            show={Boolean(archiveAppartmentMeta)}
            alertText={translate('are_you_sure_archive')}
            showProgress={archiveAppartmentMeta?.showProgress}
            continueButtonText={translate('archive')}
            onDismissClick={() => setArchiveAppartmentMeta(null)}
            onHide={() => setArchiveAppartmentMeta(null)}
            onContinueClick={onArchiveAppartment}
          />
          <CustomerSelectModal
            show={Boolean(apartmentCopyModalMeta)}
            apartmentToCopy={apartmentCopyModalMeta?.apartmentToCopy}
            showProgress={apartmentCopyModalMeta?.showProgress}
            onHide={() => setApartmentCopyModalMeta(null)}
            currentCustomer={customer}
            selectedCustomer={apartmentCopyModalMeta?.selectedCustomer}
            onCustomerSelect={selectedCustomer => setApartmentCopyModalMeta(meta => ({ ...meta, selectedCustomer }))}
            onSubmit={onApartmentCopySubmit}
          />
        </>
      )}
    </>
  );
};

export default PinuiBinuiIRR;

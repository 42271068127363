import React, { useMemo } from 'react';
import { Button, FormControl, FormSelect } from 'react-bootstrap';
import { PlusCircle, Trash } from 'react-bootstrap-icons';
import CurrencyInput from '../../components/common/CurrencyInput';
import UnderlineButton from '../../components/common/UnderlineButton';
import AppTable from '../../components/common/app-table/AppTable';
import { monthSmall } from '../../helpers/constants';
import { findIfUserIsInterestedInCourseAndOfSpecifiedLevel } from '../../helpers/global';
import useAuth from '../../hooks/useAuth';
import useLocalization from '../../hooks/useLocalization';

/** value,amount,  year, month,  vestingYear, vestingMonth*/
const getRSUHeaders = (translate, strategyYears) => [
  {
    name: translate('month'),
    key: 'month',
    type: 'dropdown',
    options: monthSmall.map((m, i) => ({ option: m, value: i }))
  },
  {
    name: translate('year'),
    key: 'year',
    type: 'number',
    options: strategyYears.map(y => ({ option: y, value: y }))
  },
  {
    name: translate('stock_amount'),
    type: 'number',
    format: 'currency',
    key: 'amount'
  },
  {
    name: translate('stock_value'),
    type: 'number',
    format: 'currency',
    key: 'value',
    withCurrencyConverter: true,
    minWidth: 200
  },
  {
    name: translate('vesting_month'),
    key: 'vestingMonth',
    type: 'dropdown',
    options: monthSmall.map((m, i) => ({ option: m, value: i }))
  },
  {
    name: translate('vesting_year'),
    key: 'vestingYear',
    type: 'number',
    options: strategyYears.map(y => ({ option: y, value: y }))
  }
];

const RSUGrants = ({
  rsuGrants = [],
  onRSUGrantsChange,
  onRSUGrantsAddClick,
  onRSUGrantsDeleteClick,
  strategyYears = []
}) => {
  const { langCode, translate } = useLocalization();
  const rsuHeaders = useMemo(() => getRSUHeaders(translate, strategyYears), [langCode, strategyYears]);
  const { user } = useAuth();

  const onChange = (payment, field, type, format, value) => {
    payment[field] = value;
    onRSUGrantsChange && onRSUGrantsChange(rsuGrants.map(p => (p.id === payment.id ? { ...payment } : p)));
  };

  return (
    <>
      <AppTable className="mb-0 no-sticky">
        <thead>
          <tr className="text-white bg-dark">
            {rsuHeaders.map(({ name, minWidth = 100 }) => (
              <th style={{ minWidth }} key={name}>
                {name}
              </th>
            ))}
            {!findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1]) && (
              <th style={{ minWidth: 70 }}>{translate('actions')}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {rsuGrants.length > 0 ? (
            <>
              {rsuGrants.map(payment => (
                <tr key={payment.id}>
                  {rsuHeaders.map(({ name, type, options, key, format, withCurrencyConverter }) => (
                    <td valign="bottom" className="bg-white p-0" key={name}>
                      {type === 'dropdown' && (
                        <FormSelect
                          className="mb-0"
                          autoComplete="off"
                          size="sm"
                          value={payment[key]}
                          onChange={e => {
                            onChange(payment, key, type, format, e.target.value);
                          }}
                        >
                          {options.map(o => (
                            <option key={o.value} value={o.value}>
                              {o.option}
                            </option>
                          ))}
                        </FormSelect>
                      )}
                      {type === 'number' && (
                        <>
                          {format === 'currency' ? (
                            <CurrencyInput
                              size="sm"
                              className="mb-0"
                              autoComplete="off"
                              value={payment[key]}
                              returnZeroByDefault={false}
                              onChange={value => {
                                //call on change
                                onChange(payment, key, type, format, value);
                              }}
                              onCurrencyConvert={value => {
                                const currencyKey = `${key}CurrencyConversionInfo`;
                                const currencyValue = {
                                  conversionCurrency: value.fromCurrency,
                                  conversionRate: value.conversionRate,
                                  conversionAmount: value.amount
                                };
                                onChange(payment, currencyKey, type, format, currencyValue);
                              }}
                              currencyConversionInfo={
                                payment?.[`${key}CurrencyConversionInfo`]
                                  ? {
                                      fromCurrency: payment?.[`${key}CurrencyConversionInfo`].conversionCurrency,
                                      conversionRate: payment?.[`${key}CurrencyConversionInfo`].conversionRate,
                                      amount: payment?.[`${key}CurrencyConversionInfo`].conversionAmount
                                    }
                                  : null
                              }
                              withCurrencyConverter={withCurrencyConverter}
                            />
                          ) : (
                            <FormControl
                              className="mb-0"
                              autoComplete="off"
                              type="number"
                              size="sm"
                              value={payment[key]}
                              onChange={e => {
                                let inputValue = Number(e.target.value.trim() || 0);
                                if (Number.isNaN(inputValue)) return;
                                //call on change
                                onChange(payment, key, type, format, inputValue);
                              }}
                            />
                          )}
                        </>
                      )}
                    </td>
                  ))}
                  {!findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1]) && (
                    <td className="p-0">
                      <div className="text-center">
                        <Button
                          variant="outline-danger"
                          className="px-1 py-0 "
                          onClick={() => onRSUGrantsDeleteClick(payment)}
                        >
                          <Trash size={9} />
                        </Button>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={rsuHeaders.length + 1}>
                <h6 className="text-muted text-center mb-0 smallFont">{translate('nothing_to_show')}</h6>
              </td>
            </tr>
          )}
        </tbody>
      </AppTable>
      <div className="text-end">
        {!findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1]) && (
          <UnderlineButton
            fontSize="smallFont"
            variant="success"
            Icon={PlusCircle}
            text={translate('new')}
            onClick={onRSUGrantsAddClick}
          />
        )}
      </div>
    </>
  );
};

export default RSUGrants;

import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Button, Card, Col, Container, FormControl, FormSelect, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../helpers/api';
import {
  ENDPOINTS,
  PAPER_CALCULATOR_KEY,
  IRR_CALCULATOR_KEY,
  PINUI_BINUI_CALCULATOR_KEY,
  RSU_OPTIONS_KEY,
  monthSmall
} from '../../helpers/constants';
import CustomerCard from '../admin/manage-users/customer/CustomerCard';
import ComparisonChart from '../admin/manage-users/customer/strategies/charts/ComparisonChart';
import Loader from '../common/Loader';
import SlidingSideBar from '../common/SlidingSideBar/SlidingSideBar';
import RealEstateIRR from '../calculators/Irr';
import PinuiBinuiIRR from '../calculators/PinuiBinui';
import PaperApartmentIRR from '../calculators/PaperApartment';
import { LocalizeContext } from 'react-locale-language';
import RSUOptions from '../calculators/RSUOptionsCalculator';
import FileViewer from '../common/FileViewer';
import CustomerGeneralInfoSidebar from '../admin/manage-users/customer/CustomerGeneralInfoSidebar';
import useAuth from '../../hooks/useAuth';
import {
  addMonthAndYear,
  areMonthYearEqual,
  findDiffInNumberOfMonths,
  getUpdatedLinkedVariablesOfLiability
} from '../admin/manage-users/customer/strategies/helper';
import { cloneDeep } from 'lodash';
import HorizontalProgress from '../common/HorizontalProgress';
import CustomerShareSidebar from '../admin/manage-users/customer/CustomerShareSidebar';
import { validateEmail } from '../../helpers/global';

const UpdateStrategyTimeModal = ({
  show,
  onHide,
  showProgress,
  translate,
  strategyStartMonth,
  strategyStartYear,
  onStartMonthChange,
  onStartYearChange,
  strategyTotalYears,
  onTotalYearsChange,
  onStrategyStartDateUpdateSubmit
}) => {
  return (
    <Modal size="lg" show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton={!showProgress}>
        <Modal.Title>
          <h6 className="mb-0 d-inline-block">{translate('update_strategy_start_time')}</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto">
        <div className="mt-1 p-2">
          <Row>
            <Col xs={4}>
              <h6 className="mid">
                {translate('start_month')}
                <span className="text-danger">*</span>
              </h6>
              <FormSelect
                size="sm"
                id="startMonth"
                onChange={onStartMonthChange}
                value={strategyStartMonth}
                name="startMonth"
              >
                {monthSmall.map((month, index) => {
                  return (
                    <option value={index} key={index}>
                      {month}
                    </option>
                  );
                })}
              </FormSelect>
            </Col>
            <Col xs={4}>
              <h6 className="mid">
                {translate('start_year')}
                <span className="text-danger">*</span>
              </h6>
              <FormControl
                size="sm"
                type="number"
                id="startYear"
                onChange={onStartYearChange}
                value={strategyStartYear}
                name="startYear"
              />
            </Col>
            <Col xs={4}>
              <h6 className="mid">
                {translate('total_years')}
                <span className="text-danger">*</span>
              </h6>
              <FormControl
                size="sm"
                type="number"
                id="totalYears"
                onChange={onTotalYearsChange}
                value={strategyTotalYears}
                name="totalYears"
              />
            </Col>
          </Row>
          <h6 className="smallFont mt-2 text-muted">{translate('update_start_date_note_for_strategies')}</h6>
          <Row className="mt-4">
            <Col xs={12} className={'mt-2 mt-md-0'}>
              <Button size="sm" onClick={onStrategyStartDateUpdateSubmit} disabled={showProgress}>
                {translate('update')}
              </Button>
            </Col>
          </Row>
        </div>

        {showProgress && <HorizontalProgress text={`${translate('updating_strategy_time')}...`} />}
      </Modal.Body>
    </Modal>
  );
};

const ExampleUserNotFount = ({ translate }) => {
  return (
    <div className="w-100 vh-100 d-flex justify-content-center align-items-center">
      <div className=" border p-2 rounded">{translate('customer_not_found')}</div>
    </div>
  );
};
const ComparisonChartSidebar = ({ customer, show, onHide, translate, adminCompTable }) => (
  <SlidingSideBar
    fullScreen
    visible={show}
    onClose={onHide}
    showCloseButton
    title={`${translate('compare_strategies')} (${customer?.name || translate('no_name')})`}
  >
    {show && (
      <ComparisonChart
        strategies={customer.strategies}
        adminCompTable={adminCompTable}
        customerCompTable={customer['comp']}
      />
    )}
  </SlidingSideBar>
);

const IrrCalculatorSidebar = ({
  customer,
  show,
  onHide,
  onCustomerUpdate,
  adminCompTable,
  translate,
  readOnlyMode
}) => (
  <SlidingSideBar
    fullScreen
    visible={show}
    onClose={onHide}
    showCloseButton
    title={`${translate('irr_calculator')} (${customer?.name || translate('no_name')})`}
  >
    {show && (
      <RealEstateIRR
        adminCompTable={adminCompTable}
        fromCustomerPortal
        customer={customer}
        onCustomerUpdate={onCustomerUpdate}
        readOnlyMode={readOnlyMode}
      />
    )}
  </SlidingSideBar>
);

const PinuiBinuiCalculatorSidebar = ({
  customer,
  show,
  onHide,
  onCustomerUpdate,
  adminCompTable,
  translate,
  readOnlyMode
}) => (
  <SlidingSideBar
    fullScreen
    visible={show}
    onClose={onHide}
    showCloseButton
    title={`${translate('pinui_binui')} (${customer?.name || translate('no_name')})`}
  >
    {show && (
      <PinuiBinuiIRR
        adminCompTable={adminCompTable}
        fromCustomerPortal
        customer={customer}
        onCustomerUpdate={onCustomerUpdate}
        readOnlyMode={readOnlyMode}
      />
    )}
  </SlidingSideBar>
);

const PaperApartmentCalculatorSidebar = ({
  customer,
  show,
  onHide,
  onCustomerUpdate,
  adminCompTable,
  translate,
  readOnlyMode
}) => (
  <SlidingSideBar
    fullScreen
    visible={show}
    onClose={onHide}
    showCloseButton
    title={`${translate('paper_apartment')} (${customer?.name || translate('no_name')})`}
  >
    {show && (
      <PaperApartmentIRR
        adminCompTable={adminCompTable}
        fromCustomerPortal
        customer={customer}
        onCustomerUpdate={onCustomerUpdate}
        readOnlyMode={readOnlyMode}
      />
    )}
  </SlidingSideBar>
);

const RSUOptionsCalculatorSidebar = ({ customer, show, onHide, onCustomerUpdate, translate, adminCompTable }) => (
  <SlidingSideBar
    fullScreen
    visible={show}
    onClose={onHide}
    showCloseButton
    title={`${translate('rsu_options')} (${customer?.name || translate('no_name')})`}
  >
    {show && <RSUOptions adminCompTable={adminCompTable} customer={customer} onCustomerUpdate={onCustomerUpdate} />}
  </SlidingSideBar>
);

const CustomerPortal = ({ adminCompTable, readOnly, endPointToFetchCustomerFrom }) => {
  //? this is the active customer selected from list
  const [parentCustomer, setParentCustomer] = useState();
  const { viewAsUserMode } = useAuth();
  //? a parent customer can also have multiple child customer, a customer is selected from one of these
  //? this is the currently active customer from parent customer [this can also be the parent customer itself]
  const [activeCustomer, setActiveCustomer] = useState();

  const [showComparisonChart, setShowComparisonChart] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showIrrCalculator, setShowIrrCalculator] = useState(false);
  const [showPinuiBinuiCalculator, setShowPinuiBinuiCalculator] = useState(false);
  const [showPaperApartmentCalculator, setShowPaperApartmentCalculator] = useState(false);
  const [showRSUOptionsCalculator, setShowRSUOptionsCalculator] = useState(false);
  const [showFiles, setShowFiles] = useState(false);

  const { translate } = useContext(LocalizeContext);
  const allowShowingCustomerInfo = useMemo(() => parentCustomer?.showGeneralInfo, [parentCustomer]);
  const [showCustomerGeneralInfo, setShowCustomerGeneralInfo] = useState(false);

  const [updateStrategyTimeMeta, setUpdateStrategyTimeMeta] = useState(null);
  const [updatingStrategyTime, setUpdatingStrategyTime] = useState(false);

  const getCustomerStrategies = async () => {
    setLoading(true);
    try {
      const { response, error } = await makeApiRequests({
        endpoint: endPointToFetchCustomerFrom || ENDPOINTS.USERS_ME,
        method: 'GET',
        requestBody: undefined,
        doNotSendUpdatingAs: !viewAsUserMode ? true : false
      });
      setLoading(false);

      if (error) {
        toast.error(error);
        return;
      }

      setParentCustomer(response);
    } catch (e) {
      setLoading(false);
      toast.error('Something went wrong! Please try again');
      console.log(e);
    }
  };

  useEffect(() => {
    if (!parentCustomer) {
      setActiveCustomer();
      return;
    }

    if (!activeCustomer) {
      setActiveCustomer(parentCustomer);
      return;
    }

    const activeInAccessible = parentCustomer.accessibleUsers.find(c => c['_id'] === activeCustomer['_id']);

    setActiveCustomer(activeInAccessible || parentCustomer);
  }, [parentCustomer]);

  useEffect(() => {
    getCustomerStrategies();
  }, []);

  const onCompareStrategyClick = () => {
    setShowComparisonChart(true);
  };

  const onIrrCalculatorClick = () => {
    setShowIrrCalculator(true);
  };

  const onPinuiBinuiClick = () => {
    setShowPinuiBinuiCalculator(true);
  };

  const onPaperApartmentClick = () => {
    setShowPaperApartmentCalculator(true);
  };

  const onRSUOptionsClick = () => {
    setShowRSUOptionsCalculator(true);
  };

  const onActiveCustomerSwitched = customer => {
    setActiveCustomer(customer);
  };

  const onCustomerUpdate = newCustomer => {
    // update delegations and accessibles
    let updatedCustomer = { ...parentCustomer };

    if (parentCustomer._id === newCustomer._id) {
      updatedCustomer = { ...updatedCustomer, ...newCustomer };
    }

    ['accessibleUsers', 'delegations'].forEach(key => {
      updatedCustomer[key] = updatedCustomer[key].map(oc => {
        return oc._id === newCustomer._id ? newCustomer : oc;
      });
    });

    setParentCustomer(updatedCustomer);
  };

  const onViewFilesClick = () => {
    setShowFiles(true);
  };

  const onStrategyStartDateUpdateSubmit = async () => {
    const { strategyStartYear, strategyStartMonth, strategyTotalYears } = updateStrategyTimeMeta;

    if (!strategyStartYear) {
      return toast.error('Please provide a valid year!');
    }

    const yearInNumber = Number(strategyStartYear);
    const monthInNumber = Number(strategyStartMonth);

    if (isNaN(yearInNumber) || strategyStartYear.toString().length !== 4) {
      return toast.error('Please provide a valid year!');
    }

    const totalYearsInNumber = Number(strategyTotalYears);
    if (isNaN(totalYearsInNumber) || totalYearsInNumber < 1) {
      return toast.error('Total number of years must be atleast 1!');
    }

    setUpdatingStrategyTime(true);

    //set dates of all strategies to whatever is specified.
    //this means we set the initial year of each strategy to specified year
    //and move the start and end date of every instrument relatively
    const updateDatesOfInstrument = (instrument, diffInMonths, key, defaultStartMonthKey, defaultStartYearKey) => {
      const startYearKey = defaultStartYearKey || (key === 'assets' ? 'buyingYear' : 'startYear');
      const startMonthKey = defaultStartMonthKey || (key === 'assets' ? 'buyingMonth' : 'startMonth');
      const endYearKey = key === 'assets' ? 'sellingYear' : 'endYear';
      const endMonthKey = key === 'assets' ? 'sellingMonth' : 'endMonth';

      const { month: newStartMonth, year: newStartYear } = addMonthAndYear(
        instrument[startMonthKey],
        instrument[startYearKey],
        diffInMonths
      );

      instrument[startMonthKey] = newStartMonth;
      instrument[startYearKey] = newStartYear;

      if (instrument[endYearKey]) {
        const { month: newEndMonth, year: newEndYear } = addMonthAndYear(
          instrument[endMonthKey],
          instrument[endYearKey],
          diffInMonths
        );
        instrument[endMonthKey] = newEndMonth;
        instrument[endYearKey] = newEndYear;
      }
    };

    const newStrategies = parentCustomer.strategies.map(strategy => {
      const clonedStrategy = cloneDeep(strategy);
      clonedStrategy.initialYear = yearInNumber;
      clonedStrategy.initialMonth = monthInNumber;
      clonedStrategy.totalYears = totalYearsInNumber;

      const diffInMonths = findDiffInNumberOfMonths(
        strategy.initialMonth,
        strategy.initialYear,
        monthInNumber,
        yearInNumber
      );

      const {
        assets,
        liabilities,
        fixedIncomeAssets,
        oneOffChanges,
        customCashflows,
        investments,
        defaultArmageddonConfig
      } = clonedStrategy;

      [
        { key: 'assets', instruments: assets },
        { key: 'liabilities', instruments: liabilities },
        { key: 'fixedIncomeAssets', instruments: fixedIncomeAssets },
        { key: 'oneOffChanges', instruments: oneOffChanges },
        { key: 'customCashflows', instruments: customCashflows },
        { key: 'investments', instruments: investments },
        { key: 'armageddon', instruments: defaultArmageddonConfig }
      ].forEach(({ instruments, key }) => {
        if (!instruments) return;

        //this is for defaultArmageddonConfig
        if (key === 'armageddon') {
          updateDatesOfInstrument(instruments, diffInMonths, key);
          return;
        }

        instruments.forEach(i => {
          updateDatesOfInstrument(i, diffInMonths, key);

          if (i['cashflowChanges']) {
            i['cashflowChanges'].forEach(c => {
              updateDatesOfInstrument(c, diffInMonths, key);
            });
          }

          if (i['paperApartmentPayments']) {
            i['paperApartmentPayments'].forEach(c => {
              updateDatesOfInstrument(c, diffInMonths, key, 'month', 'year');
            });
          }
        });
      });

      //when we extend strategy i.e. total number of Years is changed
      //1.  liabilities which matures before the expanded date should not expand
      //     but should end at maturity, so we update the end dates of all the liabilities
      //2.  reinvestment dont have null/undefined in there ending dates,
      //    so if reinvestment ends at last month and year of strategy we need to extend it to new last month and year as well

      //1.
      clonedStrategy.liabilities = clonedStrategy.liabilities.map(l => ({
        ...l,
        ...getUpdatedLinkedVariablesOfLiability(
          clonedStrategy,
          clonedStrategy.assets.find(a => a.name === l.relatedAssets?.[0]),
          null,
          l
        )
      }));

      //2.
      const { month: prevLastMonth, year: prevLastYear } = addMonthAndYear(
        monthInNumber,
        yearInNumber,
        -1, //last month is just one month before
        strategy.totalYears
      );
      const { month: newLastMonth, year: newLastYear } = addMonthAndYear(
        monthInNumber,
        yearInNumber,
        -1,
        totalYearsInNumber
      );

      clonedStrategy.investments = clonedStrategy.investments.map(i =>
        areMonthYearEqual(i.endMonth, i.endYear, prevLastMonth, prevLastYear)
          ? { ...i, endMonth: newLastMonth, endYear: newLastYear }
          : i
      );

      return clonedStrategy;
    });

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_UPDATE,
      requestBody: {
        strategies: newStrategies,
        _id: activeCustomer['_id']
      }
    });

    setUpdatingStrategyTime(false);

    if (error) {
      return toast.error(error);
    }

    onCustomerUpdate(response);
    setUpdateStrategyTimeMeta(null);
    toast.success(`Successfully updated strategies!`);
  };

  const [shareDialogMeta, setShareDialogMeta] = useState(null);

  const onShareClick = () => {
    setShareDialogMeta({ userBeingAdded: '' });
  };

  const onShareUserAddClick = async () => {
    const { userBeingAdded } = shareDialogMeta;

    if (!validateEmail(userBeingAdded)) {
      return toast.error('Please provide a valid email');
    }

    toast.info('Adding user...');
    setShareDialogMeta({ ...shareDialogMeta, showProgress: true });

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.SHARE_USER_ADD,
      requestBody: {
        email: userBeingAdded,
        customerId: parentCustomer['_id']
      }
    });

    setShareDialogMeta({ ...shareDialogMeta, showProgress: false });

    if (error) {
      return toast.error(error);
    }

    setShareDialogMeta({ ...shareDialogMeta, userBeingAdded: '' });

    onCustomerUpdate(response);

    toast.success('Customer Shared succesfully');
  };

  const onShareUserRemoveClick = async user => {
    toast.info('Removing user...');
    setShareDialogMeta({ ...shareDialogMeta, showProgress: true });

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.SHARE_USER_SUBTRACT,
      requestBody: {
        email: user.email,
        customerId: parentCustomer['_id']
      }
    });

    setShareDialogMeta({ ...shareDialogMeta, showProgress: false });

    if (error) {
      return toast.error(error);
    }

    onCustomerUpdate(response);

    toast.success('User removed successfully');
  };

  return (
    <Container className={'h-100 p-2 p-md-3 bg-light'} fluid>
      {loading ? (
        <Card>
          <Card.Body>
            <Loader />
          </Card.Body>
        </Card>
      ) : readOnly && !Boolean(parentCustomer) ? (
        <ExampleUserNotFount translate={translate} />
      ) : (
        parentCustomer &&
        activeCustomer && (
          <>
            <CustomerCard
              isExampleUser={readOnly}
              adminCompTable={adminCompTable}
              parentCustomer={parentCustomer}
              activeCustomer={activeCustomer}
              onCompareStrategyClick={
                activeCustomer['strategies'].filter(s => !s.killed).length > 0 && onCompareStrategyClick
              }
              onCustomerGeneralInfoClick={allowShowingCustomerInfo && (() => setShowCustomerGeneralInfo(true))}
              onIrrCalculatorClick={onIrrCalculatorClick}
              onPinuiBinuiClick={onPinuiBinuiClick}
              onPaperApartmentClick={onPaperApartmentClick}
              onRSUOptionsClick={onRSUOptionsClick}
              onActiveCustomerSwitched={onActiveCustomerSwitched}
              onCustomerUpdate={onCustomerUpdate}
              fromCustomerPortal
              onViewFilesClick={onViewFilesClick}
              onUpdateStrategyTimeClick={() =>
                setUpdateStrategyTimeMeta({
                  customer: parentCustomer,
                  mode: 'edit',
                  strategyStartYear: parentCustomer['strategies'][0]?.initialYear || new Date().getFullYear(),
                  strategyStartMonth: parentCustomer['strategies'][0]?.initialMonth || 0,
                  strategyTotalYears: parentCustomer['strategies'][0]?.totalYears || 10
                })
              }
              onShareClick={onShareClick}
            />
            <ComparisonChartSidebar
              translate={translate}
              customer={activeCustomer}
              adminCompTable={adminCompTable}
              show={showComparisonChart}
              onHide={() => setShowComparisonChart(false)}
            />
            {allowShowingCustomerInfo && (
              <CustomerGeneralInfoSidebar
                customer={parentCustomer}
                show={showCustomerGeneralInfo}
                onHide={() => setShowCustomerGeneralInfo(false)}
                onCustomerUpdate={onCustomerUpdate}
                translate={translate}
                editable={!readOnly}
              />
            )}
            <FileViewer
              show={showFiles}
              onHide={() => setShowFiles(false)}
              driveFolder={activeCustomer.driveFolder}
              files={activeCustomer.files}
            />
            {[
              {
                show: showIrrCalculator,
                onHide: setShowIrrCalculator,
                key: IRR_CALCULATOR_KEY,
                Calculator: IrrCalculatorSidebar
              },
              {
                show: showPinuiBinuiCalculator,
                onHide: setShowPinuiBinuiCalculator,
                key: PINUI_BINUI_CALCULATOR_KEY,
                Calculator: PinuiBinuiCalculatorSidebar
              },
              {
                show: showPaperApartmentCalculator,
                onHide: setShowPaperApartmentCalculator,
                key: PAPER_CALCULATOR_KEY,
                Calculator: PaperApartmentCalculatorSidebar
              },
              {
                show: showRSUOptionsCalculator,
                onHide: setShowRSUOptionsCalculator,
                key: RSU_OPTIONS_KEY,
                Calculator: RSUOptionsCalculatorSidebar
              }
            ]
              .filter(a => parentCustomer['allowedCalculators'].includes(a.key))
              .map(({ show, onHide, Calculator }) => (
                <Calculator
                  translate={translate}
                  customer={activeCustomer}
                  show={show}
                  adminCompTable={adminCompTable}
                  onCustomerUpdate={setParentCustomer}
                  onHide={() => onHide(false)}
                  readOnlyMode={Boolean(readOnly)}
                />
              ))}
          </>
        )
      )}
      <UpdateStrategyTimeModal
        translate={translate}
        show={updateStrategyTimeMeta !== null}
        onHide={() => setUpdateStrategyTimeMeta(null)}
        strategyStartMonth={updateStrategyTimeMeta?.strategyStartMonth}
        strategyStartYear={updateStrategyTimeMeta?.strategyStartYear}
        onStartMonthChange={e =>
          setUpdateStrategyTimeMeta({ ...updateStrategyTimeMeta, strategyStartMonth: e.target.value })
        }
        onStartYearChange={e =>
          setUpdateStrategyTimeMeta({ ...updateStrategyTimeMeta, strategyStartYear: e.target.value })
        }
        strategyTotalYears={updateStrategyTimeMeta?.strategyTotalYears}
        onTotalYearsChange={e =>
          setUpdateStrategyTimeMeta({ ...updateStrategyTimeMeta, strategyTotalYears: e.target.value })
        }
        onStrategyStartDateUpdateSubmit={onStrategyStartDateUpdateSubmit}
        showProgress={updatingStrategyTime}
      />
      <CustomerShareSidebar
        customer={activeCustomer}
        show={shareDialogMeta}
        onHide={() => setShareDialogMeta(null)}
        onUserRemoveClick={onShareUserRemoveClick}
        userBeingAdded={shareDialogMeta?.userBeingAdded}
        onUserBeingAddedChange={email => setShareDialogMeta({ ...shareDialogMeta, userBeingAdded: email })}
        onAddUserClick={onShareUserAddClick}
        showProgress={shareDialogMeta?.showProgress}
        translate={translate}
      />
    </Container>
  );
};

export default CustomerPortal;

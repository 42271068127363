import React, { useContext, useEffect, useState } from 'react';
import { Alert, Card, Col, Container, Row } from 'react-bootstrap';
import { InfoCircleFill } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ForgotPasswordModal from '../components/common/ForgotPasswordModal';
import LanguagePicker from '../components/common/LanguagePicker';
import Loader from '../components/common/Loader';
import Logo from '../components/common/Logo';
import { UserContext } from '../context/UserContext';
import { makeApiRequests } from '../helpers/api';
import { ENDPOINTS } from '../helpers/constants';
import ConfirmPasswordForm from '../forms/ConfirmPasswordForm';

const CreatePassword = () => {
  const { key } = useParams();
  const history = useHistory();

  const { translate } = useContext(LocalizeContext);
  const { isUserLoggedIn } = useContext(UserContext);
  const [findingUser, setFindingUser] = useState(true);
  const [resetingPassword, setResetingPassword] = useState(false);
  const [showForgotPassModal, setShowForgotPassModal] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [user, setUser] = useState(null);

  if (isUserLoggedIn) {
    return <Redirect from="/create-password" to="/" />;
  }

  const findUser = async () => {
    try {
      setFindingUser(true);
      const { response, error } = await makeApiRequests({
        endpoint: ENDPOINTS.USERS_DETAILS(key),
        method: 'GET'
      });
      if (response) {
        setUser(response);
      }
      if (error) {
        setErrorMessage({
          type: 'user',
          message: error
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setFindingUser(false);
    }
  };

  useEffect(() => {
    if (key) {
      findUser();
    }
  }, [key]);

  const createPassword = async ({ password }) => {
    try {
      setResetingPassword(true);
      const { response, error } = await makeApiRequests({
        endpoint: ENDPOINTS.RESET_PASSWORD,
        requestBody: {
          email: user?.email,
          temporaryKey: key,
          newPassword: password
        },
        method: 'POST'
      });
      if (response) {
        toast.success('Password updated successfully. You can login using your new password');
        history.push('/login');
      }
      if (error) {
        setErrorMessage({
          type: 'createPassword',
          message: error
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setResetingPassword(false);
    }
  };

  const onForgotPasswordSubmit = async data => {
    try {
      setShowForgotPassModal({ showProgress: true });
      const { response, error } = await makeApiRequests({
        endpoint: ENDPOINTS.FORGOT_PASSWORD,
        requestBody: {
          email: data?.email
        },
        method: 'POST'
      });
      if (response) {
        toast.success('Password reset link has been sent');
        setShowForgotPassModal(null);
      }
      if (error) {
        setShowForgotPassModal({ showProgress: false, errorMessage: error });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container fluid className="h-100">
      <Row className="h-100">
        <Col xs={1} className="bg-dark d-block"></Col>
        <Col xs={11} className="pt-2 pb-3 px-0 px-md-5 bg-light">
          <div className="text-end">
            <LanguagePicker />
          </div>

          <Row className="justify-content-center h-100">
            <Col xs={10} md={6} className="align-self-center">
              <Card className="">
                <Card.Header className="p-3 bg-dark">
                  <div className="">
                    <Logo />
                  </div>
                </Card.Header>
                <Card.Body>
                  {findingUser || errorMessage?.type === 'user' ? (
                    <div className="h-100 d-flex justify-content-center align-items-center">
                      {findingUser ? (
                        <Loader />
                      ) : (
                        <Alert className="p-4 mt-3 large d-flex align-items-center gap-3" variant="danger">
                          <InfoCircleFill size={40} />
                          {translate('token_expired_invalid')}
                        </Alert>
                      )}
                    </div>
                  ) : (
                    <>
                      {' '}
                      <h6 className="text-muted mx-2 my-1">
                        {translate('hello')} <strong>{user?.name}, </strong>
                        {translate('welcome_message')}
                      </h6>
                      <h6 className="xxlarge mx-2 my-1">
                        <b className="text-dark">{translate('please_reset_your_password')}</b>
                      </h6>
                      <hr />
                      <ConfirmPasswordForm
                        loading={resetingPassword}
                        errorMessage={errorMessage?.type === 'createPassword' ? errorMessage?.message : null}
                        onSubmit={createPassword}
                      />
                    </>
                  )}
                  {!findingUser && (
                    <>
                      <hr />{' '}
                      <div className="d-flex justify-content-between align-items-center">
                        <Link className="text-dark large d-block text-center" to={'/login'}>
                          {translate('go_to_login_page')}
                        </Link>{' '}
                        {!user && (
                          <>
                            <Link
                              className="text-dark large d-block text-center"
                              onClick={() => setShowForgotPassModal({ showProgress: false })}
                            >
                              Resend password reset link
                            </Link>
                            <ForgotPasswordModal
                              title={'resend_password_link'}
                              show={showForgotPassModal}
                              onHide={() => setShowForgotPassModal(null)}
                              showProgress={showForgotPassModal?.showProgress}
                              onSubmit={onForgotPasswordSubmit}
                              forgotPasswordError={showForgotPassModal?.errorMessage}
                            />
                          </>
                        )}
                      </div>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default CreatePassword;

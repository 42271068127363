import React from 'react';
import { CheckBoxInput } from '../../form-generator/components';
import BuyingTax from '../../components/admin/manage-users/customer/strategies/BuyingTax';
import SellingTax from '../../components/admin/manage-users/customer/strategies/SellingTax';

const AssetTaxes = ({
  isApartmentAbroad,
  taxInfo = {},
  assetEditMode,
  assetValue,
  onBuyingTaxChange,
  onBuyingTaxPercentageChange,
  buyingTaxAutoMode,
  onBuyingTaxCalculationModeChange,
  onSellingTaxChange,
  onSellingTaxPercentageChange,
  sellingTaxAutoMode,
  onSellingTaxCalculationModeChange,
  onlyApartment,
  onFirstApartmentCheckedChange
}) => {
  return (
    <div className="">
      {!isApartmentAbroad && (
        <CheckBoxInput
          id="buyingOnlyApartment"
          size="sm"
          showLabel
          label="Is Only apartment?"
          checked={onlyApartment?.buying}
          onChangeFunction={e => e && onFirstApartmentCheckedChange('buying', e.target.checked)}
        />
      )}

      <BuyingTax
        disabled={!assetEditMode}
        assetValue={assetValue}
        buyTaxBreakdown={taxInfo['buyTaxBreakdown']}
        totalTax={taxInfo['buy']}
        totalTaxPercentage={taxInfo['buyPercentage']}
        onTotalTaxChange={onBuyingTaxChange}
        onTotalTaxPercentageChange={onBuyingTaxPercentageChange}
        isApartmentAbroad={isApartmentAbroad}
        isTaxCalculatedAutomatically={buyingTaxAutoMode}
        onAutoCalculateModeChange={onBuyingTaxCalculationModeChange}
      />

      {!isApartmentAbroad && (
        <CheckBoxInput
          id="sellingOnlyApartment"
          size="sm"
          showLabel
          label="Is Only apartment?"
          checked={onlyApartment?.selling}
          onChangeFunction={e => e && onFirstApartmentCheckedChange('selling', e.target.checked)}
        />
      )}

      <SellingTax
        isApartmentAbroad={isApartmentAbroad}
        sellingTaxBreakdown={taxInfo['sellingTaxBreakdown']}
        totalTax={taxInfo['sell']}
        totalTaxPercentage={taxInfo['sellPercentage']}
        appreciatedValue={taxInfo['appreciatedValue']}
        pnl={taxInfo['pnl']}
        onTotalTaxChange={onSellingTaxChange}
        onTotalTaxPercentageChange={onSellingTaxPercentageChange}
        isTaxCalculatedAutomatically={sellingTaxAutoMode}
        onAutoCalculateModeChange={onSellingTaxCalculationModeChange}
      />
    </div>
  );
};

export default AssetTaxes;

import React, { useEffect } from 'react';
import useStrategyMonthYearSelector from '../../hooks/useStrategyMonthYearSelector';
import { monthSmall } from '../../helpers/constants';
import useLocalization from '../../hooks/useLocalization';

const MonthYearPicker = ({
  className = '',
  years = [],
  incompleteYears,
  month,
  year,
  onMonthChange,
  onYearChange,
  monthLabel,
  yearLabel,
  disabled,
  required
}) => {
  const { translate } = useLocalization();

  const {
    selectedMonth,
    selectedYear,
    onMonthChange: onMonthChangeInternal,
    onYearChange: onYearChangeInternal,
    monthOptions,
    yearOptions
  } = useStrategyMonthYearSelector({
    incompleteYearsOfStrategy: incompleteYears,
    strategyYears: years,
    initialYear: year,
    initialMonth: month
  });

  useEffect(() => {
    onMonthChange(selectedMonth);
  }, [selectedMonth]);

  useEffect(() => {
    onYearChange(selectedYear);
  }, [selectedYear]);

  return (
    <div className={`d-flex justify-content-between gap-3 ${className}`}>
      <div className="flex-fill">
        <label className="mid" htmlFor="year">
          {yearLabel || translate('year')}
        </label>
        <select
          id="year"
          disabled={disabled}
          onChange={e => onYearChangeInternal(e.target.value)}
          name="year"
          className="form-select form-select-sm mb-3"
          value={selectedYear}
        >
          {!required && <option value=""></option>}
          {yearOptions.map(year => (
            <option value={year} selected={selectedYear === year} key={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      <div className="flex-fill">
        <label className="mid" htmlFor="month">
          {monthLabel || translate('month')}
        </label>
        <select
          id="month"
          onChange={e => onMonthChangeInternal(e.target.value)}
          name="month"
          disabled={disabled}
          className="form-select form-select-sm mb-3"
          value={selectedMonth}
        >
          {monthOptions.map(month => (
            <option value={month} key={month}>
              {monthSmall[month]}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default MonthYearPicker;

import React from 'react';
import { ArrowLeftRight } from 'react-bootstrap-icons';
import CurrencyInput from '../../../../common/CurrencyInput';
import PercentageInput from '../../../../common/PercentageInput';
import { formatCurrency } from './helper';

const SellingTax = ({
  sellingTaxBreakdown = [],
  totalTax,
  totalTaxPercentage,
  pnl = 0,
  appreciatedValue = 0,
  onTotalTaxChange,
  onTotalTaxPercentageChange,
  isApartmentAbroad,
  isTaxCalculatedAutomatically,
  onAutoCalculateModeChange
}) => {
  return (
    <>
      <div className="mt-1 d-flex align-items-center">
        <div className="flex-grow-1">
          <h6 className="mid mb-0">Expected Selling Tax:</h6>
        </div>
        {!isApartmentAbroad && (
          <div>
            <h6 className={`a tiny text-primary hover mb-0`} onClick={onAutoCalculateModeChange}>
              <ArrowLeftRight className="mx-1" />
              Switch to <b>{isTaxCalculatedAutomatically ? 'Manual' : 'Auto'}</b> mode
            </h6>
          </div>
        )}
      </div>
      <hr className="my-2" />
      <div className="px-2">
        {[
          { title: 'Asset value at selling month and year', value: appreciatedValue },
          { title: 'Profit', value: pnl }
        ].map(({ title, value }) => (
          <h6 key={title} className="smallFont text-muted text-end">
            {title}: <span className="text-dark">{formatCurrency(value)}</span>
          </h6>
        ))}

        {sellingTaxBreakdown?.map(t => (
          <div>
            <h6 className="mb-0  smallFont text-dark text-end">
              Upto {formatCurrency(t.to)}(<span>{t.percentage}%</span>)
            </h6>

            <h6 className="text-end text-muted smallFont mt-2">
              <b>={formatCurrency(t.actualTax)}</b>
            </h6>
          </div>
        ))}

        <div className="smallFont mb-3 d-flex">
          <div className={isApartmentAbroad ? 'w-50' : 'w-100'}>
            <b>Total Tax: </b>
            <CurrencyInput
              size="sm"
              disabled={!isApartmentAbroad && isTaxCalculatedAutomatically}
              onChange={value => onTotalTaxChange(value)}
              value={totalTax}
              maxDecimal={0}
            />
          </div>
          {isApartmentAbroad && (
            <div className="mx-2 w-50">
              <b>Tax (%): </b>
              <PercentageInput
                size="sm"
                onChange={value => onTotalTaxPercentageChange(value)}
                value={totalTaxPercentage}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SellingTax;

import { uniqueId } from 'lodash';
import { useContext, useMemo } from 'react';
import { Button, FormCheck, FormControl, FormSelect, Table } from 'react-bootstrap';
import { PlusCircle, Trash } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import PercentageInput from '../../form-generator/components/PercentageInput';
import {
  formatCurrency,
  formatPercentage,
  getNumberFromFormattedValue
} from '../admin/manage-users/customer/strategies/helper';
import AutoGrowTextArea from '../common/AutoGrowTextArea';
import CompactBlockSelectInput from '../common/CompactBlockSelectInput';
import CurrencyInput from '../common/CurrencyInput';
import MonthYearInput from '../common/MonthYearInput';
import RangeInput from '../common/RangeInput';
import SwitchInput from '../common/SwitchInput';
import UnderlineButton from '../common/UnderlineButton';
import { getPercentageValue } from './helpers';
import Summary from './IrrBreakdownSummary';

const getPaymentHeaders = translate => [
  {
    name: translate('month'),
    key: 'month',
    type: 'number'
  },
  {
    name: translate('value'),
    type: 'number',
    format: 'currency',
    key: 'value'
  },
  {
    name: '%',
    type: 'number',
    format: '%',
    key: '%'
  }
];

const PaperApartmentPayments = ({ readOnlyMode, apartmentValue, payments = [], onPaymentsChange }) => {
  const { translate, langCode } = useContext(LocalizeContext);

  const paperApartmentPaymentHeaders = useMemo(() => getPaymentHeaders(translate), [langCode]);

  const onPaymentChange = (payment, field, type, format, fieldValue) => {
    let apartmentValueParsed = getNumberFromFormattedValue(apartmentValue);
    payment[field] = fieldValue;

    if (type === 'number') {
      if (format === '%') {
        //update number also
        payment.value = apartmentValueParsed * fieldValue * 0.01;
      } else if (format === 'currency') {
        //update % also
        payment['%'] = apartmentValueParsed === 0 ? 0 : ((fieldValue * 100) / apartmentValueParsed).toFixed(2);
      }
    }

    onPaymentsChange(payments.map(p => (p.id === payment.id ? { ...payment } : p)));
  };

  const onPaymentAddClick = () => {
    onPaymentsChange([...payments, { id: uniqueId(), month: '', value: '' }]);
  };

  const onPaymentDeleteClick = payment => {
    onPaymentsChange(payments.filter(v => v.id !== payment.id));
  };

  return (
    <div className="px-1 w-100 pointer-events">
      <Table bordered className="mb-0 w-100 no-sticky ">
        <thead>
          <tr className="text-white bg-dark ">
            {paperApartmentPaymentHeaders.map(({ name }) => (
              <th key={name}>{name}</th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {payments.length > 0 ? (
            <>
              {payments.map(payment => (
                <tr key={payment.id}>
                  {paperApartmentPaymentHeaders.map(({ name, type, options, key, format }) => (
                    <td className="bg-white p-0" key={name}>
                      {type === 'dropdown' && (
                        <FormSelect
                          className="mb-0"
                          autoComplete="off"
                          size="sm"
                          value={payment[key]}
                          disabled={readOnlyMode}
                          onChange={e => {
                            onPaymentChange(payment, key, type, format, e.target.value);
                          }}
                        >
                          {options.map(o => (
                            <option key={o.value} value={o.value}>
                              {o.option}
                            </option>
                          ))}
                        </FormSelect>
                      )}
                      {type === 'number' &&
                        (format === '%' ? (
                          <PercentageInput
                            className="m-0"
                            size="sm"
                            disabled={readOnlyMode}
                            value={payment[key]}
                            onChange={e => {
                              let inputValue = e.target.value.replace(/[%,]/g, '').trim();
                              if (isNaN(Number(inputValue))) return;
                              //call on change
                              onPaymentChange(payment, key, type, format, inputValue);
                            }}
                          />
                        ) : (
                          <FormControl
                            className="mb-0"
                            autoComplete="off"
                            type="text"
                            size="sm"
                            disabled={readOnlyMode}
                            value={!format ? payment[key] : formatCurrency(payment[key], false)}
                            onChange={e => {
                              let inputValue = Number(e.target.value.replace(/[%,]/g, '').trim() || 0);
                              if (Number.isNaN(inputValue)) return;
                              //call on change
                              onPaymentChange(payment, key, type, format, inputValue);
                            }}
                          />
                        ))}
                    </td>
                  ))}
                  <td className="p-0">
                    <div className="text-center">
                      <Button
                        variant="outline-danger"
                        className="px-1 py-0 mx-2"
                        onClick={() => onPaymentDeleteClick(payment)}
                      >
                        <Trash size={9} />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={paperApartmentPaymentHeaders.length + 1}>
                <h6 className="text-muted text-center mb-0 smallFont">{translate('nothing_to_show')}</h6>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="text-end">
        <UnderlineButton
          fontSize="smallFont"
          variant="success"
          Icon={PlusCircle}
          text={translate('new')}
          onClick={onPaymentAddClick}
        />
      </div>
    </div>
  );
};

const DataRow = ({
  label,
  objectKey,
  extraInfo = null,
  isInput,
  percentageOfField,
  type,
  min,
  max,
  trueValue,
  falseValue,
  blockMaxWidth = null,
  appartments = [],
  onValueChange,
  allFields,
  isRtlLanguage,
  collapsedApartments,
  tabIndex,
  totalFieldsCount,
  options = [],
  readOnlyMode,
  dataListOptions,
  as,
  required = false
}) => {
  return (
    <tr className={type === 'paperApartmentPayments' ? '' : ''}>
      <td className={`p-1 bg-white text-${isRtlLanguage ? 'end' : 'start'} calc-column`}>
        <b>
          {label}
          {type === 'percentage' && ' (%)'}
        </b>
      </td>
      {appartments?.map((app, appIndex) => (
        <td
          key={app.id}
          className={`p-1 text-${isRtlLanguage ? 'end' : 'start'}  ${
            !collapsedApartments.includes(app.id) ? 'calc-column' : ''
          }`}
        >
          {!collapsedApartments.includes(app.id) && (
            <div className="d-flex align-items-center">
              {isInput ? (
                type === 'block-select' ? (
                  <CompactBlockSelectInput
                    isRtlLanguage={isRtlLanguage}
                    value={app[objectKey]}
                    onChange={value => onValueChange(app.id, objectKey, type, value)}
                    options={options}
                    blockMaxWidth={blockMaxWidth}
                  />
                ) : type === 'switch' ? (
                  <SwitchInput
                    value={app[objectKey]}
                    onChange={value => onValueChange(app.id, objectKey, type, value)}
                    trueValue={trueValue}
                    falseValue={falseValue}
                  />
                ) : type === 'range' ? (
                  <RangeInput
                    isRtlLanguage={isRtlLanguage}
                    value={app[objectKey] || 1}
                    onChange={value => onValueChange(app.id, objectKey, type, value)}
                    min={min}
                    max={max}
                  />
                ) : type === 'monthYearInput' ? (
                  <MonthYearInput
                    isRtlLanguage={isRtlLanguage}
                    value={app[objectKey]}
                    onChange={value => onValueChange(app.id, objectKey, type, value)}
                  />
                ) : type === 'boolean' ? (
                  <FormCheck
                    tabIndex={appIndex * totalFieldsCount + tabIndex}
                    size="sm"
                    disabled={readOnlyMode}
                    checked={app[objectKey]}
                    onChange={e => onValueChange(app.id, objectKey, type, e.target.checked)}
                  />
                ) : type === 'rate' || type === 'dropdown' ? (
                  <FormSelect
                    tabIndex={appIndex * totalFieldsCount + tabIndex}
                    size="sm"
                    disabled={readOnlyMode}
                    value={app[objectKey]}
                    onChange={e =>
                      onValueChange(app.id, objectKey, type, e.target.value === 'select_one' ? null : e.target.value)
                    }
                  >
                    {!required && <option value={'select_one'}>Select one</option>}
                    {type === 'rate'
                      ? [
                          'Select...',
                          ...Array(10)
                            .fill(0)
                            .map((a, i) => i + 1)
                        ].map(o => (
                          <option key={o} value={o === 'Select...' ? '' : o}>
                            {o}
                          </option>
                        ))
                      : options.map(({ label, value }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                  </FormSelect>
                ) : type === 'percentage' ? (
                  <PercentageInput
                    tabIndex={appIndex * totalFieldsCount + tabIndex}
                    className="m-0"
                    size="sm"
                    disabled={readOnlyMode}
                    value={app[objectKey]}
                    onChange={e => onValueChange(app.id, objectKey, type, e.target.value)}
                  />
                ) : type === 'paperApartmentPayments' ? (
                  <PaperApartmentPayments
                    tabIndex={appIndex * totalFieldsCount + tabIndex}
                    disabled={readOnlyMode}
                    payments={app[objectKey]}
                    apartmentValue={app['inputPrice']}
                    onPaymentsChange={value => onValueChange(app.id, objectKey, type, value)}
                    readOnlyMode={readOnlyMode}
                  />
                ) : type === 'text' ? (
                  <>
                    <AutoGrowTextArea
                      autoComplete="off"
                      tabIndex={appIndex * totalFieldsCount + tabIndex}
                      className="m-0"
                      as={as || (percentageOfField ? 'input' : 'textarea')}
                      type={'text'}
                      style={percentageOfField ? { width: '70%' } : {}}
                      size="sm"
                      disabled={readOnlyMode}
                      rows={1}
                      list={`${objectKey}-list`}
                      value={app[objectKey]}
                      onChange={e => onValueChange(app.id, objectKey, type, e.target.value, { percentageOfField })}
                    />
                    {dataListOptions?.length > 0 && (
                      <datalist id={`${objectKey}-list`}>
                        {dataListOptions.map(option => (
                          <option key={option} value={option} />
                        ))}
                      </datalist>
                    )}
                  </>
                ) : (
                  <>
                    <CurrencyInput
                      tabIndex={appIndex * totalFieldsCount + tabIndex}
                      className="m-0"
                      style={percentageOfField ? { width: '70%' } : {}}
                      size="sm"
                      disabled={readOnlyMode}
                      value={app[objectKey]}
                      onChange={number => onValueChange(app.id, objectKey, type, number, { percentageOfField })}
                    />
                    {percentageOfField && (
                      <PercentageInput
                        className="m-0 ms-1"
                        hint="%"
                        size="sm"
                        style={{ width: '30%' }}
                        disabled={readOnlyMode}
                        value={app[`${objectKey}-percentage`]}
                        onChange={e =>
                          onValueChange(app.id, objectKey, 'dependent-percentage', e.target.value, {
                            percentageOfField
                          })
                        }
                      />
                    )}
                  </>
                )
              ) : (
                <span className="px-2">
                  {type === 'percentage'
                    ? formatPercentage(getPercentageValue(allFields, objectKey, app[objectKey]))
                    : formatCurrency(app[objectKey])}
                </span>
              )}
              {extraInfo && app?.[extraInfo?.key] && <Summary data={app?.[extraInfo?.key]} extraInfo={extraInfo} />}
            </div>
          )}
        </td>
      ))}
    </tr>
  );
};

export default DataRow;

import { snakeCase } from 'lodash';
import React, { useContext } from 'react';
import { LocalizeContext } from 'react-locale-language';

const Title = ({ field: { title, label, required, renderExtraLabel }, editMode, className, style, data }) => {
  const { translate } = useContext(LocalizeContext);
  return (
    <div style={style} className={`d-flex align-items-center gap-1 mb-1 ${className}`}>
      <span className="text-dark mid">
        {translate(snakeCase(title)) || title || translate(snakeCase(label)) || label}
      </span>
      {required && editMode && <span className="text-danger">* </span>} {renderExtraLabel && renderExtraLabel(data)}
    </div>
  );
};

export default Title;
